import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  FaTrash,
  FaCog,
  FaEllipsisV,
  FaEdit,
  FaTimes,
  FaUserCheck,
  FaRegCopy,
} from "react-icons/fa";
import { PiLinkSimpleBold } from "react-icons/pi";
import "./customcss/admintabstyle.css";

const OrganizationList = ({ searchResults, alertMessage }) => {
  const token = sessionStorage.getItem("token");
  const memberId = sessionStorage.getItem("member_id");
  const [orgList, setorgList] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [membersRow, setMembersRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [tokenn, setTokenn] = useState(`${token}`);
  const [orgSettings, setOrgSettings] = useState([]);
  const [copied, setCopied] = useState(null);
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setTokenn(token);
    // const chapterId = sessionStorage.getItem("chapter_id");
    // setChapterIdd(chapterId);
  }, []);

  console.log("org results in org comp: ", searchResults);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setorgList(searchResults); // Update members with search results
    } else if (!alertMessage) {
      // Fetch the default member list only if alertMessage is not present
      fetchDataFromApi();
    }
  }, [searchResults, alertMessage, orgList]);

  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/getAllOrg",
        {
          headers: {
            Authorization: `Bearer ${tokenn}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const orgList1 = data.result;
        setorgList(orgList1);

        // roleId == 1 ? setorgList(orgList) : setorgList(null);
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        // setError(`Error fetching orgList: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
      // setError("Error fetching orgList. Please try again later.");
    }
  };

  const toggleEyeDetails = (index) => {
    const selectedOrg = orgList.find((org, idx) => idx === index);
    // If the selectedOrg is found, set its values into orgSettings
    if (selectedOrg) {
      setOrgSettings(selectedOrg);
    }
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleMembersSection = (index) => {
    setMembersRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setMembersRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  const handleToggle = (settingName, value) => {
    // Create a copy of orgSettings object
    const updatedOrgSettings = { ...orgSettings };

    // Update the specific setting
    updatedOrgSettings["updated_by"] = memberId;

    updatedOrgSettings[settingName] = value;

    // Update the state with the modified orgSettings object
    setOrgSettings(updatedOrgSettings);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, editRow, deleteRow]);

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("upated settings", orgSettings);

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrgSetting",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(orgSettings),
        }
      );

      if (response.ok) {
        console.log("Organization setting updated successfully");
        alert("Organization setting updated successfully"); // Show alert
        setEyeClickedRow(null);
      } else {
        const errorData = await response.json();
        console.error("Error creating Organization setting:", errorData);
        setError("Error creating Organization. Please try again later.");
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating Organization setting. Please try again later.");
    }
  };

  const handleCopy = (text, orgId) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(orgId);
        setTimeout(() => {
          setCopied(null);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            {orgList.length > 0 ? (
              <Col lg="12">
                <Card>
                  <Card.Body className="p-0">
                    {alertMessage ? (
                      <div>
                        {" "}
                        <p>{alertMessage}</p>
                      </div>
                    ) : (
                      <>
                        {orgList.length === 0 ? (
                          <p>No data available</p>
                        ) : (
                          <Table className="rounded">
                            <thead className="bg-primary text-white">
                              <tr>
                                <th>
                                  <b>Organization</b>
                                </th>
                                <th>
                                  <b>Owner</b>
                                </th>
                                <th className="email-column">
                                  <b>Email</b>
                                </th>
                                <th className="link-column">
                                  <b>Link</b>
                                </th>
                                <th>
                                  <b>Setting</b>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {orgList.map((orgList, index) => (
                                <React.Fragment key={orgList.org_id}>
                                  <tr>
                                    <td>
                                      <b>{orgList.organization_name}</b>
                                    </td>
                                    <td>
                                      <b>{orgList.org_owner_name}</b>
                                    </td>
                                    <td className="email-column">
                                      <b>{orgList.email_id}</b>
                                    </td>
                                    <td className="link-column">
                                      {truncateText(orgList.org_link, 30)}
                                      &nbsp;&nbsp;
                                      <FaRegCopy
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleCopy(
                                            orgList.org_link,
                                            orgList.org_id
                                          )
                                        }
                                      />
                                      {copied === orgList.org_id && (
                                        <span
                                          style={{
                                            marginLeft: "10px",
                                            color: "green",
                                          }}
                                        >
                                          Copied!
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-lg-none">
                                        <Dropdown
                                          show={showActionsDropdown === index}
                                          onSelect={() =>
                                            setShowActionsDropdown(null)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            variant="link"
                                            id={`dropdown-basic-${index}`}
                                            style={{
                                              fontSize: "1.5rem",
                                              color: "skyblue",
                                            }}
                                          >
                                            <FaEllipsisV
                                              className="ellipsis-icon"
                                              onClick={() =>
                                                toggleActionsDropdown(index)
                                              }
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleEyeDetails(index)
                                              }
                                            >
                                              <FaCog className="viewicon" />{" "}
                                              View Details
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleCopy(
                                                  orgList.org_link,
                                                  orgList.org_id
                                                )
                                              }
                                            >
                                              <PiLinkSimpleBold className="editicon" />{" "}
                                              Copy Link
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item onClick={() => toggleEditSection(index)}>
                                                                            <FaEdit className="editicon" /> Edit
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => toggleDeleteSection(index)}>
                                                                            <FaTrash className="deleteicon" /> Delete
                                                                        </Dropdown.Item> */}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div className="d-none d-lg-block">
                                        &nbsp;&nbsp; &nbsp;&nbsp;
                                        <FaCog
                                          className="viewicon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        {/* <FaUserCheck
                                    // className="editicon"
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => toggleMembersSection(index)}
                                  /> */}
                                        &nbsp;&nbsp;
                                      </div>
                                    </td>
                                  </tr>
                                  {eyeClickedRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="5"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setEyeClickedRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: ".5rem",
                                            right: ".8rem",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <div className="row px-3">
                                          <div className="col-lg-6 px-3">
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>
                                                  Member Onboarding Authority
                                                </b>
                                              </p>
                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="post-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.member_onboard_authority ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "member_onboard_authority",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>Posts Across Chapter</b>
                                              </p>
                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="post-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.posts_across_chapter ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "posts_across_chapter",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>Trainings Across Chapter</b>
                                              </p>
                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="training-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.trainings_across_chapter ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "trainings_across_chapter",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>
                                                  Chapter Referral Thirdparty
                                                </b>
                                              </p>

                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="training-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.chapter_referral_thirdparty_data ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "chapter_referral_thirdparty_data",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                          </div>

                                          <div className="col-lg-6 px-3">
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>Events Across Chapter</b>
                                              </p>

                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="events-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.events_across_chapter ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "events_across_chapter",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>

                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>Meetings Across Chapter</b>
                                              </p>
                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="meeting-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.meetings_across_chapter ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "meetings_across_chapter",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between px-2">
                                              <p>
                                                <b>Referrals Across Chapter</b>
                                              </p>

                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id="referral-switch"
                                                  label=""
                                                  checked={
                                                    orgSettings.referrals_across_chapter ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggle(
                                                      "referrals_across_chapter",
                                                      e.target.checked ? 1 : 0
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </div>
                                            {/* <p style={{ textAlign: "center" }}>
                                              &nbsp;&nbsp; &nbsp;&nbsp;
                                              &nbsp;&nbsp; &nbsp;&nbsp;
                                              &nbsp;&nbsp;{" "}
                                              <button
                                                style={{
                                                  padding: "2px 12px",
                                                  fontSize: "13px",
                                                  fontWeight: "bold",
                                                  backgroundColor: "#007BFF",
                                                  color: "white",
                                                  border: "none",
                                                  borderRadius: "4px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleSave}
                                              >
                                                Save
                                              </button>
                                            </p> */}
                                          </div>
                                        </div>
                                        <div className="row px-5">
                                          <p style={{ textAlign: "right" }}>
                                            <button
                                              style={{
                                                padding: ".3rem .8rem",
                                                fontSize: "1rem",
                                                fontWeight: "bold",
                                                backgroundColor: "#007BFF",
                                                color: "white",
                                                border: "none",
                                                borderRadius: ".3rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={handleSave}
                                            >
                                              Save
                                            </button>
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <p>No organizations available!</p>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OrganizationList;
