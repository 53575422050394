import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaTrash, FaEdit, FaTimes } from "react-icons/fa";
import useOrgNaming from "../../../hooks/getNamingHook";
const Chapters = () => {
  const memberId = sessionStorage.getItem("member_id");
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const token = sessionStorage.getItem("token"); // Fetch the token from localStorage
  const org_id = sessionStorage.getItem("org_id");
  const [selectedRequest, setselectedRequest] = useState(null);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (chapter_id) => {
    setMemberToDelete(chapter_id);
    setShowdelete(true);
  };
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit = () => setShowedit(false);
  const handleShowedit = (chapter) => {
    setselectedRequest(chapter);
    setselectedRequestId(chapter.chapter_id);
    setShowedit(true);
  };
  const [showeadd, setShowadd] = useState(false);
  const handleCloseadd = () => {
    setShowadd(false);
    setCreateData(initialFormData);
  };
  const handleShoweadd = () => setShowadd(true);
  const initialFormData = {
    chapter_name: "",
    email_id: "",
    phone_no: "",
    chapter_desc: "",
    street_address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
  };
  const [createData, setCreateData] = useState(initialFormData);
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };
  const { getOrgNames, loading } = useOrgNaming(org_id, token);

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    fetchchapter();
  }, []);
  //show the Naming list
  const fetchchapter = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/chapters/orgChapters/${org_id}`,
        config
      );
      const data = response.data;

      // const categories = data.map((item) => item.member_category);
      setChapters(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // create chapter handel change function.
  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setCreateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //create new chapter
  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    createData.created_by = memberId;
    createData.org_id = org_id;
    const { chapter_name, email_id, phone_no } = createData;
    // Check if membership_plan is filled (required)
    if (!chapter_name) {
      setError("chapter_name is required.");
      return;
    }

    // Check if fee is filled (required)
    if (!email_id) {
      setError("email_id is required.");
      return;
    }
    // Check if fee is filled (required)
    if (!phone_no) {
      setError("phone_no is required.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/chapters/chapters`,
        createData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchchapter();
      // Handle success or display a success message
      setShowadd(false);
      handleSuccess("Created Sucessfully");
      setCreateData(initialFormData);
    } catch (error) {
      setError("Error in added New chapter. Please try again later.");
      console.error("Create Failed:", error);
    }
  };
  // delete the chapter from the list
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/chapters/chapters/${memberToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Handle success or display a success message
      setShowdelete(false);
      handleSuccess("Record is deleted ");
      fetchchapter();
    } catch (error) {
      setError("Error in Delete chapter. Please try again later.");
    }
  };
  // update chapter handel change function.
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setselectedRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //update the already present chapter deatils
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (selectedRequest) {
      selectedRequest.updated_by = memberId;
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/chapters/chapters/${selectedRequestId}`,
          selectedRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Handle success or display a success message
        fetchchapter();
        setShowedit(false);
        handleSuccess("Record is Updated ");
      } catch (error) {
        setError("Error in Update chapter. Please try again later.");
      }
    }
  };

  //expand the row of list
  const toggleExpandRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <b>{getOrgNames?.alt_chapter || "Chapters"}</b>
                    </h4>
                  </div>
                  <div className="d-flex flex-column flex-lg-row justify-content-end">
                    <Button
                      variant="primary"
                      onClick={handleShoweadd}
                      className="my-2"
                    >
                      Add {getOrgNames?.alt_chapter || "Chapter"}
                    </Button>
                  </div>
                </Card.Header>
                <Modal
                  centered
                  show={showeadd}
                  onHide={handleCloseadd}
                  scrollable={true}
                  style={{
                    marginTop: "15vh",
                    height: "80vh",
                    marginBottom: "5vh",
                  }}
                >
                  <Modal.Header
                    closeButton
                    style={{ backgroundColor: "#007BFF", color: "white" }}
                  >
                    <Modal.Title className="text-white">
                      Add New chapter
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="chapter_name"
                              className="form-label"
                            >
                              {getOrgNames?.alt_chapter || "Chapter"} Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              value={createData.chapter_name}
                              id="chapter_name"
                              placeholder="Enter chapter name"
                              onChange={(e) => handleCreateChange(e)}
                              name="chapter_name"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="phone_no"
                              className="form-label"
                            >
                              Phone Number:
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              className="form-control"
                              value={createData.phone_no}
                              id="phone_no"
                              placeholder="Enter phone number"
                              onChange={(e) => handleCreateChange(e)}
                              name="phone_no"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group className="form-group col-sm-12">
                        <Form.Label
                          htmlFor="street_address"
                          className="form-label"
                        >
                          Street Address:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={createData.street_address}
                          id="street_address"
                          placeholder="Enter street address"
                          onChange={(e) => handleCreateChange(e)}
                          name="street_address"
                        />
                      </Form.Group>

                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="city" className="form-label">
                              City:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              value={createData.city}
                              id="city"
                              placeholder="Enter city"
                              onChange={(e) => handleCreateChange(e)}
                              name="city"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="state" className="form-label">
                              State:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              value={createData.state}
                              id="state"
                              placeholder="Enter state"
                              onChange={(e) => handleCreateChange(e)}
                              name="state"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="country"
                              className="form-label"
                            >
                              Country:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              value={createData.country}
                              id="country"
                              placeholder="Enter country"
                              onChange={(e) => handleCreateChange(e)}
                              name="country"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-sm-6">
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="zip_code"
                              className="form-label"
                            >
                              Zip Code:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              value={createData.zip_code}
                              id="zip_code"
                              placeholder="Enter zip code"
                              onChange={(e) => handleCreateChange(e)}
                              name="zip_code"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group className="form-group col-sm-12">
                        <Form.Label htmlFor="email_id" className="form-label">
                          Email ID:
                        </Form.Label>
                        <Form.Control
                          type="email"
                          className="form-control"
                          value={createData.email_id}
                          id="email_id"
                          placeholder="Enter email ID"
                          onChange={(e) => handleCreateChange(e)}
                          name="email_id"
                        />
                      </Form.Group>
                      <Form.Group className="form-group col-sm-12">
                        <Form.Label
                          htmlFor="chapter_desc"
                          className="form-label"
                        >
                          {getOrgNames?.alt_chapter || "Chapter"} Description:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control"
                          value={createData.chapter_desc}
                          id="chapter_desc"
                          placeholder="Enter chapter description"
                          onChange={(e) => handleCreateChange(e)}
                          name="chapter_desc"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseadd}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* </Card>
            </Col> */}

                {/* <Col sm={12}>
              <Card> */}
                <Card.Body
                  style={{
                    overflowY: "auto",
                    height: "80vh",
                    display: "inline-table",
                  }}
                >
                  <Table className="mb-0 rounded">
                    {/* ----table head code */}
                    <thead className="bg-primary text-white">
                      <tr>
                        <th className="col-xs-4">
                          <b>{getOrgNames?.alt_chapter || "Chapter"} Name</b>
                        </th>
                        {/* <th>
                            <b>no_of_members</b>
                          </th> */}
                        <th className="col-xs-4">
                          <b>Email</b>
                        </th>
                        <th className="col-xs-4 text-end">
                          <b></b>
                        </th>
                      </tr>
                    </thead>

                    {/* -----table body code */}
                    {chapters.length > 0 ? (
                      <tbody>
                        {chapters.map((chapter, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="col-xs-4">
                                <b>{chapter.chapter_name}</b>
                              </td>
                              {/* <td className="col-xs-5">
                                <b>{chapter.no_of_members}</b>
                              </td> */}
                              <td className="col-xs-4">
                                <b>{chapter.email_id}</b>
                              </td>

                              <td className="col-xs-4 text-end">
                                <FaEdit
                                  className="editicon"
                                  onClick={() => handleShowedit(chapter)}
                                />
                                {/* -----edit model  */}
                                <Modal
                                  centered
                                  show={showedit}
                                  onHide={handleCloseedit}
                                  scrollable={true}
                                  style={{
                                    marginTop: "15vh",
                                    height: "80vh",
                                    marginBottom: "5vh",
                                  }}
                                >
                                  <Modal.Header
                                    closeButton
                                    style={{
                                      backgroundColor: "#007BFF",
                                      color: "white",
                                    }}
                                  >
                                    <Modal.Title>
                                      Edit{" "}
                                      {getOrgNames?.alt_chapter || "Chapter"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {selectedRequest && (
                                      <Form>
                                        <div className="row">
                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="chapter_name"
                                                className="form-label"
                                              >
                                                {getOrgNames?.alt_chapter ||
                                                  "Chapter"}{" "}
                                                Name:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                className="form-control"
                                                value={
                                                  selectedRequest.chapter_name
                                                }
                                                id="chapter_name"
                                                placeholder="Enter chapter name"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="chapter_name"
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="phone_no"
                                                className="form-label"
                                              >
                                                Phone Number:
                                              </Form.Label>
                                              <Form.Control
                                                type="tel"
                                                className="form-control"
                                                value={selectedRequest.phone_no}
                                                id="phone_no"
                                                placeholder="Enter phone number"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="phone_no"
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        <Form.Group className="form-group col-sm-12">
                                          <Form.Label
                                            htmlFor="chapter_desc"
                                            className="form-label"
                                          >
                                            {getOrgNames?.alt_chapter ||
                                              "Chapter"}{" "}
                                            Description:
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            className="form-control"
                                            value={selectedRequest.chapter_desc}
                                            id="chapter_desc"
                                            placeholder="Enter chapter description"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="chapter_desc"
                                          />
                                        </Form.Group>

                                        <Form.Group className="form-group col-sm-12">
                                          <Form.Label
                                            htmlFor="street_address"
                                            className="form-label"
                                          >
                                            Street Address:
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            value={
                                              selectedRequest.street_address
                                            }
                                            id="street_address"
                                            placeholder="Enter street address"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="street_address"
                                          />
                                        </Form.Group>

                                        <div className="row">
                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="city"
                                                className="form-label"
                                              >
                                                City:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                className="form-control"
                                                value={selectedRequest.city}
                                                id="city"
                                                placeholder="Enter city"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="city"
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="state"
                                                className="form-label"
                                              >
                                                State:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                className="form-control"
                                                value={selectedRequest.state}
                                                id="state"
                                                placeholder="Enter state"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="state"
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="country"
                                                className="form-label"
                                              >
                                                Country:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                className="form-control"
                                                value={selectedRequest.country}
                                                id="country"
                                                placeholder="Enter country"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="country"
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="col-sm-6">
                                            <Form.Group className="form-group">
                                              <Form.Label
                                                htmlFor="zip_code"
                                                className="form-label"
                                              >
                                                Zip Code:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                className="form-control"
                                                value={selectedRequest.zip_code}
                                                id="zip_code"
                                                placeholder="Enter zip code"
                                                onChange={(e) =>
                                                  handleUpdateChange(e)
                                                }
                                                name="zip_code"
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        <Form.Group className="form-group col-sm-12">
                                          <Form.Label
                                            htmlFor="email_id"
                                            className="form-label"
                                          >
                                            Email ID:
                                          </Form.Label>
                                          <Form.Control
                                            type="email"
                                            className="form-control"
                                            value={selectedRequest.email_id}
                                            id="email_id"
                                            placeholder="Enter email ID"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="email_id"
                                          />
                                        </Form.Group>
                                      </Form>
                                    )}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseedit}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={handleUpdateSubmit}
                                    >
                                      Save Changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                &nbsp;&nbsp;
                                <FaTrash
                                  className="deleteicon"
                                  onClick={() =>
                                    handleShowdelete(chapter.chapter_id)
                                  }
                                />
                                {/* Delete Model */}
                                <Modal
                                  centered
                                  show={showdelete}
                                  onHide={handleClosedelete}
                                >
                                  <Modal.Header
                                    closeButton
                                    style={{
                                      backgroundColor: "#007BFF",
                                      color: "white",
                                    }}
                                  >
                                    <Modal.Title>
                                      Delete{" "}
                                      {getOrgNames?.alt_chapter || "Chapter"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to delete this{""}
                                    {getOrgNames?.alt_chapter || "Chapter"}?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClosedelete}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={handleDeleteSubmit}
                                    >
                                      Delete
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                &nbsp;&nbsp;
                                {expandedRow === index ? (
                                  <FiChevronUp
                                    size={30}
                                    onClick={() => toggleExpandRow(index)}
                                  />
                                ) : (
                                  <FiChevronDown
                                    size={30}
                                    onClick={() => toggleExpandRow(index)}
                                  />
                                )}
                              </td>
                            </tr>

                            {expandedRow === index && (
                              <tr className="expanded-row">
                                <td colSpan="5">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <p>
                                        <b>
                                          {getOrgNames?.alt_chapter ||
                                            "Chapter"}{" "}
                                          Description:{" "}
                                          {chapter.chapter_desc || "N/A"}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                          Phone Number:{" "}
                                          {chapter.phone_no || "N/A"}
                                        </b>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <p>
                                        <b>
                                          Address:{" "}
                                          {chapter.street_address || "N/A"},{" "}
                                          {chapter.city || "N/A"},{" "}
                                          {chapter.state || "N/A"},{" "}
                                          {chapter.country || "N/A"},
                                          {chapter.zip_code || "N/A"}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <p>No {getOrgNames?.alt_chapter || "Chapter"} List.</p>
                    )}
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 9999,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default Chapters;

// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   Table,
//   Button,
//   Modal,
// } from "react-bootstrap";
// import "./customcss/admintabstyle.css";
// import { FiChevronDown, FiChevronUp } from "react-icons/fi";
// import { FaTrash, FaEdit } from "react-icons/fa";

// const Chapters = () => {
//   const [chapters, setChapters] = useState([]);
//   const [expandedRow, setExpandedRow] = useState(null);
//   const [showdelete, setShowdelete] = useState(false);
//   const handleClosedelete = () => setShowdelete(false);
//   const handleShowdelete = () => setShowdelete(true);
//   const [showedit, setShowedit] = useState(false);
//   const handleCloseedit = () => setShowedit(false);
//   const handleShowedit = () => setShowedit(true);

//   useEffect(() => {
//     fetch("process.env.REACT_APP_API_URL/chapters/chapters")
//       .then((response) => response.json())
//       .then((data) => {
//         setChapters(data);
//       })
//       .catch((error) => console.error("Error fetching data: ", error));
//   }, []);

//   const toggleExpandRow = (index) => {
//     if (expandedRow === index) {
//       setExpandedRow(null);
//     } else {
//       setExpandedRow(index);
//     }
//   };

//   return (
//     <>
//       <div id="content-page" className="content-page">
//         <Container>
//           <Row>
//             <Col lg={12}>
//               <Card>
//                 <Card.Body className="p-0">
//                   <div className="user-tabing p-3">
//                     <div className="d-flex flex-wrap align-items-center justify-content-between">
//                       <div
//                         variant="pills"
//                         className=" d-flex align-items-center text-center profile-forum-items p-0 m-0 w-75"
//                       >
//                         <Col lg={3} className=" p-3">
//                           <h3>Chapters</h3>
//                         </Col>
//                       </div>
//                       <Button type="submit" className="btn btn-primary">
//                         Add Chapter
//                       </Button>
//                     </div>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </Col>

//             {chapters.length > 0 ? (
//               <Col sm={12}>
//                 <Card>
//                   <Card.Body className="p-0">
//                     <Table responsive className="forum-table mb-0 rounded">
//                       {/* ----table head code */}
//                       <thead className="bg-primary text-white">
//                         <tr>
//                           <th>
//                             <b>Chapter ID</b>
//                           </th>
//                           <th>
//                             <b>Chapter Name</b>
//                           </th>
//                           <th>
//                             <b>Action</b>
//                           </th>
//                         </tr>
//                       </thead>

//                       {/* -----table body code */}
//                       <tbody>
//                         {chapters.map((chapter, index) => (
//                           <React.Fragment key={index}>
//                             <tr>
//                               <td className="col-lg-5">
//                                 <b>{chapter.chapter_id}</b>
//                               </td>
//                               <td className="col-lg-5">
//                                 <b>{chapter.chapter_name}</b>
//                               </td>

//                               <td className="col-lg-2">
//                                 <FaEdit size={15} onClick={handleShowedit} />
//                                 {/* Edit Model */}
//                                 <Modal
//                                   centered
//                                   show={showedit}
//                                   onHide={handleCloseedit}
//                                 >
//                                   <Modal.Header closeButton>
//                                     <Modal.Title>Edit Row</Modal.Title>
//                                   </Modal.Header>
//                                   <Modal.Body>
//                                     Are you sure you want to edit this row?
//                                   </Modal.Body>
//                                   <Modal.Footer>
//                                     <Button
//                                       variant="secondary"
//                                       onClick={handleCloseedit}
//                                     >
//                                       Close
//                                     </Button>
//                                     <Button
//                                       variant="primary"
//                                       onClick={handleCloseedit}
//                                     >
//                                       Save Changes
//                                     </Button>
//                                   </Modal.Footer>
//                                 </Modal>
//                                 &nbsp;&nbsp;
//                                 <FaTrash size={15} onClick={handleShowdelete} />
//                                 {/* Delete Model */}
//                                 <Modal
//                                   centered
//                                   show={showdelete}
//                                   onHide={handleClosedelete}
//                                 >
//                                   <Modal.Header closeButton>
//                                     <Modal.Title>Delete Row</Modal.Title>
//                                   </Modal.Header>
//                                   <Modal.Body>
//                                     Are you sure you want to delete this row?
//                                   </Modal.Body>
//                                   <Modal.Footer>
//                                     <Button
//                                       variant="secondary"
//                                       onClick={handleClosedelete}
//                                     >
//                                       Close
//                                     </Button>
//                                     <Button
//                                       variant="primary"
//                                       onClick={handleClosedelete}
//                                     >
//                                       Save Changes
//                                     </Button>
//                                   </Modal.Footer>
//                                 </Modal>
//                                 &nbsp;&nbsp;
//                                 <b>
//                                   <FiChevronDown
//                                     size={30}
//                                     onClick={() => toggleExpandRow(index)}
//                                   />
//                                   {/* <FiChevronUp /> */}
//                                 </b>
//                               </td>
//                             </tr>

//                             {expandedRow === index && (
//                               <tr className="expanded-row">
//                                 <td colSpan="5">
//                                   <div className="row">
//                                     <div className="col-lg-6">
//                                       <p>
//                                         <b>
//                                           Email ID: {chapter.email_id || "N/A"}
//                                         </b>
//                                       </p>
//                                       <p>
//                                         <b>
//                                           Phone Number:{" "}
//                                           {chapter.phone_no || "N/A"}
//                                         </b>
//                                       </p>
//                                     </div>
//                                     <div className="col-lg-6">
//                                       <p>
//                                         <b>
//                                           Address:{" "}
//                                           {chapter.street_address || "N/A"},{" "}
//                                           {chapter.city || "N/A"},{" "}
//                                           {chapter.state || "N/A"},{" "}
//                                           {chapter.country || "N/A"},
//                                           {chapter.zip_code || "N/A"}
//                                         </b>
//                                       </p>
//                                     </div>
//                                   </div>
//                                 </td>
//                               </tr>
//                             )}
//                           </React.Fragment>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ) : (
//               <p>No Chapters List.</p>
//             )}
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default Chapters;
