import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// react-boostrap
import {
  Container,
  Col,
  Row,
  Dropdown,
  ProgressBar,
  Card,
  Button,
} from "react-bootstrap";
// fullcalender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { useNavigate } from "react-router-dom";
// components
// import Card from '../../../components/Card'
import CustomToggle from "../../../components/dropdowns";

// Datepicker
import Datepicker from "../../../components/datepicker";
import Swal from "sweetalert2"; // Import Swal

// apex-chart
import Chart from "react-apexcharts";
import useOrgNaming from "../../../hooks/getNamingHook";
const Admin = () => {
  const navigate = useNavigate();
  const [totalMembershipReq, setTotalMembershipReq] = useState(0);
  const [memberData, setMemberData] = useState([]);
  const [memberData1, setMemberData1] = useState([]);

  const [memberDatacal, setMemberDataCal] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [allMeetings, setAllMeetings] = useState([]);
  const [totalReferrals, setTotalReferrals] = useState(null);
  const [totalReferralsAmt, setTotalReferralsAmt] = useState(null);
  const [totalMyReferrals, setTotalMyReferrals] = useState(null);
  const [totalSentReferrals, setTotalSentReferrals] = useState(null);
  const [memberMeetings, setMemberMeetings] = useState({
    chapterMeetingsCount: 0,
    oneOnOneMeetingsCount: 0,
  });
  const [chapterMeetings, setChapterMeetings] = useState(null);
  const org_id = sessionStorage.getItem("org_id");
  const userRole = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const [events, setEvents] = useState([]);
  const [tranning, settranning] = useState([]);
  const [metting, setmetting] = useState([]);
  const [eventsChapter, setEventsChapter] = useState([]);
  const [tranningChapter, settranningChapter] = useState([]);
  const [mettingChapter, setmettingChapter] = useState([]);
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  const [memberCategories, setMemberCategories] = useState([]);
  const [adminChapterMeeting, setAdminChapterMeeting] = useState([]);

  //Navigate to related Referals
  const sendReferals = () => {
    //   const dataToSend = 'YourDataHere';
    // navigate("/dashboard/app/referralsList");
    if (userRole === "ORG_ADMIN") {
      navigate("/dashboard/app/referralsList");
    } else {
      // Handle other roles or default case
      navigate("/dashboards/app/chapter-received-referrals");
    }
  };
  //Navigate to related Referals page

  const sendMemberMeeting = () => {
    // Handle other roles or default case
    navigate("/dashboards/app/memberMeetingAdmin");
  };
  //Navigate to related Chapter meeting page

  const sendMeeting = () => {
    // Handle other roles or default case
    navigate("/dashboards/app/chapter-meeting");
  };
  //Navigate to related Thankyou note page

  const sendMembership = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/org-thank-you-notes-recieved");
  };
  //Navigate to related Memebers  page

  const sendMembers = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboards/app/members");
  };

  // handel the cander event click and event render
  const renderEventContent = (eventInfo) => {
    let symbol = "";
    let textColor = "";
    let bgColor = "";

    // Determine the symbol, text color, and background color based on the event type
    if (eventInfo.event.extendedProps.type === "Event") {
      symbol = "E"; // Symbol for meeting
      textColor = "white"; // Text color for meetings
      bgColor = "blue"; // Background color for meetings
    } else if (eventInfo.event.extendedProps.type === "Training") {
      symbol = "T"; // Symbol for training
      textColor = "white"; // Text color for trainings
      bgColor = "green"; // Background color for trainings
    } else {
      symbol = "M"; // Symbol for event
      textColor = "white"; // Text color for events
      bgColor = "gray"; // Background color for events
    }

    // Return a div with the appropriate symbol, text color, and background color
    return (
      <div
        className="event-symbol"
        style={{
          color: textColor,
          backgroundColor: bgColor,
          fontWeight: "bold",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {symbol}
      </div>
    );
  };

  const handleEventClick = (arg) => {
    // Format start date
    const startDate = new Date(arg.event.start).toLocaleString();
    // Format end date, if available
    const endDate = arg.event.end
      ? new Date(arg.event.end).toLocaleString()
      : "N/A";
    Swal.fire({
      title: arg.event.title,
      html: `
      <p>Start Date: ${startDate}</p>
      <p>End Date: ${endDate}</p>
      <p>Description: ${
        arg.event.extendedProps.description
          ? arg.event.extendedProps.description
          : "N/A"
      }</p>      `,
    });
  };
  //get the calander data for the super admin
  //fetch the data of event for the  org admin calaneder
  const fetchEventData = async () => {
    try {
      const eventResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/event/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const eventData = eventResponse.data.processedEvents;
      const formattedEvents = eventData.map((event, index) => {
        const meetingDateParts = event.event_start_date
          .split("T")[0]
          .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
        const meetingYear = parseInt(meetingDateParts[0], 10);
        const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
        const meetingDay = parseInt(meetingDateParts[2], 10);
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours, minutes, seconds] = event.event_time_in
          .split(":")
          .map(Number);
        const eventTimeIn = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours,
          minutes,
          seconds
        );
        // Formatting the time in UTC
        const formattedStartTime = eventTimeIn.toISOString();
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours1, minutes1, seconds1] = event.event_time_out
          .split(":")
          .map(Number);
        const eventTimeOut = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours1,
          minutes1,
          seconds1
        );
        // Formatting the time in UTC
        const formattedEndTime = eventTimeOut.toISOString();
        const eventId = `event_${index}`; // Create a unique ID for each event
        // Process event data
        const title = `Event: ${event.event_topic}`;
        return {
          id: eventId,
          title: title,
          start: formattedStartTime, // Concatenate date and start time
          end: formattedEndTime, // Use the same formatted datetime for both start and end
          description: event.event_details,
          // color: 'red',
          type: "Event", // Set the type property for meetings
        };
      });

      // Append unique events to events state and update eventIds state
      setEvents(formattedEvents);
      // setTotalDataa(formattedEvents)
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  //fetch the data of  tranning for the  org admin calaneder
  const fetchTrainingData = async () => {
    try {
      const trainingResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/training/gettrainings/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const trainingData = trainingResponse.data.trainingwithUrl;
      const formattedTrainings = trainingData.map((training, index) => {
        const meetingDateParts = training.train_start_date
          .split("T")[0]
          .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
        const meetingYear = parseInt(meetingDateParts[0], 10);
        const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
        const meetingDay = parseInt(meetingDateParts[2], 10);
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours, minutes, seconds] = training.train_start_time
          .split(":")
          .map(Number);
        const eventTimeIn = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours,
          minutes,
          seconds
        );
        // Formatting the time in UTC
        const formattedStartTime = eventTimeIn.toISOString();
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours1, minutes1, seconds1] = training.train_end_time
          .split(":")
          .map(Number);
        const eventTimeOut = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours1,
          minutes1,
          seconds1
        );
        // Formatting the time in UTC
        const formattedEndTime = eventTimeOut.toISOString();
        const trainingId = `training_${index}`; // Create a unique ID for each training
        // Process training data
        const title = `Training: ${training.training_topic}`;
        return {
          id: trainingId,
          title: title,
          start: formattedStartTime, // Concatenate date and start time
          end: formattedEndTime, // Use the same formatted datetime for both start and end
          description: training.training_details,
          // color: 'skyblue',
          type: "Training", // Set the type property for meetings
        };
      });
      // Append unique trainings to events state and update eventIds state
      settranning(formattedTrainings);
      // setTotalDataa(...totaldataa , formattedTrainings)
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };
  //fetch the data of  meeting for the  org admin calaneder
  const fetchMeetingData = async () => {
    try {
      // Fetch meetings by member_id
      const userMeetingResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/meetings/oneonone-meeting/user/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userMeetingData = userMeetingResponse.data.results;
      // Fetch meetings by memberId
      const memberMeetingResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/meetings/data/total-meetings/org/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const memberMeetingData = memberMeetingResponse.data.results;
      // Filter meetings where meeting_type is equal to "Chapter"
      const filteredMeetings = memberMeetingData.filter(
        (meeting) => meeting.meeting_type === "Chapter"
      );

      // Merge the results
      const allMeetingData = [...userMeetingData, ...filteredMeetings];
      const formattedMeetings = allMeetingData.map((meeting, index) => {
        // Extracting date from meeting_date
        const meetingDateParts = meeting.meeting_date.split("T")[0].split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
        const meetingYear = parseInt(meetingDateParts[0], 10);
        const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
        const meetingDay = parseInt(meetingDateParts[2], 10);
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours, minutes, seconds] = meeting.meeting_start_time
          .split(":")
          .map(Number);
        const meetingStartTime = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours,
          minutes,
          seconds
        );
        // Formatting the time in UTC
        const formattedStartTime = meetingStartTime.toISOString();
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours1, minutes1, seconds1] = meeting.meeting_end_time
          .split(":")
          .map(Number);
        const meetingEndTime = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours1,
          minutes1,
          seconds1
        );
        // Formatting the time in UTC
        const formattedEndTime = meetingEndTime.toISOString();
        const meetingId = `meeting_${index}`; // Create a unique ID for each meeting
        // Process meeting data
        const title = `Meeting: ${meeting.meeting_subject}`;
        return {
          id: meetingId,
          title: title,
          start: formattedStartTime, // Concatenate date and start time
          end: formattedEndTime, // Use the same formatted datetime for both start and end
          description: meeting.meeting_desc,
          type: "Meeting", // Set the type property for meetings
        };
      });
      // Append unique meetings to events state and update eventIds state
      setmetting(formattedMeetings);
    } catch (error) {
      console.error("Error fetching meeting data:", error);
    }
  };

  // get calender data for the chater admin
  //fetch the data of event for the  chapter admin calaneder
  const fetchChapterEventData = async () => {
    try {
      const eventResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/event/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const eventData = eventResponse.data.processedEvents;
      const adminEventData = eventData.filter(
        (meeting) => meeting.created_by == member_id
      );
      const formattedEvents = adminEventData.map((event, index) => {
        const meetingDateParts = event.event_start_date
          .split("T")[0]
          .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
        const meetingYear = parseInt(meetingDateParts[0], 10);
        const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
        const meetingDay = parseInt(meetingDateParts[2], 10);
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours, minutes, seconds] = event.event_time_in
          .split(":")
          .map(Number);
        const eventTimeIn = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours,
          minutes,
          seconds
        );
        // Formatting the time in UTC
        const formattedStartTime = eventTimeIn.toISOString();
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours1, minutes1, seconds1] = event.event_time_out
          .split(":")
          .map(Number);
        const eventTimeOut = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours1,
          minutes1,
          seconds1
        );
        // Formatting the time in UTC
        const formattedEndTime = eventTimeOut.toISOString();
        const eventId = `event_${index}`; // Create a unique ID for each event
        // Process event data
        const title = `Event: ${event.event_topic}`;
        return {
          id: eventId,
          title: title,
          start: formattedStartTime, // Concatenate date and start time
          end: formattedEndTime, // Use the same formatted datetime for both start and end
          description: event.event_details,
          type: "Event",
        };
      });

      // Append unique events to events state and update eventIds state
      setEventsChapter(formattedEvents);
      // setTotalDataa(formattedEvents)
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  //fetch the data of  tranning for the  chapter admin calaneder
  const fetchChapterTrainingData = async () => {
    try {
      const trainingResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/training/gettrainings/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const trainingData = trainingResponse.data.trainingwithUrl;
      const admintrainingData = trainingData.filter(
        (meeting) => meeting.created_by == member_id
      );
      const formattedTrainings = admintrainingData.map((training, index) => {
        const meetingDateParts = training.train_start_date
          .split("T")[0]
          .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
        const meetingYear = parseInt(meetingDateParts[0], 10);
        const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
        const meetingDay = parseInt(meetingDateParts[2], 10);
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours, minutes, seconds] = training.train_start_time
          .split(":")
          .map(Number);
        const eventTimeIn = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours,
          minutes,
          seconds
        );
        // Formatting the time in UTC
        const formattedStartTime = eventTimeIn.toISOString();
        // Extracting hours, minutes, and seconds from meeting_start_time
        const [hours1, minutes1, seconds1] = training.train_end_time
          .split(":")
          .map(Number);
        const eventTimeOut = new Date(
          meetingYear,
          meetingMonth,
          meetingDay,
          hours1,
          minutes1,
          seconds1
        );
        // Formatting the time in UTC
        const formattedEndTime = eventTimeOut.toISOString();
        const trainingId = `training_${index}`; // Create a unique ID for each training
        // Process training data
        const title = `Training: ${training.training_topic}`;
        return {
          id: trainingId,
          title: title,
          start: formattedStartTime, // Concatenate date and start time
          end: formattedEndTime, // Use the same formatted datetime for both start and end
          description: training.training_details,
          type: "Training",
        };
      });
      // Append unique trainings to events state and update eventIds state
      settranningChapter(formattedTrainings);
      // setTotalDataa(...totaldataa , formattedTrainings)
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };
  //fetch the data of  meeting for the  chapter admin calaneder
  //get the all meeting and referals for  org admin
  const fetchMemberMeetings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/data/total-meetings/org/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }

      const data = await response.json();

      // Log the entire data to see the response structure

      if (data && data.results) {
        let allMeetings = data.results;

        // Log the full list of meetings

        let chapterMeetings = [];
        let oneOnOneMeetings = [];

        if (userRole == "CHAPTER_ADMIN") {
          // Filter for chapter-specific meetings
          chapterMeetings = allMeetings.filter(
            (meeting) =>
              meeting.chapter_id == chapterId &&
              meeting.meeting_type == "Chapter"
          );
          const adminMeetings = chapterMeetings.filter(
            (meeting) => meeting.organized_by == member_id
          );

          const formattedMeetings = adminMeetings.map((meeting, index) => {
            // Extracting date from meeting_date
            const meetingDateParts = meeting.meeting_date
              .split("T")[0]
              .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
            const meetingYear = parseInt(meetingDateParts[0], 10);
            const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
            const meetingDay = parseInt(meetingDateParts[2], 10);
            // Extracting hours, minutes, and seconds from meeting_start_time
            const [hours, minutes, seconds] = meeting.meeting_start_time
              .split(":")
              .map(Number);
            const meetingStartTime = new Date(
              meetingYear,
              meetingMonth,
              meetingDay,
              hours,
              minutes,
              seconds
            );
            // Formatting the time in UTC
            const formattedStartTime = meetingStartTime.toISOString();
            // Extracting hours, minutes, and seconds from meeting_start_time
            const [hours1, minutes1, seconds1] = meeting.meeting_end_time
              .split(":")
              .map(Number);
            const meetingEndTime = new Date(
              meetingYear,
              meetingMonth,
              meetingDay,
              hours1,
              minutes1,
              seconds1
            );
            // Formatting the time in UTC
            const formattedEndTime = meetingEndTime.toISOString();
            const meetingId = `meeting_${index}`; // Create a unique ID for each meeting
            // Process meeting data
            const title = `Meeting: ${meeting.meeting_subject}`;
            return {
              id: meetingId,
              title: title,
              start: formattedStartTime, // Concatenate date and start time
              end: formattedEndTime, // Use the same formatted datetime for both start and end
              description: meeting.meeting_desc,
              type: "Meeting",
            };
          });

          // Append unique meetings to events state and update eventIds state
          setmettingChapter(formattedMeetings);

          // Filter for one-on-one meetings where chapterId matches either p_chapter_id or o_chapter_id
          oneOnOneMeetings = allMeetings.filter((meeting) => {
            return (
              (meeting.p_chapter_id == chapterId &&
                meeting.o_chapter_id == chapterId &&
                meeting.meeting_type == "One-on-One") ||
              (meeting.p_chapter_id == chapterId &&
                meeting.meeting_type == "One-on-One") ||
              (meeting.o_chapter_id == chapterId &&
                meeting.meeting_type == "One-on-One")
            );
          });
        } else if (userRole == "ORG_ADMIN") {
          // Filter for chapter meetings (assuming there's a meeting_type field)
          chapterMeetings = allMeetings.filter(
            (meeting) => meeting.meeting_type == "Chapter"
          );
          const adminMeetings = chapterMeetings.filter(
            (meeting) => meeting.organized_by == member_id
          );

          const formattedMeetings = adminMeetings.map((meeting, index) => {
            // Extracting date from meeting_date
            const meetingDateParts = meeting.meeting_date
              .split("T")[0]
              .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
            const meetingYear = parseInt(meetingDateParts[0], 10);
            const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
            const meetingDay = parseInt(meetingDateParts[2], 10);
            // Extracting hours, minutes, and seconds from meeting_start_time
            const [hours, minutes, seconds] = meeting.meeting_start_time
              .split(":")
              .map(Number);
            const meetingStartTime = new Date(
              meetingYear,
              meetingMonth,
              meetingDay,
              hours,
              minutes,
              seconds
            );
            // Formatting the time in UTC
            const formattedStartTime = meetingStartTime.toISOString();
            // Extracting hours, minutes, and seconds from meeting_start_time
            const [hours1, minutes1, seconds1] = meeting.meeting_end_time
              .split(":")
              .map(Number);
            const meetingEndTime = new Date(
              meetingYear,
              meetingMonth,
              meetingDay,
              hours1,
              minutes1,
              seconds1
            );
            // Formatting the time in UTC
            const formattedEndTime = meetingEndTime.toISOString();
            const meetingId = `meeting_${index}`; // Create a unique ID for each meeting
            // Process meeting data
            const title = `Meeting: ${meeting.meeting_subject}`;
            return {
              id: meetingId,
              title: title,
              start: formattedStartTime, // Concatenate date and start time
              end: formattedEndTime, // Use the same formatted datetime for both start and end
              description: meeting.meeting_desc,
              type: "Meeting",
            };
          });

          // Append unique meetings to events state and update eventIds state
          setmettingChapter(formattedMeetings);
          // Filter for one-on-one meetings (assuming "One-on-One" is a valid meeting_type)
          oneOnOneMeetings = allMeetings.filter(
            (meeting) => meeting.meeting_type == "One-on-One"
          );
        }

        // Calculate counts
        const chapterMeetingsCount = chapterMeetings.length;
        const oneOnOneMeetingsCount = oneOnOneMeetings.length;

        // Log the counts to see if they're correct

        // Update state for both meeting types
        setMemberMeetings({
          chapterMeetingsCount,
          oneOnOneMeetingsCount,
        });
      } else {
        console.error(
          "Invalid or missing meetings results in the API response."
        );
      }
    } catch (err) {
      console.error("Error fetching total meetings:", err);
    }
  };

  const fetchTotalSentReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/chapter/get-referralByMe/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
      const data = await response.json();

      if (data && data.results) {
        const referralCount = data.results.length;
        setTotalSentReferrals(referralCount);
      } else {
        console.error(
          "Invalid or missing referral results in the API response."
        );
      }
    } catch (err) {
      console.error("Error fetching total referrals:", err);
      // Handle error
    }
  };

  const fetchTotalMyReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/chapter/get-referralByMe/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
      const data = await response.json();

      if (data && data.results) {
        const referralCount = data.results.length;
        setTotalMyReferrals(referralCount);
      } else {
        console.error(
          "Invalid or missing referral results in the API response."
        );
      }
    } catch (err) {
      console.error("Error fetching total referrals:", err);
      // Handle error
    }
  };
  // Define a function to call the APIs based on the condition
  const fetchData = () => {
    if (!chapterId) {
      fetchMemberMeetings();
      // fetchChapterMeetings();
      fetchTotalSentReferrals();
      fetchTotalMyReferrals();
      fetchEventData();
      fetchTrainingData();
      fetchMeetingData();
    } else {
      fetchChapterEventData();
      fetchChapterTrainingData();
      fetchMemberMeetings();
    }
  };
  useEffect(() => {
    // Initial fetch calls
    fetchData();
    // Set up interval
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [chapterId]);

  useEffect(() => {
    const fetchTotalMembershipReq = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/membershipRequest/membership-requests/org/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // including the token in the headers
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        let data, membershipReqCount;
        if (userRole === "ORG_ADMIN") {
          data = await response.json();
          if (data && data.requests) {
            membershipReqCount = data.requests.length;
            setTotalMembershipReq(membershipReqCount);
          } else {
            console.error(
              "Invalid or missing Membership requests results in the API response."
            );
          }
        } else {
          data = await response.json();
          if (data && data.requests) {
            const filterdata = data.requests.filter(
              (request) => request.chapter_id == chapterId
            );
            membershipReqCount = filterdata.length;
            setTotalMembershipReq(membershipReqCount);
          } else {
            console.error(
              "Invalid or missing Membership requests results in the API response."
            );
          }
        }
      } catch (err) {
        console.error("Error fetching total membership requests:", err);
        // Handle error
      }
    };

    fetchTotalMembershipReq();
  }, []);

  useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/chapters/orgChapters/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add your authorization header here
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json(); // Use json() to extract data from the response
        setChapterData(data);
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      }
    };

    fetchChapterData();
    fetchMemberCategories();
  }, []);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();

        if (!data || !data.imageUrls) {
          throw new Error(
            "Invalid or missing Membership requests results in the API response."
          );
        }

        let membershipReqCount;
        let filteredData;

        if (userRole === "ORG_ADMIN") {
          membershipReqCount = data.imageUrls.length;
          setMemberDataCal(data.imageUrls);
        } else {
          filteredData = data.imageUrls.filter(
            (request) => request.chapter_id == chapterId
          );
          membershipReqCount = filteredData.length;
          setMemberDataCal(filteredData);
        }
        setMemberData1(data.imageUrls);
        setMemberData(membershipReqCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchMemberData();
    fetchMemberMeetings();
  }, []);
  const fetchMemberCategories = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/memberCategory/get_org_memberCategory/${org_id}`,
        config
      );
      const data = response.data;

      // const categories = data.map((item) => item.member_category);
      setMemberCategories(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const calculatePercentage = (category) => {
    if (!memberData1 || memberData1.length === 0) return 0; // Handle empty or undefined memberData

    const totalMembers = memberData1.length; // Get the total number of members
    const categoryMembers = memberData1.filter(
      (member) => member.member_category === category // Filter members by the current category
    ).length;

    const percentage = Math.round((categoryMembers / totalMembers) * 100) || 0; // Calculate percentage and avoid division by zero

    return percentage; // Return the calculated percentage
  };

  ///Use effect for the calculate business_done_amount amount on dashboard
  useEffect(() => {
    const fetchTotalReferrals = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/referrals/get-referrals/org/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // including the token in the headers
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        const data = await response.json();

        // Log the entire data object to understand its structure
        if (data && data.results) {
          const referralCount = data.results.length;
          let totalRefAmoun = 0;
          if (userRole === "ORG_ADMIN") {
            data.results.forEach((item) => {
              if (
                item.business_done_amount !== undefined &&
                item.business_done_amount !== null
              ) {
                totalRefAmoun += parseFloat(item.business_done_amount);
              }
            });
            setTotalReferralsAmt(totalRefAmoun);
            setTotalReferrals(referralCount);
          } else {
            // CHAPTER_ADMIN: Filter by chapterId
            const chapterFilteredResults = data.results.filter(
              (item) => item.chapter_id == chapterId
            );

            const referralCount = chapterFilteredResults.length; // Count filtered referrals
            chapterFilteredResults.forEach((item) => {
              if (
                item.business_done_amount !== undefined &&
                item.business_done_amount !== null
              ) {
                totalRefAmoun += parseFloat(item.business_done_amount);
              }
            });
            setTotalReferrals(referralCount);
            setTotalReferralsAmt(totalRefAmoun);
          }
        } else {
          console.error(
            "Invalid or missing referral results in the API response."
          );
        }
      } catch (err) {
        console.error("Error fetching total referrals:", err);
        // Handle error
      }
    };

    fetchTotalReferrals();
  }, []);

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="mb-3">
            <Col md="6" lg="3" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Button
                    variant="link"
                    onClick={sendMembership}
                    className="text-decoration-none p-0"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="text-muted">Business Done</h5>
                      <h4 className="mb-0">
                        ₹{!totalReferralsAmt ? 0 : totalReferralsAmt}
                      </h4>
                    </div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Button
                    variant="link"
                    onClick={sendMembers}
                    className="text-decoration-none p-0"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="text-muted">Members</h5>
                      <h4 className="mb-0">{!memberData ? 0 : memberData}</h4>
                    </div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Button
                    variant="link"
                    onClick={sendReferals}
                    className="text-decoration-none p-0"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="text-muted">Referrals</h5>
                      <h4 className="mb-0">{totalReferrals}</h4>
                    </div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Button
                    variant="link"
                    onClick={sendMemberMeeting}
                    className="text-decoration-none p-0"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="text-muted">
                        Member {getOrgNames?.alt_meeting || "Meetings"}
                      </h5>
                      <h4 className="mb-0">
                        {memberMeetings.oneOnOneMeetingsCount}
                        {/* {!(userRole === "ORG_ADMIN" ||
                        userRole === "CHAPTER_ADMIN"
                          ? allMeetings
                          : memberMeetings + chapterMeetings)
                          ? 0
                          : userRole === "ORG_ADMIN" ||
                            userRole === "CHAPTER_ADMIN"
                          ? allMeetings
                          : memberMeetings + chapterMeetings} */}
                      </h4>
                    </div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Button
                    variant="link"
                    onClick={sendMeeting}
                    className="text-decoration-none p-0"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="text-muted">
                        {/* {getOrgNames?.alt_meeting || "Meetings"} */}
                        Chapter {getOrgNames?.alt_meeting || "Meetings"}
                      </h5>
                      <h4 className="mb-0">
                        {memberMeetings.chapterMeetingsCount}
                        {/* {!(userRole === "ORG_ADMIN" ||
                        userRole === "CHAPTER_ADMIN"
                          ? allMeetings
                          : memberMeetings + chapterMeetings)
                          ? 0
                          : userRole === "ORG_ADMIN" ||
                            userRole === "CHAPTER_ADMIN"
                          ? allMeetings
                          : memberMeetings + chapterMeetings} */}
                      </h4>
                    </div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <Col md="8" lg="8"> */}
            <Card>
              <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, listPlugin]}
                  events={[
                    ...eventsChapter,
                    ...tranningChapter,
                    ...mettingChapter,
                  ]}
                  eventClick={handleEventClick}
                  eventContent={renderEventContent} // Use the renderEventContent callback                  // eventRender={handleEventRender} // Use the eventRender callback
                  // eventColor='#378006' // Set the color for all events
                />
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Row as="ul" className="list-unstyled mb-3">
                {chapterData.length > 0 ? (
                  chapterData.map((chapter) => (
                    <Col md="6" lg="6" as="li" key={chapter.chapter_id}>
                      <Card>
                        <Card.Body>
                          <div className="mt-4">
                            <h3>{chapter.active_member_count}</h3>
                            <p className="mb-0">
                              {chapter.chapter_name} Members
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No chapter data available</p>
                )}
              </Row>
            </Col>
            <Col lg="6" md="6">
              <Card>
                <Card.Header>
                  <div className="header-title">
                    <h4 className="card-title">Member Categories</h4>
                  </div>
                </Card.Header>
                <Card.Body style={{ overflowX: "scroll", maxHeight: "67vh" }}>
                  {memberCategories.map((category, index) => (
                    <div className="mb-3" key={category.mcategory_id}>
                      <div className="d-flex justify-content-between mt-2 text-dark">
                        <h6>{category.member_category}</h6>
                        <small>{`${calculatePercentage(
                          category.member_category
                        )}%`}</small>
                      </div>
                      <ProgressBar
                        variant={
                          index % 2 === 0
                            ? "danger"
                            : index % 3 === 0
                            ? "primary"
                            : index % 5 === 0
                            ? "success"
                            : "warning"
                        }
                        className="mt-2"
                        now={calculatePercentage(category.member_category)}
                        style={{ height: "6px" }}
                      />
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Admin;
