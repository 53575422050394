import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Tab,
  Nav,
  Dropdown,
  Modal,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import Card from "../../../components/Card";
import CustomToggle from "../../../components/dropdowns";
import { Link } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import ShareOffcanvas from "../../../components/share-offcanvas";
import { useLocation } from "react-router-dom";
import { BsCalendar } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

// images
import img1 from "../../../assets/images/page-img/profile-bg1.jpg";
import user1 from "../../../assets/images/user/1.png";
import icon1 from "../../../assets/images/icon/01.png";
import icon2 from "../../../assets/images/icon/02.png";
import icon3 from "../../../assets/images/icon/03.png";
import icon4 from "../../../assets/images/icon/04.png";
import icon5 from "../../../assets/images/icon/05.png";
import icon6 from "../../../assets/images/icon/06.png";
import icon7 from "../../../assets/images/icon/07.png";
import iconD from "../../../assets/images/icon/15.png";

import g1 from "../../../assets/images/page-img/g1.jpg";
import g2 from "../../../assets/images/page-img/g2.jpg";
import g3 from "../../../assets/images/page-img/g3.jpg";
import g4 from "../../../assets/images/page-img/g4.jpg";
import g5 from "../../../assets/images/page-img/g5.jpg";
import g6 from "../../../assets/images/page-img/g6.jpg";
import g7 from "../../../assets/images/page-img/g7.jpg";
import g8 from "../../../assets/images/page-img/g8.jpg";
import g9 from "../../../assets/images/page-img/g9.jpg";
import small1 from "../../../assets/images/small/07.png";

import CreateReferral from "./createReferral";
import Referrals from "./referrals";
import ProfilePhotos from "./profilePhotos"; // Adjust the path as per your file structure
import ProfileMeetings from "./profileMeetings";
import MemberMeeting from "./memberMeeting";

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const FriendProfile = () => {
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const fileInputRef = useRef(null);
  const [show, setShow] = useState(false);

  const memberId = sessionStorage.getItem("member_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const userId = sessionStorage.getItem("userID");
  //Fetch the data of member by id
  const [memberData, setMemberData] = useState(null);
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  const { state } = location;
  const [selectedImagee, setSelectedImagee] = useState(null);
  const [postData, setPostData] = useState("");
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [databaseImage, setDatabaseImage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  // const [showComments, setShowComments] = useState(false);
  const [postComment, setPostComment] = useState("");
  const [postLiked, setPostLiked] = useState(false);
  const [clicked, setClicked] = useState(false);
  // const [userID, setUserID] = useState("");
  const [memberID, setMemberID] = useState("");
  const useridd = location?.state?.userid;
  const [likedPosts, setLikedPosts] = useState({}); // Track liked posts
  const [dislikes, setDislikes] = useState({});

  // const userid = useridd ? useridd : memberId;

 let mmmid;

  // useEffect to fetch userID and memberID

  useEffect(() => {
    // setUserID(userId);
    const mmid = location?.state?.state;
    mmmid = mmid ? mmid : memberId
    setMemberID(mmmid);
  }, []);

  const handleClose = () => {
    setShow(false);
    setInputValue("");
    setTextValue("");
    setSelectedImagee(false);

    setIsImageSelected(false);
  };
  const handleShow = () => setShow(true);

  const handleAddPohoto = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
  };

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  const handleReferButton = () => {
    setSelectedComponent("refer");
  };

  const handleMeetingButton = () => {
    setSelectedComponent("meeting");
  };
  const handleCloseButton = () => {
    setSelectedComponent(null);
  };

  const fetchMemberData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/members/members/${mmmid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // Setting the Cache-Control header as a configuration option
          "Cache-Control": "no-cache",
        }
      );

      setMemberData(response.data.result);
      setDatabaseImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };

  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  const handleChange = (e) => {
    const inputV = e.target.value;
    setInputValue(inputV);
  };

  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    // Do something with the selected file, e.g., upload or process it
    //  console.log("Selected file:", selectedFile);
    setSelectedImagee(selectedFile);
    //  console.log("selected Image 2---->>>>", selectedImage);
    if (selectedFile) {
      setIsImageSelected(true);
    } else {
      setIsImageSelected(false);
    }
  };

  const handlePost = async () => {
    if (!clicked) {
      // Set the state to indicate the button has been clicked
      setClicked(true);

      try {
        // Create a FormData object and append the image file to it
        const formData = new FormData();
        formData.append("post_photovideo_path", selectedImagee);

        // Append other data to the FormData object
        formData.append("post_title", inputValue);
        formData.append("created_by", memberID);
        formData.append("post_content", textValue);
        formData.append("member_id", memberID);
        formData.append("chapter_id", chapterId);

        // Perform the POST request using async/await
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/postt/userpostnew`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`, // including the token in the headers
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server

        // setPostData((prevPosts) => [data.postWithPresignedUrls, ...prevPosts]);

        // Optionally, reset the input field after a successful post
        setInputValue("");
        setTextValue("");
        setSelectedImagee(false);

        setIsImageSelected(false);
        fetchPost();
        // showSuccess()
        handleSuccess("Post Created Successfully !!!");
        // fetchMemberData();
        // fetchPost();
        // fetchMemberData();

        handleClose();
        setClicked(false);
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  };

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/postt/get-userpost/new/${mmmid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );
      const data = response.data; // Use response.data directly, as axios parses JSON
      setPostData(data.postsWithPresignedUrls);
    } catch (error) {
      console.error("Error fetching post data", error);
    }
  };

  // useEffect(() => {
  //   fetchPost();
  //   fetchMemberData();
  // }, [userID]);

  useEffect(() => {
    const fetchPostData = async () => {
      fetchPost();
      // console.log("memid", mmmid);
      fetchMemberData();
    };

    // Fetch initial data
    fetchPostData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchPostData();
    }, 3000);
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mmmid]);

  // Sort the postData array based on the 'created_on' timestamp in descending order
  // this is to show latest post above

  const sortedDataa = useMemo(() => {
    if (Array.isArray(postData)) {
      return [...postData].sort(
        (a, b) => new Date(b.post_id) - new Date(a.post_id)
      );
    } else {
      console.error("postData is not an array");
      return [];
    }
  }, [postData]); // Ensure that postData is the only dependency, not sortedData

  //   const handleAddPohoto = () => {
  //     console.log('photo added successfully !!!');
  //     // Trigger click on the hidden file input
  //     fileInputRef.current.click();
  // };

  const handleImageClick = () => {
    // Check if the ref is not null before calling click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedProfileImage(reader.result);
        updateMemberImage(file);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        // Handle error when reading file
      };
      reader.readAsDataURL(file);
    }
  };

  // Determine which image to display
  const displayImage = selectedProfileImage || databaseImage;

  const updateMemberImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("profileImage", file);
      formData.append("fieldName", "profileImage");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members/upload-image/${memberId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        // Handle non-successful response
        // Show error message to the user
        // setError('There was a problem with the update request');
      } else {
        // Handle the successful response
        const responseData = await response.json();
      }
    } catch (error) {
      // Handle errors
      // Show error message to the user
      // setError('There was a problem with the update request');
      // ... rest of the code to handle errors
    }
  };

  // to hide and show comments

  function handleComment() {
    setShowComments(!showComments);
  }

  function handleTextArea(e) {
    setTextValue(e.target.value);
    // console.log("text area", textValue);
  }

  const handlePostComment = (e) => {
    setPostComment(e.target.value);
  };

  const postCommentFunction = async (postID) => {
    // console.log("postID----->>>", postID);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/pcomment/postcomment/user",
        {
          comments: postComment,
          created_by: memberID,
          member_id: memberID,
          post_id: postID,
        }
      );

      if (!response.status === 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = response.data; // Access response data directly

      // console.log("post data-->", data);
      fetchPost();

      setPostComment("");
    } catch (err) {
      console.error("Error saving post comment:", err.message);
    }
  };

  // const postLikeFunction = async (postID, postLikes) => {
  //   //     if(postLiked){

  //   //         try{

  //   //             const response =  await axios.put(process.env.REACT_APP_API_URL +'/pcomment/postlikes/user', {
  //   //                 post_id : postID,
  //   //                 post_likes:postLikes-1,
  //   //             })

  //   //             if(response.status !== 201){
  //   //             throw new Error(`HTTP error! Status: ${response.status}`);

  //   //             }
  //   //             const data = response.data;  // Access response data directly

  //   //             console.log('post dislike-->', data);
  //   //         setPostLiked(false);
  //   //         fetchPost();
  //   //         return;

  //   //         }catch(err){
  //   //             if(err){
  //   //                 console.log('error while dislike post',err.message)
  //   //             }
  //   //         }
  //   //     }

  //   //    else{
  //   if (!postLiked) {
  //     try {
  //       const response = await axios.put(
  //         process.env.REACT_APP_API_URL + "/pcomment/postlikes/user",
  //         {
  //           post_id: postID,
  //           post_likes: postLikes + 1,
  //         }
  //       );

  //       if (response.status !== 201) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       // Assuming the like is successfully updated on the server,
  //       // set postLiked to true to indicate that the post has been liked
  //       setPostLiked(true);

  //       // Fetch updated post data
  //       fetchPost();
  //     } catch (err) {
  //       console.error("Error while liking post:", err.message);
  //     }
  //   }
  // };
  const postLikeFunction = async (postId) => {
    const userId = sessionStorage.getItem("member_id");

    // Check if the post is already liked by the current user
    // if (likedPosts[postId]) {
    //   return; // Exit if the post is already liked
    // }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/pcomment/checkPostLiked",
        { post_id: postId, member_id: userId }
      );
      console.log("reaction------>", response.data.liked);
      if (response.data.liked === "like") {
        return;
      }
      // return { post_id: post.post_id, reaction: response.data.reaction }; // Store the reaction type
    } catch (error) {
      console.error(`Error fetching reaction for post ${postId}:`, error);
      // return { post_id: post.post_id, reaction: null }; // Fallback if error occurs
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
        {
          post_id: postId,
          user_id: userId,
          reaction_type: "like",
        }
      );

      if (response.status === 200) {
        // Update the local liked posts state
        setLikedPosts((prev) => ({ ...prev, [postId]: true }));

        // Optionally update the post likes count in the UI
        setPostData((prevPosts) =>
          prevPosts.map((post) =>
            post.post_id === postId
              ? { ...post, post_likes: post.post_likes + 1 }
              : post
          )
        );
      }
    } catch (error) {
      console.error("Error liking the post:", error);
    }
  };

  // async function postDisLikeFunction(postID, postLikes) {
  //   if (postLiked) {
  //     try {
  //       const response = await axios.put(
  //         process.env.REACT_APP_API_URL + "/pcomment/postlikes/user",
  //         {
  //           post_id: postID,
  //           post_likes: postLikes - 1,
  //         }
  //       );

  //       if (response.status !== 201) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       const data = response.data; // Access response data directly

  //       console.log("post dislike-->", data);
  //       setPostLiked(false);
  //       fetchPost();
  //       return;
  //     } catch (err) {
  //       if (err) {
  //         console.log("error while dislike post", err.message);
  //       }
  //     }
  //   }
  // }

  const postDisLikeFunction = async (postId) => {
    const userId = sessionStorage.getItem("member_id");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
        { post_id: postId, user_id: userId, reaction_type: "dislike" }
      );
      if (response.status === 200) {
        // Update local state
        setDislikes((prev) => ({ ...prev, [postId]: (prev[postId] || 0) + 1 }));
        if (likedPosts[postId]) {
          setLikedPosts((prev) => ({ ...prev, [postId]: prev[postId] - 1 }));
        }
      }
    } catch (error) {
      console.error("Error disliking the post:", error);
    }
  };

  return (
    <>
      <div>
        {memberData ? (
          <div>
            <FsLightbox
              toggler={imageController.toggler}
              sources={[g1, g2, g3, g4, g5, g6, g7, g8, g9]}
              slide={imageController.slide}
            />
            <Container>
              <Row>
                <Col sm={12}>
                  <Card>
                    <Card.Body className=" profile-page p-0">
                      <div className="profile-header profile-info">
                        <div className="cover-container">
                          <img
                            loading="lazy"
                            src={img1}
                            alt="profile-bg"
                            className="rounded img-fluid"
                          />
                        </div>
                        <div className="user-detail text-center mb-3">
                          {memberID === state && (
                            <input
                              name="profileImage"
                              className="file-upload"
                              type="file"
                              accept="image/*"
                              ref={fileInputRef}
                              onChange={handleImageChange}
                            />
                          )}
                          <div
                            className="rounded-circle"
                            onClick={handleImageClick}
                          >
                            {displayImage ? (
                              <img
                                loading="lazy"
                                src={displayImage}
                                alt="profile-img"
                                className="rounded-circle avatar-130"
                              />
                            ) : (
                              <img
                                loading="lazy"
                                src={user1} // Make sure to import user1 image or provide the correct source
                                alt="profile-img"
                                className="rounded-circle avatar-130"
                              />
                            )}
                          </div>
                          <div className="profile-detail">
                            <h3>
                              {memberData.first_name} {memberData.last_name}
                            </h3>
                          </div>
                        </div>

                        <div className="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
                          <div className="social-links">
                            <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                              <li className="text-center pe-2">
                                <button
                                  className="btn btn-primary p-2"
                                  onClick={handleReferButton}
                                  style={{ fontSize: "15px" }}
                                >
                                  <FaHandshake /> Send Referral
                                </button>
                              </li>
                              <li className="text-center pe-2">
                                <button
                                  className="btn btn-primary p-2"
                                  onClick={handleMeetingButton}
                                  style={{ fontSize: "15px" }}
                                >
                                  <BsCalendar /> Schedule Meeting
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                {selectedComponent === "refer" && (
                  <CreateReferral
                    onClose={handleCloseButton}
                    memberId={memberData.member_id}
                    mName={memberData.first_name}
                    state={state}
                  />
                )}

                {selectedComponent === "meeting" && (
                  <MemberMeeting
                    onClose={handleCloseButton}
                    memberId={memberData.member_id}
                    mName={memberData.first_name}
                    mNameL={memberData.last_name}
                    state={state}
                  />
                )}

                <Tab.Container defaultActiveKey="f1">
                  <nav className="tab-bottom-bordered">
                    <Nav variant="tabs" className="mb-0 rounded-top border-0">
                      <Nav.Item className="flex-grow-1 text-center">
                        <Nav.Link
                          eventKey="f1"
                          href="#"
                          style={{ fontSize: "20px" }}
                        >
                          Profile
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="flex-grow-1 text-center">
                        <Nav.Link
                          eventKey="f2"
                          href="#"
                          style={{ fontSize: "20px" }}
                        >
                          Referrals
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="flex-grow-1 text-center">
                        <Nav.Link
                          eventKey="f3"
                          href="#"
                          style={{ fontSize: "20px" }}
                        >
                          Meetings
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="card-body">
                      <Tab.Pane eventKey="f1">
                        <Row className="mt-3">
                          <Col lg={4}>
                            <Card>
                              <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                  <h4 className="card-title">About</h4>
                                </div>
                              </div>
                              <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                  <li className="mb-2 d-flex align-items-center">
                                    <span className="material-symbols-outlined md-18">
                                      email
                                    </span>
                                    <p className="mb-0 ms-2">
                                      {memberData.email_id}
                                    </p>
                                  </li>
                                  <li className="mb-2 d-flex align-items-center">
                                    <span className="material-symbols-outlined md-18">
                                      business
                                    </span>
                                    <p className="mb-0 ms-2">
                                      {memberData.member_category}
                                    </p>
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <span className="material-symbols-outlined md-18">
                                      work
                                    </span>
                                    <p className="mb-0 ms-2">
                                      {memberData.company_name}
                                    </p>
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <span className="material-symbols-outlined md-18">
                                      group
                                    </span>
                                    <p className="mb-0 ms-2">
                                      {memberData.chapter_name}
                                    </p>
                                  </li>
                                  <li className="mb-2 d-flex align-items-center">
                                    <span className="material-symbols-outlined md-18">
                                      place
                                    </span>
                                    <p className="mb-0 ms-2">
                                      {memberData.city}
                                    </p>
                                  </li>
                                </ul>
                              </Card.Body>
                            </Card>
                            <ProfilePhotos
                              mId={memberData.member_id}
                              state={state}
                            />
                          </Col>
                          <Col lg={8}>
                            {memberID === state && (
                              <Card id="post-modal-data">
                                <div className="card-header d-flex justify-content-between">
                                  <div className="header-title">
                                    <h4 className="card-title">Create Posts</h4>
                                  </div>
                                </div>
                                <Card.Body
                                  data-toggle="modal"
                                  data-target="#post-modal"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="user-img">
                                      {displayImage ? (
                                        <img
                                          loading="lazy"
                                          src={displayImage}
                                          alt="userimg"
                                          className="avatar-60 rounded-circle img-fluid"
                                        />
                                      ) : (
                                        <img
                                          loading="lazy"
                                          src={user1}
                                          alt="userimg"
                                          className="avatar-60 rounded-circle img-fluid"
                                        />
                                      )}
                                    </div>
                                    <form className="post-text ms-3 w-100">
                                      <input
                                        type="text"
                                        className="form-control rounded"
                                        placeholder="Write something here..."
                                        style={{ border: "none" }}
                                        onClick={handleShow}
                                      />
                                    </form>
                                  </div>
                                  <hr />
                                </Card.Body>
                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  size="lg"
                                  style={{ marginTop: "100px" }}
                                >
                                  <Modal.Header className="d-flex justify-content-between">
                                    <h5
                                      className="modal-title"
                                      id="post-modalLabel"
                                    >
                                      Create Post
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn btn-secondary lh-1"
                                      onClick={handleClose}
                                    >
                                      <span className="material-symbols-outlined">
                                        close
                                      </span>
                                    </button>
                                  </Modal.Header>
                                  <Modal.Body
                                    style={{
                                      maxHeight: "60vh",
                                      overflowX: "scroll",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <form
                                        className="post-text ms-3 w-100"
                                        action=""
                                      >
                                        <textarea
                                          className="form-control rounded"
                                          placeholder="Write something about post.."
                                          style={{ border: "none" }}
                                          value={inputValue}
                                          onChange={handleChange}
                                          onClick={handleShow}
                                        />
                                      </form>
                                    </div>
                                    <hr />
                                    <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                                      <li
                                        className="w-100"
                                        onClick={handleAddPohoto}
                                      >
                                        <div className="bg-soft-primary rounded p-2 pointer me-3">
                                          <Link to="#"></Link>
                                          <img
                                            loading="lazy"
                                            src={small1}
                                            alt="icon"
                                            className="img-fluid"
                                          />{" "}
                                          Photo/Video
                                        </div>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          ref={fileInputRef}
                                          style={{ display: "none" }}
                                          onChange={handleFileChange2}
                                        />

                                        <li className="col-md-12 mb-2 mt-2">
                                          {selectedImagee && (
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "12rem",
                                                objectFit: "contain",
                                              }}
                                            >
                                              <img
                                                src={URL.createObjectURL(
                                                  selectedImagee
                                                )}
                                                alt="Selected"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "contain",
                                                }}
                                              />
                                            </div>
                                          )}
                                        </li>
                                      </li>
                                    </ul>
                                    <hr />
                                    <div className="other-option">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100 d-flex align-items-center">
                                          <textarea
                                            value={textValue}
                                            onChange={handleTextArea}
                                            placeholder="Write the details about your post..."
                                            style={{
                                              width: "100%",
                                              height: "4rem",
                                            }}
                                          />
                                        </div>
                                        <div className="card-post-toolbar">
                                          <Dropdown>
                                            <Dropdown.Menu clemassName="dropdown-menu m-0 p-0">
                                              <Dropdown.Item
                                                className="dropdown-item p-3"
                                                href="#"
                                              >
                                                <div className="d-flex align-items-top">
                                                  <i className="ri-save-line h4"></i>
                                                  <div className="data ms-2">
                                                    <h6>Public</h6>
                                                    <p className="mb-0">
                                                      Anyone on or off Facebook
                                                    </p>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                className="dropdown-item p-3"
                                                href="#"
                                              >
                                                <div className="d-flex align-items-top">
                                                  <i className="ri-close-circle-line h4"></i>
                                                  <div className="data ms-2">
                                                    <h6>Friends</h6>
                                                    <p className="mb-0">
                                                      Your Friend on facebook
                                                    </p>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                className="dropdown-item p-3"
                                                href="#"
                                              >
                                                <div className="d-flex align-items-top">
                                                  <i className="ri-user-unfollow-line h4"></i>
                                                  <div className="data ms-2">
                                                    <h6>Friends except</h6>
                                                    <p className="mb-0">
                                                      Don't show to some friends
                                                    </p>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                className="dropdown-item p-3"
                                                href="#"
                                              >
                                                <div className="d-flex align-items-top">
                                                  <i className="ri-notification-line h4"></i>
                                                  <div className="data ms-2">
                                                    <h6>Only Me</h6>
                                                    <p className="mb-0">
                                                      Only me
                                                    </p>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <Button
                                      variant="primary"
                                      className="d-block w-100 mt-3"
                                      onClick={handlePost}
                                      disabled={clicked}
                                    >
                                      Post
                                    </Button> */}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="primary"
                                      className=" w-100"
                                      onClick={handlePost}
                                      disabled={clicked}
                                    >
                                      Post
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Card>
                            )}
                            <Card>
                              <Card.Body>
                                <div className="post-item">
                                  <div className="user-post"></div>
                                </div>
                                {sortedDataa.map((item, index) => (
                                  <div className="post-item">
                                    <div className="user-post-data p-3">
                                      <div className="d-flex justify-content-between">
                                        <div className="me-3">
                                          <img
                                            loading="lazy"
                                            className="rounded-circle  avatar-60"
                                            src={displayImage}
                                            alt=""
                                          />
                                        </div>
                                        <div className="w-100">
                                          <div className="d-flex justify-content-between flex-wrap">
                                            <div>
                                              <h5 className="mb-0 d-inline-block">
                                                <Link to="#" key={index}>
                                                  {item.first_name}{" "}
                                                  {item.last_name}
                                                </Link>
                                              </h5>

                                              <p className="mb-0" key={index}>
                                                {item.post_title}
                                              </p>
                                            </div>
                                            <div className="card-post-toolbar">
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  as={CustomToggle}
                                                >
                                                  <i className="ri-more-fill"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" m-0 p-0">
                                                  <Dropdown.Item
                                                    className="p-3"
                                                    to="#"
                                                  >
                                                    <div className="d-flex align-items-top">
                                                      <i className="ri-save-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>Save Post</h6>
                                                        <p className="mb-0">
                                                          Add this to your saved
                                                          items
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    className=" p-3"
                                                    to="#"
                                                  >
                                                    <div className="d-flex align-items-top">
                                                      <i className="ri-pencil-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>Edit Post</h6>
                                                        <p className="mb-0">
                                                          Update your post and
                                                          saved items
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    className="p-3"
                                                    to="#"
                                                  >
                                                    <div className="d-flex align-items-top">
                                                      <i className="ri-close-circle-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>
                                                          Hide From Timeline
                                                        </h6>
                                                        <p className="mb-0">
                                                          See fewer posts like
                                                          this.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    className=" p-3"
                                                    to="#"
                                                  >
                                                    <div className="d-flex align-items-top">
                                                      <i className="ri-delete-bin-7-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>Delete</h6>
                                                        <p className="mb-0">
                                                          Remove thids Post on
                                                          Timeline
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    className=" p-3"
                                                    to="#"
                                                  >
                                                    <div className="d-flex align-items-top">
                                                      <i className="ri-notification-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>Notifications</h6>
                                                        <p className="mb-0">
                                                          Turn on notifications
                                                          for this post
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="user-post">
                                      <p>{item.post_content}</p>
                                      {item.presignedUrl && (
                                        <Link to="#">
                                          <img
                                            loading="lazy"
                                            src={item.presignedUrl}
                                            alt="post"
                                            className="img-fluid w-100"
                                          />
                                        </Link>
                                      )}
                                      {/* {item.post_photovideo_path} */}
                                    </div>
                                    <div className="comment-area mt-3">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="like-block position-relative d-flex align-items-center">
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                              <Dropdown key={item.post_id}>
                                                <Dropdown.Toggle
                                                  as={CustomToggle}
                                                  id={`like-toggle-${item.post_id}`}
                                                  onClick={() =>
                                                    postLikeFunction(
                                                      item.post_id
                                                    )
                                                  }
                                                  disabled={
                                                    likedPosts[item.post_id]
                                                  } // Disable if already liked
                                                  className="like-toggle-btn"
                                                >
                                                  <img
                                                    src={icon1}
                                                    className="img-fluid"
                                                    alt="Like"
                                                  />
                                                  <span className="ms-2">
                                                    {Math.max(
                                                      item.post_likes,
                                                      0
                                                    )}{" "}
                                                    Likes
                                                  </span>
                                                </Dropdown.Toggle>
                                              </Dropdown>
                                            </div>
                                            <div
                                              style={{ marginLeft: "10px" }}
                                              className="d-flex align-items-center"
                                            >
                                              <Dropdown key={item.post_id}>
                                                <Dropdown.Toggle
                                                  as={CustomToggle}
                                                  id={`dislike-toggle-${item.post_id}`}
                                                  onClick={() =>
                                                    postDisLikeFunction(
                                                      item.post_id
                                                    )
                                                  } // Directly call the function with post_id
                                                  disabled={postLiked} // Disable if post has already been liked
                                                >
                                                  <img
                                                    src={iconD}
                                                    className="img-fluid"
                                                    alt="Dislike"
                                                  />
                                                  <span className="ms-2">
                                                    {/* {dislikes[item.post_id] || 0} */}
                                                    Dislikes
                                                  </span>
                                                </Dropdown.Toggle>
                                              </Dropdown>
                                            </div>
                                          </div>
                                          <div
                                            style={{ marginLeft: "10px" }}
                                            className="total-comment-block"
                                          >
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                as={CustomToggle}
                                                id="post-option"
                                                onClick={handleComment}
                                              >
                                                Comment
                                              </Dropdown.Toggle>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <ShareOffcanvas />
                                      </div>
                                      <hr />
                                      {showComments && (
                                        <ul className="post-comments p-0 m-0">
                                          <li key={index} className="mb-2">
                                            {item.all_comments && (
                                              <div>
                                                {item.all_comments.map(
                                                  (comment, commentIndex) => (
                                                    <div
                                                      key={commentIndex}
                                                      className="d-flex flex-wrap"
                                                    >
                                                      <div className="user-img">
                                                        <img
                                                          src={
                                                            comment.profilePresignedUrl ||
                                                            user1
                                                          }
                                                          alt="user1"
                                                          className="avatar-35 rounded-circle img-fluid"
                                                        />
                                                      </div>
                                                      <div className="comment-data-block ms-3">
                                                        <h6>
                                                          {comment.username}
                                                        </h6>
                                                        <p className="mb-0">
                                                          {comment.comment}
                                                        </p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                          {/* <Link to="#">
                                                            like
                                                          </Link>
                                                          <Link to="#">
                                                            reply
                                                          </Link> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </li>
                                        </ul>
                                      )}
                                      {showComments && (
                                        <form className="comment-text d-flex align-items-center mt-3">
                                          <input
                                            value={postComment}
                                            onChange={handlePostComment}
                                            type="text"
                                            className="form-control rounded"
                                            placeholder="Enter Your Comment"
                                          />
                                          <button
                                            style={{ marginLeft: "10px" }}
                                            type="button"
                                            onClick={() => {
                                              postCommentFunction(item.post_id);
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </form>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="f2">
                        <Referrals mId={memberData.member_id} state={state} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="f3" className="fade show">
                        <ProfileMeetings mId={mmmid} state={state} />
                      </Tab.Pane>
                    </Tab.Content>
                  </nav>
                </Tab.Container>
              </Row>
            </Container>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default FriendProfile;
