import React, { createContext, useContext, useState, useEffect } from 'react';
import useOrgNaming from './getNamingHook';

const OrgNamingContext = createContext();

export const useOrgNamingContext = () => useContext(OrgNamingContext);

export const OrgNamingProvider = ({ children, org_id, token }) => {
    const { getOrgNames, loading, success, error, refreshOrgNames } = useOrgNaming(org_id, token);

    return (
        <OrgNamingContext.Provider value={{ getOrgNames, loading, success, error, refreshOrgNames }}>
            {children}
        </OrgNamingContext.Provider>
    );
};
