import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "axios";

const AdminPostRegistration = ({
  onClose,
  trainingId,
  training_payment_link,
  memberId,
  createdBy,
}) => {
  // State to manage the alert type ('green' for success, 'red' for error)
  const [alertType, setAlertType] = useState(null);
  // State to manage the alert message
  const [alertMessage, setAlertMessage] = useState("");
  // State to check if the user is already registered
  const [isRegistered, setRegistered] = useState(false);
  // State to manage the button's disabled status during the registration process
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  // State to control the display of the payment link
  const [showPaymentLink, setShowPaymentLink] = useState(false);
  const [isPaymentChecked, setPaymentChecked] = useState(false);  // useEffect hook to fetch data and check if the user is already registered

   // useEffect hook to fetch data and check if the user is already registered
   useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        // Get the token from session storage
        const token = sessionStorage.getItem("token");
        // Make a GET request to fetch the registration status
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/training/registered/members/${trainingId}`,
      
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // If the response is successful
        if (response.status === 200) {
          const data = response.data;
          // Check if the member is already registered
          if (data.results.some((member) => member.member_id == memberId)) {
            setRegistered(true);
          }
        } else {
          console.log("Error fetching tranning");
        }
      } catch (error) {
        console.log("Error fetching tranning. Please try again later.");
      }
    };

    fetchDataFromApi();
  }, [trainingId, memberId]);

  // Function to handle the registration process
  const handleRegistration = async () => {
    try {
      // Get the token from session storage
      const token = sessionStorage.getItem("token");
      // Disable the button during the registration process
      setButtonDisabled(true);
      // Make a POST request to register the user for the training
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/training/trainigregister/newuser`,
        {
          training_id: trainingId,
          member_id: memberId,
          created_by: createdBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response based on status code
      // if (response.status === 200) {
        // Registration successful
        setRegistered(true);
        setAlertType("green");
        setAlertMessage(
          "You have been successfully registered for this training!"
        );
    } catch (error) {
      // Set the error alert
      setAlertType("red");
      // setRegistered(true);
      setAlertMessage("You are already registered for this Training!");
    } finally {
      // Re-enable the button after the registration process
      setButtonDisabled(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {alertType && (
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ color: alertType }} role="alert">
                    <b>{alertMessage}</b>
                  </div>
                  <Button variant="primary" onClick={onClose}>
                    OK
                  </Button>
                </div>
              )}
              {!alertType && (
                <>
                  {isRegistered ? (
                    <>
                      {/* Message for already registered user */}
                      <p style={{ color: "red" }}>
                        <b>You are already registered for this Training!</b>
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button variant="primary" onClick={onClose}>
                          OK
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {!showPaymentLink ? (
                        <>
                          {/* Initial registration prompt */}
                          <p>
                            <b>Do you want to register for this Training?</b>
                          </p>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="danger"
                              onClick={onClose}
                              className="me-2"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => setShowPaymentLink(true)}
                              disabled={isButtonDisabled}
                            >
                              Yes
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Payment link and Done button after confirmation */}
                          <p>
                            <b>Training Payment Link: <a href={training_payment_link} target="_blank" rel="noopener noreferrer">Click to pay</a></b>
                          </p>
                          <b>
                          <Form.Check
                            type="checkbox"
                            label="I have completed the payment"
                            onChange={(e) => setPaymentChecked(e.target.checked)}
                          />
                          </b>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="danger"
                              onClick={onClose}
                              className="me-2"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleRegistration}
                              disabled={!isPaymentChecked || isButtonDisabled} // Button is disabled unless checkbox is checked
                            >
                              {isButtonDisabled ? "Registering..." : "Done"}
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminPostRegistration;

