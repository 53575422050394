import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import MyReferralComment from "./myReferralComment";
import EditReferral from "./editReferral";
import DeleteReferral from "./deleteReferral";

const Referrals = ({ uId, mId, state }) => {
  const token = sessionStorage.getItem("token");
  const memberId = sessionStorage.getItem("member_id");
  const [myReferrals, setMyReferrals] = useState([]);
  const [sentReferrals, setSentReferrals] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [successMessages, setSuccessMessages] = useState([]); // Stores success messages
  const [tempAmounts, setTempAmounts] = useState({}); // Temporarily stores amounts for editing

  // Handle amount submission for a referral
  const handleAmountSubmit = async (referralId) => {
  try {
    const updatedReferrals = [...myReferrals];
    const referralIndex = updatedReferrals.findIndex((referral) => referral.referral_id === referralId);

    if (referralIndex === -1) {
      throw new Error("Referral not found in state");
    }

    const newAmount = tempAmounts[referralId];
    updatedReferrals[referralIndex].ref_amount = newAmount;
    updatedReferrals[referralIndex].submitted = true;

    setMyReferrals(updatedReferrals); // Update referrals state

    // Call function to update referral status and amount
    await updateReferralBDA(referralId, updatedReferrals[referralIndex].ref_status, newAmount, memberId);
  } catch (error) {
    console.error("Error handling amount submit:", error);
  }
};

  // const handleAmountSubmit = async (index) => {
  //   try {
  //     const updatedReferrals = [...myReferrals];
  //     const newAmount = tempAmounts[index];
  //     updatedReferrals[index].ref_amount = newAmount;
  //     updatedReferrals[index].submitted = true;
  //     setMyReferrals(updatedReferrals); // Update referrals state

  //     // Call function to update referral status
  //     await updateReferralBDA(
  //       updatedReferrals[index].referral_id,
  //       updatedReferrals[index].ref_status,
  //       updatedReferrals[index].ref_amount,
  //       memberId
  //     );
  //   } catch (error) {
  //     console.error("Error handling amount submit:", error); // Log error
  //   }
  // };
  // Handle amount change in the form
  const handleAmountChange = (e, referral_id) => {
    try {
      const { value } = e.target;
      setTempAmounts((prev) => ({
        ...prev,
        [referral_id]: value,
      }));
    } catch (error) {
      console.error("Error handling amount change:", error); // Log error
    }
  };

  // Function to update referral status and amount
  const updateReferralBDA = async (
    referralId,
    newStatus,
    refAmount,
    memberId
  ) => {
    try {
      const data = {
        ref_status: newStatus,
        ref_amount: refAmount,
        created_by: memberId,
      };

      // Make API request to update the referral status
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/update-referral/status/${referralId}`,
        {
          method: "PUT", // Use PUT method to update
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      handleSuccess("Status successfully changed"); // Handle success message
    } catch (error) {
      setError("Failed to update referral status"); // Handle error state
      console.error("Error updating referral status:", error); // Log error
    }
  };

  // Another function to update referral status (duplicated for possible different handling)
  const updateReferralStatus = async (referralId, newStatus, memberId) => {
    try {
      const data = {
        ref_status: newStatus,
        created_by: memberId,
      };

      // Make API request to update the referral status
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/update-referral/onlystatus/${referralId}`,
        {
          method: "PUT", // Use PUT method to update
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      fetchMyReferrals();
      // Commented out handleSuccess call to avoid duplicate messages
      // handleSuccess("Status successfully changed");
    } catch (error) {
      setError("Failed to update referral status"); // Handle error state
      console.error("Error updating referral status:", error); // Log error
    }
  };

  // Handle status change for a referral
  // const handleStatusChange = async (e, index) => {
  //   try {
  //     const newStatus = e.target.value; // Get new status from event
  //     console.log(
  //       `Changing status to: ${newStatus} for referral at index: ${index}`
  //     );

  //     const updatedReferrals = [...myReferrals];
  //     updatedReferrals[index].ref_status = newStatus;

  //     console.log("Updated referrals before setting state:", updatedReferrals);

  //     setMyReferrals(updatedReferrals); // Update referrals state immediately
  //     console.log("Updated state with new status.");

  //     // Ensure referral_id is not undefined
  //     const referralId = updatedReferrals[index].referral_id;
  //     if (!referralId) {
  //       throw new Error("Referral ID is undefined");
  //     }

  //     console.log(`Updating status for referral ID: ${referralId}`);

  //     // Call function to update referral status in the backend
  //     await updateReferralStatus(referralId, newStatus, memberId);

  //     // Refetch updated referrals from the backend after status update
  //     await fetchMyReferrals();
  //     console.log("Referrals re-fetched after status change.");
  //   } catch (error) {
  //     console.error("Error handling status change:", error); // Log error
  //   }
  // };

  const handleStatusChange = async (e, referralID) => {
    try {
      const newStatus = e.target.value; // Get new status from event
     
      // Find the referral by its ID instead of using it as an index
      const updatedReferrals = [...myReferrals];
      const referralIndex = updatedReferrals.findIndex(
        (referral) => referral.referral_id === referralID
      );

      if (referralIndex === -1) {
        throw new Error("Referral not found in state");
      }

      updatedReferrals[referralIndex].ref_status = newStatus;


      setMyReferrals(updatedReferrals); // Update referrals state immediately

      // Update referral status in the backend
      await updateReferralStatus(referralID, newStatus, memberId);

      // Refetch updated referrals from the backend after status update
      await fetchMyReferrals();
    } catch (error) {
      console.error("Error handling status change:", error); // Log error
    }
  };

  // Show success message
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  // Clear error message
  const clearError = () => {
    setError(null);
  };
  const fetchMyReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/get-referralForMe/${mId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }

      if (!response.ok) {
        throw Error("Failed to fetch my referrals");
      }

      const data = await response.json();

      if (data && data.results) {
        // Initialize the `submitted` state for each referral
        const initializedReferrals = data.results.map((referral) => ({
          ...referral,
          submitted:
            referral.ref_status == "Business Done" &&
            referral.business_done_amount !== null,
        }));

        setMyReferrals(initializedReferrals);
      }
    } catch (err) {
      console.error("Error fetching my referrals:", err);
      setError(
        "An error occurred while fetching referrals. Please try again later."
      );
    }
  };

  const fetchSentReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/get-referralByMe/${mId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }

      if (!response.ok) {
        throw Error("Failed to fetch sent referrals");
      }

      const data = await response.json();

      if (data && data.results) {
        // Initialize the `submitted` state for each referral
        const initializedReferrals = data.results.map((referral) => ({
          ...referral,
          submitted:
            referral.ref_status == "Business Done" &&
            referral.business_done_amount !== null,
        }));

        setSentReferrals(initializedReferrals);
      }
    } catch (err) {
      console.error("Error fetching sent referrals:", err);
      // setError(
      //   "An error occurred while fetching referrals. Please try again later."
      // );
    }
  };
  const fetchReferralsData = async () => {
    await fetchMyReferrals();
    await fetchSentReferrals();
  };
  useEffect(() => {
    // Fetch initial data
    fetchReferralsData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchReferralsData();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mId, refreshNeeded, token]);

  const combinedReferrals = [...myReferrals, ...sentReferrals];

  const filteredReferrals = combinedReferrals.filter((referral) => {
    const condition1 =
      referral.referred_by === parseInt(mId, 10) &&
      referral.referred_to === parseInt(memberId, 10);
    const condition2 =
      referral.referred_by === parseInt(memberId, 10) &&
      referral.referred_to === parseInt(mId, 10);

    return condition1 || condition2;
  });

  const displayReferrals =
    memberId === state ? combinedReferrals : filteredReferrals;

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <div>
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Referred By</b>
                          </th>
                          <th>
                            <b>Referred To</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {displayReferrals.length > 0 ? (
                        <tbody>
                          {displayReferrals
                            .slice()
                            .reverse()
                            .map((referral, index) => (
                              <React.Fragment key={referral.referral_id}>
                                <tr>
                                  <td>
                                    <b>{referral.referred_by_first_name}</b>
                                  </td>
                                  <td>
                                    <b>{referral.referred_to_first_name}</b>
                                  </td>
                                  <td>
                                    <b>{referral.ref_status}</b>
                                  </td>
                                  <td>
                                    <div className="d-lg-none">
                                      <Dropdown
                                        show={showActionsDropdown === index}
                                        onSelect={() =>
                                          setShowActionsDropdown(null)
                                        }
                                      >
                                        <Dropdown.Toggle
                                          variant="link"
                                          id={`dropdown-basic-${index}`}
                                          style={{
                                            fontSize: "1.5rem",
                                            color: "skyblue",
                                          }}
                                        >
                                          <FaEllipsisV
                                            className="ellipsis-icon"
                                            onClick={() =>
                                              toggleActionsDropdown(index)
                                            }
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleEyeDetails(index)
                                            }
                                          >
                                            <FaEye className="viewicon" /> View
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleCommentSection(index)
                                            }
                                          >
                                            <FaComment className="commenticon" />{" "}
                                            Comment
                                          </Dropdown.Item>
                                          {memberId == referral.referred_by &&
                                            memberId !=
                                              referral.referred_to && (
                                              <>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    toggleEditSection(index)
                                                  }
                                                >
                                                  <FaEdit className="editicon" />{" "}
                                                  Edit
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item
                                                  onClick={() =>
                                                    toggleDeleteSection(index)
                                                  }
                                                >
                                                  <FaTrash className="deleteicon" />{" "}
                                                  Delete
                                                </Dropdown.Item> */}
                                              </>
                                            )}

                                          {/* {memberId == referral.referred_to && (
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleDeleteSection(index)
                                              }
                                            >
                                              <FaTrash className="deleteicon" />{" "}
                                              Delete
                                            </Dropdown.Item>
                                          )} */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div className="d-none d-lg-block">
                                      <FaEye
                                        className="viewicon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() => toggleEyeDetails(index)}
                                      />
                                      &nbsp;&nbsp;
                                      <FaComment
                                        className="commenticon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() =>
                                          toggleCommentSection(index)
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      {memberId == referral.referred_by &&
                                        memberId != referral.referred_to && (
                                          <>
                                            <FaEdit
                                              className="editicon"
                                              size={15}
                                              color="#007BFF"
                                              onClick={() =>
                                                toggleEditSection(index)
                                              }
                                            />
                                            &nbsp;&nbsp;
                                            {/* <FaTrash
                                              className="deleteicon"
                                              size={15}
                                              color="red"
                                              onClick={() =>
                                                toggleDeleteSection(index)
                                              }
                                            /> */}
                                          </>
                                        )}
                                      &nbsp;&nbsp;
                                      {/* {memberId == referral.referred_to && (
                                        <FaTrash
                                          className="deleteicon"
                                          size={15}
                                          color="red"
                                          onClick={() =>
                                            toggleDeleteSection(index)
                                          }
                                        />
                                      )} */}
                                    </div>
                                  </td>
                                </tr>
                                {eyeClickedRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() => setEyeClickedRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <p>
                                            <b>
                                              Referral Name:{" "}
                                              {referral.referral_name}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              Description:{" "}
                                              {referral.referral_desc}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              Company Name:{" "}
                                              {referral.company_name}
                                            </b>
                                          </p>
                                          {!referral.business_done_amount ? (
                                            referral.ref_status ===
                                              "Business Done" && (
                                              <p>
                                                <b>
                                                  Business Done Amount:
                                                  <input
                                                    type="number"
                                                    value={
                                                      referral.business_done_amount
                                                    }
                                                    onChange={(e) =>
                                                      handleAmountChange(
                                                        e,
                                                        referral.referral_id
                                                      )
                                                    }
                                                  />
                                                </b>
                                              </p>
                                            )
                                          ) : (
                                            <p>
                                              <b>
                                                Business Done Amount:
                                                {referral.business_done_amount}
                                              </b>
                                            </p>
                                          )}
                                          {referral.refSubmitted == 1 && (
                                            <p>
                                              <b>
                                                Business Done Amount:
                                                <input
                                                  type="number"
                                                  value={
                                                    referral.business_done_amount
                                                  }
                                                  disabled
                                                />
                                              </b>
                                            </p>
                                          )}
                                        </div>
                                        <div className="col-lg-6">
                                          <p>
                                            <b>E mail: {referral.email_id}</b>
                                          </p>
                                          <p>
                                            <b>
                                              Contact No: {referral.contact_no}
                                            </b>
                                          </p>
                                          {memberId != referral.referred_by &&
                                            memberId ==
                                              referral.referred_to && (
                                              <p>
                                                <b>
                                                  Referral Status:
                                                  <select
                                                    value={referral.ref_status}
                                                    onChange={(e) =>
                                                      handleStatusChange(
                                                        e,
                                                        referral.referral_id
                                                      )
                                                    }
                                                    disabled={
                                                      referral.submitted == 1
                                                    }
                                                  >
                                                    <option value="Open">
                                                      Open
                                                    </option>
                                                    <option value="Contacted">
                                                      Contacted
                                                    </option>
                                                    <option value="Business Done">
                                                      Business Done
                                                    </option>
                                                    <option value="Business Not Done">
                                                      Business Not Done
                                                    </option>
                                                  </select>
                                                </b>
                                                {!referral.business_done_amount
                                                  ? referral.ref_status ===
                                                      "Business Done" && (
                                                      <p>
                                                        <b>
                                                          <button
                                                            onClick={() =>
                                                              handleAmountSubmit(
                                                                referral.referral_id
                                                              )
                                                            }
                                                          >
                                                            Submit
                                                          </button>
                                                        </b>
                                                      </p>
                                                    )
                                                  : ""}
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {commentRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() => setCommentRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>
                                      <MyReferralComment
                                        referralId={referral.referral_id}
                                      />
                                    </td>
                                  </tr>
                                )}
                                {editRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      {/* Close Icon */}
                                      <div
                                        className="close-icon"
                                        onClick={() => setEditRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>

                                      {/* AddComment Component */}
                                      <EditReferral
                                        referralId={referral.referral_id}
                                        onClose={() => setEditRow(null)}
                                      />
                                    </td>
                                  </tr>
                                )}
                                {deleteRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() => setDeleteRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>
                                      <DeleteReferral
                                        referralId={referral.referral_id}
                                        onClose={() => setDeleteRow(null)}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                        </tbody>
                      ) : (
                        <p>No referrals available.</p>
                      )}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default Referrals;
