import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import Card from "../../components/Card";
import { FaTimes } from "react-icons/fa";
import AdminPostRegistration from "../adminPostRegistration";
import profil1 from "../../assets/images/user/1.png";
import { useLocation } from "react-router-dom";
const AdminPosts = () => {
  // State variables for managing training data and UI states
  const [adminPost, setAdminPost] = useState([]);
  const location = useLocation();
  let filteredState = (location.state ?? {}).filteredResult ?? [];
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [message1, setMessage1] = useState("");
  const org_id = sessionStorage.getItem("org_id");
  const role_id = sessionStorage.getItem("role_id");
  const memberID = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");
  const [showRegistrationDiv, setShowRegistrationDiv] = useState(false);
  const [selectedTrainingIndex, setSelectedTrainingIndex] = useState(null);

  function handleCancel() {
    setMessage1(false);
    setShowRegistrationDiv(false);
    setSelectedEventIndex(null);
  }

  // Function to fetch training data from the API

  const fetchDataFromApi = async () => {
    try {
      let response;
      if (userRole === "ORG_ADMIN") {
        // ORG_ADMIN: Fetch trainings and filter by org_id and cutoff registration date
        response = await fetch(
          process.env.REACT_APP_API_URL + `/training/gettrainings/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          // Filter trainings by org_id and include trainings where the cutoff date is today or later
          const filteredEvents = data.trainingwithUrl.filter((event) => {
            const eventDate = new Date(event.train_cutoff_reg_date);
            const today = new Date();

            // Normalize the dates to only compare year, month, and day (ignoring timezones)
            const normalizedEventDate = new Date(
              eventDate.getFullYear(),
              eventDate.getMonth(),
              eventDate.getDate()
            );
            const normalizedToday = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            // Compare only the year, month, and day
            return (
              event.org_id == org_id && normalizedEventDate >= normalizedToday
            );
          });


          // Sort trainings by start date
          const sortedEvents = filteredEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setAdminPost(sortedEvents);
        } else {
          const errorData = await response.json();
          console.error("Error fetching trainings:", errorData);
        }
      } else {
        // CHAPTER_ADMIN: Same logic applies for filtering trainings by chapterId and cutoff registration date
        response = await fetch(
          process.env.REACT_APP_API_URL +
            `/training/gettrainings/org/${chapterId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          // Filter trainings where the cutoff registration date is today or later
          const filteredEvents = data.trainingwithUrl.filter((event) => {
            const eventDate = new Date(event.train_cutoff_reg_date);
            const today = new Date();

            // Normalize the dates to only compare year, month, and day (ignoring timezones)
            const normalizedEventDate = new Date(
              eventDate.getFullYear(),
              eventDate.getMonth(),
              eventDate.getDate()
            );
            const normalizedToday = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            return normalizedEventDate >= normalizedToday;
          });


          // Sort trainings by start date
          const sortedEvents = filteredEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setAdminPost(sortedEvents);
        } else {
          const errorData = await response.json();
          console.error("Error fetching trainings:", errorData);
        }
      }
    } catch (error) {
      console.error("Unexpected error fetching trainings:", error.message);
    }
  };

  // const fetchDataFromApi = async () => {
  //   try {
  //     let response;
  //     if (userRole == "ORG_ADMIN") {
  //       // If chapterId is null, call the /events API and filter by orgId
  //       response = await fetch(
  //         process.env.REACT_APP_API_URL + `/training/gettrainings/all`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       if (response.ok) {
  //         const data = await response.json();
  //         // setEvents(data.processedEvents);
  //         // Filter events by org_id and where cutoff registration date has not passed
  //         const filteredEvents = data.trainingwithUrl.filter((event) => {
  //           const eventDate = new Date(event.train_cutoff_reg_date);
  //           const today = new Date();

  //           // Compare only the year, month, and day for the cutoff date
  //           return (
  //             event.org_id == org_id &&
  //             (eventDate.getFullYear() > today.getFullYear() ||
  //               (eventDate.getFullYear() === today.getFullYear() &&
  //                 eventDate.getMonth() > today.getMonth()) ||
  //               (eventDate.getFullYear() === today.getFullYear() &&
  //                 eventDate.getMonth() === today.getMonth() &&
  //                 eventDate.getDate() >= today.getDate()))
  //           );
  //         });

  //         // Sort events by start date
  //         const sortedEvents = filteredEvents.sort(
  //           (a, b) =>
  //             new Date(a.train_start_date) - new Date(b.train_start_date)
  //         );

  //         setAdminPost(sortedEvents);
  //       } else {
  //         const errorData = await response.json();
  //         // setError(`Error fetching trannng: ${JSON.stringify(errorData)}`);
  //         console.log("error fetching training");
  //       }
  //     } else {
  //       // If chapterId is not null, call the existing API with chapterId
  //       response = await fetch(
  //         process.env.REACT_APP_API_URL +
  //           `/training/gettrainings/org/${chapterId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       if (response.ok) {
  //         const data = await response.json();

  //         // Filter events where cutoff registration date has not passed
  //         // const filteredEvents = data.trainingwithUrl.filter(
  //         //   (event) => new Date(event.train_cutoff_reg_date) > new Date()
  //         // );

  //         const filteredEvents = data.trainingwithUrl.filter((event) => {
  //           const eventDate = new Date(event.train_cutoff_reg_date);
  //           const today = new Date();

  //           // Compare only the year, month, and day
  //           return (
  //             eventDate.getFullYear() > today.getFullYear() ||
  //             (eventDate.getFullYear() === today.getFullYear() &&
  //               eventDate.getMonth() > today.getMonth()) ||
  //             (eventDate.getFullYear() === today.getFullYear() &&
  //               eventDate.getMonth() === today.getMonth() &&
  //               eventDate.getDate() >= today.getDate())
  //           );
  //         });

  //         // Sort events by start date
  //         const sortedEvents = filteredEvents.sort(
  //           (a, b) =>
  //             new Date(a.train_start_date) - new Date(b.train_start_date)
  //         );

  //         setAdminPost(sortedEvents);
  //       } else {
  //         const errorData = await response.json();
  //         // setError(`Error fetching tranning: ${JSON.stringify(errorData)}`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Unexpected error:", error.message);
  //     // setError("Error fetching tranning. Please try again later.");
  //     console.log("Error fetching tranning. Please try again later.");
  //   }
  // };
  useEffect(() => {
    // Fetch initial data
    fetchDataFromApi();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  // Handler to open registration modal for a selected training
  const handleRegister = (index) => {
    setShowRegistrationDiv(true);
    setSelectedTrainingIndex(index);
  };

  // Handler to close registration modal
  const handleRegistrationClose = () => {
    setShowRegistrationDiv(false);
    setSelectedTrainingIndex(null);
  };

  // Function to format the date as dd/mm/yyyy
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // to sort data based on posid
  const sortedDataa = useMemo(() => {
    if (Array.isArray(adminPost)) {
      return [...adminPost]
        .sort((a, b) => new Date(b.training_id) - new Date(a.training_id))
        .map((post) => ({
          ...post,
          startDate: formatDate(post.train_start_date),
          EndDate: formatDate(post.train_end_date),
          CutOffDate: formatDate(post.train_cutoff_reg_date),
        }));
    } else {
      console.error("adminPost is not an array");
      return [];
    }
  }, [adminPost]); // Ensure that postData is the only dependency, not sortedData

  // functions to display success or error message
  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  const clearError = () => {
    setError(null);
  };

  // Function to format time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour === 0) {
      formattedHour = 12; // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };


  return (
    <Container>
      {(filteredState && filteredState.length > 0
        ? filteredState
        : sortedDataa
      ).map((item, index) => (
        <Card key={index}>
          <Card.Header>
            <div className="user-post-data">
              <div className="d-flex justify-content-between">
                <div className="me-3">
                  <img
                    loading="lazy"
                    className="rounded-circle avatar-60"
                    src={item.profilePresignedUrl || profil1}
                    alt=""
                  />
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="mb-0 d-inline-block">
                        &nbsp;
                          {item.role_id == 4 && (
                          <>

                            {item.last_name}
                            <span className="badge rounded-pill text-bg-light mx-1">
                              ADMIN
                            </span>
                          </>
                        ) } 
                        {( item.role_id == 2 &&
                          <>
                            {`${item.first_name} ${item.last_name}`}
                            <span className="badge rounded-pill text-bg-light mx-1">
                              CHAPTER ADMIN
                            </span>
                          </>
                        )}
                        <p className="mt-1 mb-0 text-primary">
                          {item.training_topic}
                          <span
                            className="badge rounded-pill mx-1"
                            style={{ backgroundColor: "green", color: "white" }}
                          >
                            T
                          </span>
                        </p>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <div>
              {item.presignedUrl ? null : (
                <div className="mt-3">
                  <p>Trainer: {item?.trainer_name}</p>
                  <p>Cut_Off Reg Date: {item?.train_cutoff_reg_date}</p>
                  <p>Mode: {item?.training_mode}</p>
                  <p>Place: {item?.training_place}</p>
                  <p>Start Date: {item?.train_start_date}</p>
                  <p>End Date: {item?.train_end_date}</p>
                  <p>Start Time: {item?.train_start_time}</p>
                  <p>End Time: {item?.train_end_time}</p>
                  <p>Description: {item?.training_details}</p>
                </div>
              )}
              <div className="user-post">
                {item.presignedUrl ? (
                  <Container>
                    <Row>
                      <Col lg="12">
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col lg="6">
                                <p>
                                  <b>Trainer: {item?.trainer_name}</b>
                                </p>
                              </Col>
                              <Col lg="6">
                                <p>
                                  <b>Cut Off Reg Date: {item?.CutOffDate}</b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <p>
                                  <b>Mode: {item?.training_mode}</b>
                                </p>
                              </Col>
                              <Col lg="6">
                                <p>
                                  <b>
                                    {item?.training_mode === "virtual" ? (
                                      <>
                                        Training Link:
                                        <a
                                          href={item?.training_link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ marginLeft: "5px" }} // Optional: Adds space between the label and the link
                                        >
                                          Join Training
                                        </a>
                                      </>
                                    ) : (
                                      `Place: ${item?.training_place}`
                                    )}
                                  </b>
                                </p>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <p>
                                  <b>
                                    Date: {item?.startDate} to {item?.EndDate}
                                  </b>
                                </p>
                              </Col>
                              <Col lg="6">
                                <p>
                                  <b>
                                    Time: {formatTime(item?.train_start_time)} -{" "}
                                    {formatTime(item?.train_end_time)}
                                  </b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <p>
                                  <b>Description: {item?.training_details}</b>
                                </p>
                              </Col>
                              <Col lg="6">
                                <p>
                                  <b>Charges: {item?.training_charges}</b>
                                </p>
                              </Col>
                            </Row>
                            {item?.training_payment_link && (
                              <Row>
                                <p>
                                  <b>
                                    Training Payment link:{" "}
                                    <a
                                      href={item?.training_payment_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Click to pay
                                    </a>
                                  </b>
                                </p>
                              </Row>
                            )}

                            <div>
                              <img
                                loading="lazy"
                                src={item.presignedUrl}
                                alt={item.training_details}
                                className="img-fluid w-100"
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <Card className="text-center mt-3">
                    <p
                      className="mb-0"
                      style={{
                        maxHeight: "3em",
                        fontSize: "15px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      No Training available!
                    </p>
                  </Card>
                )}
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            {/* <div style={{ textAlign: "right", marginTop: "5px" }}> */}
            {!showRegistrationDiv && selectedTrainingIndex !== index && (
              <Row>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={() => handleRegister(index)}
                  >
                    <b>Register</b>
                  </Button>
                </div>
              </Row>
            )}
            {showRegistrationDiv && selectedTrainingIndex === index && (
              <Row>
                <Col lg="12">
                  <div className="registration-div">
                    <AdminPostRegistration
                      onClose={handleRegistrationClose}
                      trainingId={item.training_id}
                      training_payment_link={item.training_payment_link}
                      memberId={memberID}
                      createdBy={memberID}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {/* </div> */}
          </Card.Footer>
        </Card>
      ))}
    </Container>
  );
};

export default AdminPosts;
