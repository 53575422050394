import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MyReferralSearch = () => {
  const token = sessionStorage.getItem("token");
  const memberId = sessionStorage.getItem("member_id");

  const [filters, setFilters] = useState({
    search_text: "",
    company_name: "",
    member_name: "",
  });
  const navigate = useNavigate();
  const [referrls, setReferrals] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/get-referralByMe/${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data && data.results) {
        setReferrals(data.results.reverse());
      }
    } catch (err) {
      console.error("Error fetching referrals:", err);
      //   handleSearchError(
      //     "An error occurred while fetching referrals. Please try again later."
      //   );
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("/dashboards/app/by-me", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text, company_name, member_name } = filters;
    console.log("filter values: ", filters);

    const updatedResults = referrls.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.company_name &&
          result.company_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referral_desc &&
          result.referral_desc
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referral_name &&
          result.referral_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referred_by_first_name &&
          result.referred_by_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referred_to_first_name &&
          result.referred_to_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        // (result.email_id &&
        //   result.email_id.toLowerCase().includes(search_text.toLowerCase())) ||
        (result.ref_status &&
          result.ref_status.toLowerCase().includes(search_text.toLowerCase()));

      const isCompanyMatch =
        company_name === "" ||
        (result.company_name &&
          result.company_name
            .toLowerCase()
            .includes(company_name.toLowerCase()));

      const isMemberMatch =
        member_name === "" ||
        (result.referred_to_first_name &&
          result.referred_to_first_name
            .toLowerCase()
            .includes(member_name.toLowerCase()));

      return isSearchMatch && isCompanyMatch && isMemberMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();
    console.log("received referrals search results: ", filteredResults);

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboards/app/by-me", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboards/app/by-me", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
      company_name: "",
      member_name: "",
    });
    navigate("/dashboards/app/by-me", { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search here..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>
      {/* <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by company name"
          name="company_name"
          value={filters.company_name}
          onChange={handleInputChange}
        />
      </div> */}
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by referred to name"
          name="member_name"
          value={filters.member_name}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default MyReferralSearch;
