import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import MeetingList from "./meetingList";
import { format } from "date-fns";

const Meeting = () => {
  const [getOrgNames, setGetOrgNames] = useState([]);
  const memberId = sessionStorage.getItem("member_id");
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const chapterName = sessionStorage.getItem("chapter_name");
  const [members, setMembers] = useState([]);
  const location = useLocation();
  const { searchResults, alertMessage } = location.state || {};
  const [memberid, setMemberid] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  const [newMeeting, setNewMeeting] = useState({
    meeting_mode: "Virtual",
    meeting_date: "",
    meeting_start_time: "",
    meeting_end_time: "",
    meeting_link: "",
    meeting_subject: "",
    meeting_type: "One-on-One",
    member_id: "",
    chapter_id: "",
    meeting_place: "",
    meeting_desc: "",
    // meeting_status: "",
    organized_by: `${memberId}`,
    created_by: `${memberId}`,
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState(null);
  const [showMeetingForm, setShowMeetingForm] = useState(true);
  const [headerText, setHeaderText] = useState("One-on-One Meetings");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const org_id = sessionStorage.getItem("org_id");
  const resetForm = () => {
    setNewMeeting({
      meeting_mode: "Virtual",
      meeting_date: "",
      meeting_start_time: "",
      meeting_end_time: "",
      meeting_link: "",
      meeting_subject: "",
      meeting_type: "One-on-One",
      member_id: "",
      chapter_id: "",
      meeting_place: "",
      meeting_desc: "",
      organized_by: `${memberId}`,
      created_by: `${memberId}`,
    });
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/members/members/org/meeting/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw Error("Failed to fetch members");
      }
      const data = await response.json();

      // Filter out records where member_id equals mId
      const filteredMembers = data.imageUrls.filter(
        (member) => member.member_id != memberId
      );

      setMembers(filteredMembers);
    } catch (err) {
      console.error("Error fetching members:", err);
      // setError(
      //   "An error occurred while fetching members. Please try again later."
      // );
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchMembers();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchMembers();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setNewMeeting((prevMeeting) => ({
      ...prevMeeting,
      [name]: value,
    }));
  };

  const showSuccess = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const clearError = () => {
    setError(null);
  };

  const isFormValid = () => {
    return (
      newMeeting.meeting_mode !== "" &&
      newMeeting.meeting_date !== "" &&
      newMeeting.meeting_start_time !== "" &&
      newMeeting.meeting_end_time !== "" &&
      (newMeeting.meeting_mode === "Virtual"
        ? newMeeting.meeting_link !== ""
        : true) &&
      (newMeeting.meeting_mode === "In-Person"
        ? newMeeting.meeting_place !== ""
        : true) &&
      newMeeting.meeting_subject !== "" &&
      (newMeeting.meeting_type === "One-on-One"
        ? newMeeting.member_id !== ""
        : true) &&
      (newMeeting.meeting_type === "Chapter"
        ? newMeeting.chapter_id !== ""
        : true) &&
      newMeeting.organized_by !== "" &&
      newMeeting.meeting_desc !== "" &&
      newMeeting.created_by !== ""
    );
  };

  const submitMeeting = () => {
    // Check if the form is already being submitted
    if (isSubmitting) {
      return;
    }

    // Set the form as submitting
    setIsSubmitting(true);

    if (!isFormValid()) {
      setError("Please fill in all mandatory fields.");
      // Reset the submitting state
      setIsSubmitting(false);
      return;
    }

    const meetingSchedule = {
      ...newMeeting,
      organized_by: memberId,
      created_by: memberId,
    };

    const apiUrl =
      newMeeting.meeting_type === "One-on-One"
        ? `${process.env.REACT_APP_API_URL}/meetings/create-meeting`
        : `${process.env.REACT_APP_API_URL}/meetings/create-meeting-by`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(meetingSchedule),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            `Failed to submit meeting. Status: ${response.status}`
          );
        }
      })
      .then((data) => {
        // Handle the response data
        setNewMeeting({
          meeting_mode: "Virtual",
          meeting_date: "",
          meeting_start_time: "",
          meeting_end_time: "",
          meeting_link: "",
          meeting_subject: "",
          meeting_type: "One-on-One",
          member_id: "",
          chapter_id: "",
          meeting_place: "",
          meeting_desc: "",
          organized_by: `${memberId}`,
          created_by: `${memberId}`,
        });

        // Show success message
        setShowMeetingForm(true);
        showSuccess();
      })
      .catch((err) => {
        console.error("Error scheduling meeting:", err);
        setError(
          "An error occurred while scheduling the meeting. Please try again later."
        );
      })
      .finally(() => {
        // Reset the submitting state
        setIsSubmitting(false);
      });
  };

  const toggleMeetingForm = () => {
    setShowMeetingForm(!showMeetingForm);
    if (showMeetingForm) {
      setHeaderText("Schedule a Meeting");
    } else {
      setHeaderText("One-on-One Meetings");
    }
  };

  const currentDate = new Date();
  const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");

  useEffect(() => {
    fetchOrgNaming();
  }, []);
  //show the chapter list
  const fetchOrgNaming = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/org/getOrgNaming/${org_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();
      setGetOrgNames(data.result);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    <b> {headerText}</b>
                  </h4>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-end">
                  {showMeetingForm ? (
                    <Button
                      variant="primary"
                      onClick={toggleMeetingForm}
                      className="my-2 mx-lg-2"
                    >
                      Schedule {getOrgNames?.alt_meeting || "Meetings"}
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      onClick={toggleMeetingForm}
                      className="my-2 mx-lg-2"
                    >
                      Close
                    </Button>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                {showMeetingForm ? (
                  <MeetingList
                    searchResults={searchResults}
                    alertMessage={alertMessage}
                    getOrgNames={getOrgNames}
                  />
                ) : (
                  <Form className="d-flex flex-column px-2">
                    <div>
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} Mode
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="meeting_mode"
                            value={newMeeting.meeting_mode}
                            onChange={handleInputChange}
                          >
                            <option value="Virtual">Virtual</option>
                            <option value="In-Person">In-Person</option>
                          </Form.Control>
                        </Form.Group>
                        {newMeeting.meeting_mode === "Virtual" && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              {getOrgNames?.alt_meeting || "Meetings"} Link
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="url"
                              name="meeting_link"
                              value={newMeeting.meeting_link}
                              onChange={handleInputChange}
                              pattern="^(http|https)://\S+$"
                            />
                          </Form.Group>
                        )}
                        {newMeeting.meeting_mode === "In-Person" && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              {getOrgNames?.alt_meeting || "Meetings"} Place
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="meeting_place"
                              value={newMeeting.meeting_place}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      </div>
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} Date
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="meeting_date"
                            value={newMeeting.meeting_date}
                            onChange={handleInputChange}
                            min={formattedCurrentDate}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} Subject
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="meeting_subject"
                            value={newMeeting.meeting_subject}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} Start Time
                            <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            type="time"
                            name="meeting_start_time"
                            value={newMeeting.meeting_start_time}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} End Time
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="meeting_end_time"
                            value={newMeeting.meeting_end_time}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        {newMeeting.meeting_type === "One-on-One" && (
                          <Form.Group className="col-lg-12">
                            <Form.Label>
                              Member<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="member_id"
                              value={newMeeting.member_id}
                              onChange={handleInputChange}
                            >
                              <option value="">Select a Member</option>
                              {members.map((member) => (
                                <option
                                  key={member.member_id}
                                  value={member.member_id}
                                >
                                  {member.first_name} {member.last_name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        )}
                        {/* <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meetings"} Payment
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="meeting_charges"
                            value={newMeeting.meeting_charges}
                            onChange={handleInputChange}
                          />
                        </Form.Group> */}
                      </div>

                      <Form.Group>
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meetings"} Description
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="meeting_desc"
                          value={newMeeting.meeting_desc}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meetings"} Payment Link
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="meeting_payment_link"
                          value={newMeeting.meeting_payment_link}
                          onChange={handleInputChange}
                        />
                      </Form.Group> */}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="danger"
                        onClick={resetForm}
                        className="my-2 me-2"
                      >
                        <b>Reset</b>
                      </Button>
                      <Button
                        variant="primary"
                        onClick={submitMeeting}
                        className="my-2"
                        disabled={isSubmitting}
                      >
                        <b>Schedule</b>
                      </Button>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Alert
        show={showSuccessModal}
        variant="success"
        onClose={closeSuccessModal}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
            onClick={closeSuccessModal}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        <p>Your meeting has been scheduled successfully.</p>
      </Alert>

      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};

export default Meeting;
