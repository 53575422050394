import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const MyReferralComment = ({ referralId }) => {
  const createdBy = sessionStorage.getItem("member_id");
  const user = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchCommentsFromBackend = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referralComment/get-referralComments/${referralId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch comments (status: ${response.status})`
        );
      }

      const data = await response.json();

      if (data.results && data.results.length) {
        console.log("Fetched referral comments:", data.results);
        setComments(data.results);
      } else {
        console.log("No comments found");
      }
    } catch (error) {
      console.error("Error fetching comments:", error.message);
    }
  };

  useEffect(() => {
    fetchCommentsFromBackend();

    const intervalId = setInterval(() => {
      fetchCommentsFromBackend();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [referralId]);

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = async () => {
    if (newComment.trim() !== "" && !loading) {
      try {
        setLoading(true); // Set loading state to true
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/referralComment/create-referralComment`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              referral_id: referralId,
              refer_comments: newComment,
              created_by: createdBy,
            }),
          }
        );

        if (!response.ok) {
          throw Error("Failed to add comment");
        }

        // Fetch updated comments after adding a new comment
        fetchCommentsFromBackend();
        setNewComment("");
      } catch (error) {
        console.error("Error adding comment:", error);
      } finally {
        setLoading(false); // Set loading state back to false
      }
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card className="whatsapp-chat-card mx-4">
            <Card.Header className="whatsapp-chat-header">Comments</Card.Header>

            <Card.Body
              className="whatsapp-chat-body"
              style={{ overflowY: "auto", maxHeight: "30vh" }}
            >
              {comments.map((comment, index) => (
                <div
                  key={comment.refer_comment_id}
                  className={`message d-flex ${
                    comment.user_name === user
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                  style={{ marginBottom: "8px", textAlign: "left" }}
                >
                  <div
                    className={`p-2 rounded ${
                      comment.user_name === user
                        ? "bg-primary text-white ml-auto"
                        : "bg-light text-dark"
                    }`}
                    style={{ maxWidth: "70%", wordWrap: "break-word" }}
                  >
                    <strong>{comment.user_name}:</strong>
                    <br /> {comment.refer_comments}
                    <br />
                    {formatDate(comment.created_on)}
                  </div>
                </div>
              ))}
            </Card.Body>

            <Card.Footer className="whatsapp-chat-footer">
              <Form>
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <Form.Group controlId="commentTextarea">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type a comment..."
                        value={newComment}
                        onChange={handleCommentChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-auto">
                    <Button
                      variant="primary"
                      onClick={handleAddComment}
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MyReferralComment;

