import React, { useState, useRef, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
  Card,
} from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
//import { Row, Col, Container, Form, Alert, Button, Image, Card } from 'react-bootstrap'

import { Link, useNavigate } from "react-router-dom";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import axios from "axios";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import 'swiper/components/navigation/navigation.scss';
//img
import logo from "../../../assets/images/logo-white.png";
import login1 from "../../../assets/images/login/1.png";
import login2 from "../../../assets/images/login/2.png";
import login3 from "../../../assets/images/login/3.png";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFormAlert, setShowFormAlert] = useState(false);
  const [org, setOrg] = useState(logo);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgGallery, setOrgGallery] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [secondHeading, setSecondHeading] = useState("Company Information");
  // const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null); // State to manage errors
  const [errorMessage, setErrorMessage] = useState("");
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState(null);


  useEffect(() => {
    const getQueryParam = (url, param) => {
      const queryParams = new URL(url).searchParams;
      return queryParams.get(param);
    };

    const token = getQueryParam(window.location.href, "token");
    if (!token) {
      navigate("/auth/sign-up");
      return;
    }

    if (token) {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + '/org/verify-token/', { token })
          .then((response) => {
            let ORGdata = response.data.result[0];
            setOrg(ORGdata);
          })
      }
      catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("No org found:", error);
        } else {
          console.error("Error fetching org:", error);
        }
      }
    }

    const decodedToken = jwtDecode(token);
    const verifiedOrgId = decodedToken.org_id;
    setOrgId(verifiedOrgId);

    fetchMembers(verifiedOrgId);
  }, [navigate]);

  const fetchMembers = async (org_id) => {


    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`
      );

      if (response.status === 200) {
        const allMembers = response.data.imageUrls || [];
        const deactivatedMembers = allMembers.filter(
          (member) => member.member_is_delete === 0
        );
        setMembers(deactivatedMembers);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("No Members found:", error);
      } else {
        console.error("Error fetching members:", error);
      }
    }

    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/org/getOrgImages/${org_id}`)
        .then((response) => {
          if (response.status === 200) {
            const { awsLogoUrls, galleryUrlsWithDesc } = response.data;
            const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
            const G1 = galleryUrlsWithDesc;

            setOrgLogo(L1);
            setOrgGallery(G1);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("No organization found:", error);
          } else {
            console.error("Error fetching organization images:", error);
          }
        });
    } catch (error) {
      console.error("Unexpected error occurred:", error);
    }
 };


  const showSuccess = () => {
    setShowSuccessAlert(false);
    setIsSubmitting(false); // Reset submitting state after alert is closed
    closeSuccess(); // This can be any additional logic you want to execute after closing the alert
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  const clearError = () => {
    setError(null);
  };

  function closeShowFormAlert() {
    setShowFormAlert(false);
  }
  //validation to signup form

  const validateName = (fieldName) => {
    const regex = /^[a-zA-Z\s]+$/;
    const input = document.getElementById(fieldName).value;
    return regex.test(input);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const input = document.getElementById(email).value;
    return regex.test(input);
  };

  const validateNumber = (ccno) => {
    const regex = /^[0-9]+$/;
    const input = document.getElementById(ccno).value;

    // Check if the input matches the regex and has exactly 10 digits
    if (regex.test(input) && input.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  const displayErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the form is already being submitted
    if (isSubmitting) {
      return;
    }

    // Set the form as submitting
    setIsSubmitting(true);

    const requiredFields = ["fname", "lname", "email", "ccno"];

    // Check if all required fields are present in the DOM
    const allFieldsPresent = requiredFields.every(
      (fieldName) => document.getElementById(fieldName)
    );

    if (!allFieldsPresent) {
      setError("Some required fields are missing.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    // Validate fields
    if (!validateName("fname")) {
      setError("Please enter a valid first name.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    if (!validateName("lname")) {
      setError("Please enter a valid last name.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    if (!validateEmail("email")) {
      setError("Please enter a valid email address.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    if (!validateNumber("ccno")) {
      setError("Please enter a valid contact number.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    // Check if any required field is empty
    const isAnyFieldEmpty = requiredFields.some(
      (fieldName) => !document.getElementById(fieldName)?.value.trim()
    );

    if (isAnyFieldEmpty) {
      setError("Please fill in all required fields.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }
    const ccno = document.getElementById("ccno")?.value.trim();
    const isCcnoUnique = await checkContactUniqueness(ccno);
    const email = document.getElementById("email")?.value.trim();
    const isEmailUnique = await checkEmailUniqueness(email);

    if (!isEmailUnique) {
      setError("Email already exists. Please use a different email.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }
    if (!isCcnoUnique) {
      setError("Contact number already exists. Please use a different Contact number.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }

    const formData = {
      first_name: document.getElementById("fname")?.value.trim(),
      last_name: document.getElementById("lname")?.value.trim(),
      email_id: email,
      contact_no: document.getElementById("ccno")?.value.trim(),
      no_of_years_in_business: document.getElementById("no_of_years_in_business")?.value.trim(),
      membership_status: "pending",
      org_id: orgId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests`,
        formData
      );

      if (response) {
        setTimeout(() => {
          // showSuccess();

          setShowSuccessAlert(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while signing up. Please try again later.");
    }
    // } finally {
    //   // Reset the submitting state
    //   setIsSubmitting(false);
    // }
  };


// Function to check email uniqueness
const checkContactUniqueness = async (ccno) => {
  try {
    // Make a GET request to your API endpoint to check email uniqueness
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
      `/membershipRequest/checkCcnoUniqueness?ccno=${ccno}`
    );

    // Return true if the email is unique, false otherwise
    return response.data.unique;
  } catch (error) {
    console.error("ccno uniqueness check error", error);
    return false; // Assume email is not unique in case of an error
  }
};

  // Function to check email uniqueness
  const checkEmailUniqueness = async (email) => {
    try {
      // Make a GET request to your API endpoint to check email uniqueness
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/membershipRequest/checkEmailUniqueness?email_id=${email}`
      );

      // Return true if the email is unique, false otherwise
      return response.data.unique;
    } catch (error) {
      console.error("Email uniqueness check error", error);
      return false; // Assume email is not unique in case of an error
    }
  };

  const second = () => {
    setSecondHeading(" Information");
  };

  function handleSuccess() {

    const getQueryParam = (url, param) => {
      const queryParams = new URL(url).searchParams;
      return queryParams.get(param);
    };
    let token;
    if (sessionStorage.getItem("login_token")) {
      token = sessionStorage.getItem("login_token");
    } else {
      token = getQueryParam(window.location.href, "token");
    }
    navigate(`/auth/sign-in?token=${token}`);
  }

  return (
    <>
      <div>
        <section className="sign-in-page">
          <div id="container-inside">
            <div id="circle-small"></div>
            <div id="circle-medium"></div>
            <div id="circle-large"></div>
            <div id="circle-xlarge"></div>
            <div id="circle-xxlarge"></div>
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              <Col md="6" className="text-center pt-5 ">
                <div className="sign-in-detail text-white">
                  <div
                    className="sign-in-logo mb-5 justify-content-center"
                    to="#"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div class="d-flex align-items-center">
                      {!orgLogo ?

                        <svg
                          width="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                            fill="white"
                          />
                        </svg>

                        :
                        <img
                          src={orgLogo}
                          className="img-fluid ml -3"
                          alt="logo"
                          style={{ marginRight: "5px" }}
                        />



                      }
                      {!org.organization_name ? <h1 className="text-white ml-3">
                        &nbsp;<b>CB</b>
                      </h1> : <h1 className="text-white ml-3">
                        <b>{org.organization_name}</b>
                      </h1>
                      }
                    </div>

                  </div>
                  <div className="sign-slider overflow-hidden">

                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      className="list-inline m-0 p-0"
                    >
                      {orgGallery.length > 0 ? (
                        orgGallery.map((image, index) => (
                          <SwiperSlide key={index}>
                            <Image
                              style={{ paddingLeft: "30px" }}
                              src={image.url}
                              className="img-fluid mb-4"
                              alt={`Slide ${index + 1}`}
                            />
                            {/* Optionally, display description if available */}
                            {image.desc && (
                              <div className="image-description">
                                <p>{image.desc}</p>
                              </div>
                            )}
                          </SwiperSlide>
                        ))
                      ) : (
                        <>
                          <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                              delay: 2000,
                              disableOnInteraction: false,
                            }}
                            className="list-inline m-0 p-0 "
                          >
                            <SwiperSlide>
                              <Image
                                src={login1}
                                className="img-fluid mb-4"
                                alt="logo"
                              />
                              <h4 className="mb-1 text-white">Find new friends</h4>
                              <p>
                                It is a long established fact that a reader will be
                                distracted by the readable content.
                              </p>
                            </SwiperSlide>
                            <SwiperSlide>
                              <Image
                                src={login2}
                                className="img-fluid mb-4"
                                alt="logo"
                              />
                              <h4 className="mb-1 text-white">
                                Connect with the world
                              </h4>
                              <p>
                                It is a long established fact that a reader will be
                                distracted by the readable content.
                              </p>
                            </SwiperSlide>
                            <SwiperSlide>
                              <Image
                                src={login3}
                                className="img-fluid mb-4"
                                alt="logo"
                              />
                              <h4 className="mb-1 text-white">Create new events</h4>
                              <p>
                                It is a long established fact that a reader will be
                                distracted by the readable content.
                              </p>
                            </SwiperSlide>
                          </Swiper>
                        </>
                      )}
                    </Swiper>

                  </div>
                </div>
              </Col>
              <Col
                md="6"
                style={{
                  maxHeight: "80vh",
                  marginTop: "10vh",
                  marginBottom: "20vh",
                }}
              >
                <Card>
                  <Card.Header
                    className="header-title"
                    style={{
                      maxHeight: "10vh",
                      paddingBottom: "3%",
                      paddingTop: "3%",
                    }}
                  >
                    <h3 className="card-title text-center">Join {org.organization_name ? org.organization_name : "CB"}</h3>
                  </Card.Header>

                  <Card.Body
                    style={{
                      maxHeight: "50vh",
                      overflowY: "auto",
                      paddingRight: "2%",
                    }}
                  >
                    <Row>
                      <Col>
                        <Form id="form-wizard3" className="text-start">
                          <div className="form-card text-left">
                            <Row>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    First Name{" "}
                                    <spna style={{ color: "red" }}>*</spna> :
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    placeholder="First Name"
                                    required="required"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    Last Name{" "}
                                    <spna style={{ color: "red" }}>*</spna> :
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    placeholder="Last Name"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    Email Id{" "}
                                    <spna style={{ color: "red" }}>*</spna> :
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email Id"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    Contact Number
                                    <spna style={{ color: "red" }}>*</spna> :
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="ccno"
                                    name="ccno"
                                    placeholder="Contact Number"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="6">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    No. Of Years In Business{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="no_of_years_in_business"
                                    id="no_of_years_in_business"
                                    name="no_of_years_in_business"
                                    placeholder="No. Of Years In Business"
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col md="6">
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    Refered By
                                    <spna style={{ color: "red" }}>*</spna> :
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    id="membership"
                                    value={selectedMember}
                                    onChange={(e) =>
                                      setSelectedMember(e.target.value)
                                    }
                                  >
                                    <option value="">Select a member</option>
                                    {members.map((member) => (
                                      <option
                                        key={member.member_id}
                                        value={member.member_id}
                                      >
                                        {member.first_name} {""}{member.last_name}
                                      </option>
                                    ))}
                                    <option value="Other">Other</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col> */}
                            </Row>

                            {/* <Row>
                              <Col md="12">
                                {selectedMember === "Other" && (
                                  <Form.Group>
                                    <Form.Label>Custom Member</Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={customMember}
                                      onChange={(e) =>
                                        setCustomMember(e.target.value)
                                      }
                                      placeholder="Enter custom member"
                                    />
                                  </Form.Group>
                                )}
                              </Col>
                            </Row> */}
                            <Col md="12"></Col>
                          </div>
                          <div
                            className="fixed-button-container"
                            style={{ textAlign: "right" }}
                          ></div>
                        </Form>
                      </Col>
                      {/* Add an element to display error messages */}
                      {error && <Alert variant="danger">{error}</Alert>}
                    </Row>
                  </Card.Body>

                  <Card.Footer
                    className="fixed-button-container"
                    style={{
                      maxHeight: "10vh",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingTop: "5%",
                    }}
                  >
                    <Button
                      variant="primary"
                      className="action-button me-2"
                      href="#"
                      onClick={handleSuccess}
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary"
                      className="action-button"
                      href="#"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Success Alert */}
        <Alert
          show={showSuccessAlert}
          variant="success"
          // onClose={()=>{closeSuccess()}}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "green",
            color: "white",
          }}
        >
          <Alert.Heading>Success!</Alert.Heading>
          <p> successfully Signed Up</p>
        </Alert>
      </div>

      {/* Success Alert */}

      <Alert
        show={showSuccessAlert}
        variant="success"
        onClose={showSuccess}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
          />
        </div>
        <Alert.Heading> Sign up successful!!</Alert.Heading>
        <p>
          {" "}
          Once admin accept your request , you will get login credentials on
          given email{" "}
        </p>

        <div style={{ textAlign: "right" }}>
          <button className="btn btn-primary" onClick={handleSuccess}>
            OK
          </button>
        </div>
      </Alert>

      {/* Error Alert */}
      {/* {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )} */}

      <Alert
        show={showFormAlert}
        variant="success"
        onClose={closeShowFormAlert}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid red",
          color: "black",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
            onClick={closeShowFormAlert}
          />
        </div>
        <Alert.Heading>Alert!</Alert.Heading>
        <p>Please Fill In all the fields</p>
      </Alert>
    </>
  );
};

export default SignUp;
