import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Tab,
  Nav,
  Dropdown,
  Modal,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox"; // Import from react-image-lightbox
import "react-image-lightbox/style.css"; // Import the styles
import video_image from "../../../assets/images/user/mp4-icon.png";
import pdf_image from "../../../assets/images/user/PDF_image.png"; // Import your close icon
import word_image from "../../../assets/images/user/word-icon.png"; // Import your close icon
import excel_image from "../../../assets/images/user/Excel-icon.png"; // Import your close icon
import defult_image from "../../../assets/images/user/defult_image.png"; // Import your close icon
import Card from "../../../components/Card";
import { useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FileViewer } from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { AiOutlineClose } from "react-icons/ai";

const ProfilePhotos = ({ mId }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 0, // react-image-lightbox uses 0-based indexing
  });
  const fileInputRef = useRef(null);
  const [fullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [imageShow, setImageShow] = useState(false);
  const handleImageClose = () => setImageShow(false);
  const handleImageShow = () => setImageShow(true);
  const [imageData, setImageData] = useState([]);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const location = useLocation();
  const { state } = location;
  const handleShow = () => setShow(true);
  const [fullscreenIndex, setFullscreenIndex] = useState(null);
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  // Determine the memberId based on the condition
  const memId = mId != memberId ? mId : memberId;

  const fetchImageData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/members/members/images/${memId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          "Cache-Control": "no-cache",
        }
      );

      const apiImageData = response.data.imageUrls;
      setImageData(apiImageData);
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    // Call the function to fetch image data
    fetchImageData();

    return () => {
      isMounted = false;
    };
  }, [memId, token]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    // Do something with the selected files, e.g., store in state
    setSelectedFiles(files);
    handleImageShow();
  };

  // Function to toggle full-screen mode
  const handleClick = (index) => {
    setFullscreenIndex(index);
    handleShow();
  };

  const renderImagePreviews = () => {
    return selectedFiles.map((file, index) => (
      // <Col lg="12" md="12" key={index}>
      <div className="user-images position-relative overflow-hidden mb-3">
        <Link to="#">
          {" "}
          <img
            alt="gallary"
            className="img-fluid"
            src={URL.createObjectURL(file)}
            style={{
              height: "12rem",
              width: "12rem",
              objectFit: "contain",
            }}
          />
        </Link>
      </div>
      // </Col>
    ));
  };

  const [formData, setFormData] = useState({
    img_title: "",
    img_tag: "",
    img_desc: "",
  });

  // Handle input changes
  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleAddPohoto = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
  };

  const updateMemberupload = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      // Create FormData object to handle file uploads
      const formDataForApi = new FormData();

      // Append each key-value pair from formData to FormData
      Object.keys(formData).forEach((key) => {
        formDataForApi.append(key, formData[key]);
      });

      // Append each selected file to FormData
      selectedFiles.forEach((file, index) => {
        formDataForApi.append(`imageFile${index + 1}`, file);
      });
      // Append memberId and member_id to FormData
      formDataForApi.append("userID", memberId);
      formDataForApi.append("member_id", memId);

      // Make the API request using axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/members/upload-products`,
        formDataForApi,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type to handle file uploads
          },
        }
      );
      fetchImageData();
      // Handle the successful response
      handleSuccess("product files uploaded successfully");
      handleImageClose();

    } catch (error) {
      // Handle errors
      console.error("Error:", error);

      // Show error message to the user
      setError("Failed to update product data");

      // ... rest of the code to handle errors
    }
  };

  function imageOnSlide(number) {
    setImageController({
      toggler: true,
      slide: number,
    });
    setLightboxIsOpen(true);
  }

  const handleCloseFullscreen = () => {
    setFullscreenIndex(null);
  };

  function renderThumbnail(image, index) {
    const type = image.file_type;

    if (type && type.startsWith("image/")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={image.imageUrl}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    } else if (type && type.startsWith("video/")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={video_image}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    } else if (type && type.startsWith("application/pdf")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={pdf_image}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    } else if (type && type.startsWith("application/msword")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={word_image}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    } else if (type && type.startsWith("application/vnd.ms-excel")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={excel_image}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    } else if (type && type.startsWith("application/vnd.openxmlformats")) {
      return (
        <img
          loading="lazy"
          onClick={() => imageOnSlide(index)}
          src={defult_image}
          alt={`gallery-${index + 1}`}
          className="img-fluid avatar-130"
        />
      );
    }
  }

  const renderFullscreenContent = () => {
    const image = imageData[fullscreenIndex];
    if (!image) return null; // Ensure image data exists for the selected index

    const docs = [
      { url: image.imageUrl }, // Local File
    ];
    const renderfiles = () => {
      const type = image.file_type;

      if (type && type.startsWith("image/")) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              loading="lazy"
              onClick={() => imageOnSlide(fullscreenIndex)}
              src={image.imageUrl}
              alt={`gallery-${fullscreenIndex + 1}`}
              style={{
                objectFit: "contain",
                maxHeight: "500px",
                width: "100%",
              }}
            />
          </div>
        );
      } else if (
        type &&
        type.startsWith(
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        )
      ) {
        return (
          <div>
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />;
          </div>
        );
      } else if (type && type.startsWith("application/pdf")) {
        return (
          <div>
            <iframe
              src={image.imageUrl}
              title="PDF Viewer"
              type="application/pdf"
              style={{ width: "100%", height: "600px" }}
            />
          </div>
        );
      } else if (type && type.startsWith("application/msword")) {
        return (
          <div>
            {/* <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />; */}
            <iframe
              src={image.imageUrl}
              title="Document Viewer"
              type="application/msword"
              style={{ width: "100%", height: "600px" }}
            />
          </div>
        );
      } else if (type && type.startsWith("video/mp4")) {
        return (
          <div>
            <iframe
              src={image.imageUrl}
              title="video Viewer"
              type="video/mp4"
              style={{ width: "100%", height: "500px" }}
            />
          </div>
        );
      }
      // Add handling for other file types if needed
    };

    return (
      <Modal
        // backdrop="static"
        show={show}
        fullscreen={fullscreen}
        onHide={handleClose}
        style={{
          position: "fixed",
          zIndex: 9999, // Set a high z-index to ensure it appears above other elements

          // width: "100%",
          // height: "100%",
          backgroundColor: "black",
        }}
      >
        {/* <Modal.Header closeButton>
         <Modal.Title>{image.img_title}</Modal.Title>
       </Modal.Header> */}
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "black" }}
        >
          <Modal.Title className="text-white">{image.img_title}</Modal.Title>
          <div className="d-flex flex-column flex-lg-row justify-content-end">
            <AiOutlineClose
              onClick={handleClose}
              style={{
                cursor: "pointer",
                fontSize: "1rem",
                color: "white",
                alignItems: "end",
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "black" }}>
          {renderfiles()}{" "}
          {/* This will render the result of renderfiles function */}
        </Modal.Body>
        <Modal.Footer
          className="justify-content-start"
          style={{ backgroundColor: "black" }}
        >
          <p className="text-start text-white">{image.img_desc}</p>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <div>
        <Card>
          <div className="card-header d-flex justify-content-between">
            <div className="header-timport FsLightbox from 'fslightbox-react';itle">
              <h4 className="card-title">Files</h4>
            </div>
            {memberId == state && (
              <div className="card-header-toolbar d-flex align-items-center">
                <input
                  type="file"
                  accept="image/*, video/*, application/pdf, .ppt, .pptx, .doc, .docx"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple // Allow multiple files
                />

                <p className="m-0">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleAddPohoto}
                  >
                    Add File
                  </button>{" "}
                </p>
              </div>
            )}
          </div>
          <Card.Body>
            <div>
              <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                {imageData.map((image, index) => (
                  <li key={index}>
                    <Link to="#" onClick={() => handleClick(index)}>
                      {renderThumbnail(image, index)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* Fullscreen modal */}
            {fullscreenIndex !== null && (
              <div className="fullscreen-modal">
                {/* Implement your fullscreen display here */}
                {/* For example, display the image or document in fullscreen */}
                <div className="fullscreen-content">
                  {/* <button onClick={handleCloseFullscreen}>Close</button> */}
                  {/* Display the fullscreen image or document */}
                  {renderFullscreenContent()}
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
        <Modal
          show={imageShow}
          onHide={handleImageClose}
          size="lg"
          style={{ marginTop: "13vh", height: "80vh" }}
        >
          <Modal.Header className="d-flex justify-content-between">
            <h5 className="modal-title" id="post-modalLabel">
              Upload Product Views
            </h5>
            <button
              type="button"
              className="btn btn-secondary lh-1"
              onClick={handleImageClose}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "60vh", overflowX: "scroll" }}>
            <div
              style={{
                height: "12rem",
                width: "12rem",
                objectFit: "contain",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {renderImagePreviews()}
            </div>
            <div>
              <Form>
                <Row className="align-items-center">
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label htmlFor="img_title" className="form-label">
                      Image Title
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      value={formData.img_title}
                      id="img_title"
                      placeholder="Enter your Image title"
                      onChange={(e) =>
                        handleInputChange("img_title", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label htmlFor="img_tag" className="form-label">
                      Image Tag:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="img_tag"
                      placeholder="Enter your Image Tag"
                      value={formData.img_tag}
                      onChange={(e) =>
                        handleInputChange("img_tag", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label htmlFor="img_desc" className="form-label">
                      Image Description:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="img_desc"
                      placeholder="Enter your Image Description"
                      value={formData.img_desc}
                      onChange={(e) =>
                        handleInputChange("img_desc", e.target.value)
                      }
                    />
                  </Form.Group>
                </Row>
              </Form>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="d-block w-100"
              onClick={updateMemberupload}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default ProfilePhotos;
