import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Alert, Card } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

const AddVisitorForm = ({ meetingId, onClose }) => {
  const [visitorData, setVisitorData] = useState({
    meeting_id: meetingId,
    visitor_name: "",
    visitor_mob: "",
    visitor_email: "",
    visitor_address: "",
    created_by: sessionStorage.getItem("member_id"),
    updated_by: sessionStorage.getItem("member_id"),
    org_id: sessionStorage.getItem("org_id"),
  });
  const [error, setError] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const token = sessionStorage.getItem("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisitorData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !visitorData.visitor_name ||
      !visitorData.visitor_mob ||
      !visitorData.visitor_email ||
      !visitorData.visitor_address
    ) {
      setError("Please fill out all fields.");
      return;
    }

    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/meetings/visitor`,
        visitorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setShowSuccessAlert(true); // Show success alert
    } catch (error) {
      setError(
        "An error occurred while creating the visitor. Please try again."
      );
    }
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
    onClose(); // Close the form when the alert is dismissed
  };

  return (
    <div className="add-visitor-form">
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                    className="close-icon"
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        cursor: "pointer",
                    }}
                />
            </div> */}
      <Card className="m-0 p-0">
      <Form onSubmit={handleSubmit}>
        <Card.Header>
          <h4>Add Visitor</h4>
        </Card.Header>
        <Card.Body>
          
            <Form.Group controlId="visitorName">
              <Form.Label>Visitor Name</Form.Label>
              <Form.Control
                type="text"
                name="visitor_name"
                value={visitorData.visitor_name}
                onChange={handleChange}
                placeholder="Enter visitor's name"
                required
              />
            </Form.Group>

            <Form.Group controlId="visitorMob">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                name="visitor_mob"
                value={visitorData.visitor_mob}
                onChange={handleChange}
                placeholder="Enter visitor's mobile number"
                required
              />
            </Form.Group>

            <Form.Group controlId="visitorEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="visitor_email"
                value={visitorData.visitor_email}
                onChange={handleChange}
                placeholder="Enter visitor's email"
                required
              />
            </Form.Group>

            <Form.Group controlId="visitorAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="visitor_address"
                value={visitorData.visitor_address}
                onChange={handleChange}
                placeholder="Enter visitor's address"
                required
              />
            </Form.Group>

            {/* <Button style={{ marginLeft: "650px", marginTop: "10px" }} variant="primary" type="submit">
                    Add Visitor
                </Button> */}
         
        </Card.Body>
        <Card.Footer className="text-end">
          <Button variant="primary" type="submit">
            Add Visitor
          </Button>
        </Card.Footer>
        </Form>
      </Card>
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {showSuccessAlert && (
        <Alert
          show={showSuccessAlert}
          variant="success"
          onClose={closeSuccess}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid green",
            color: "green",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
              onClick={closeSuccess}
            />
          </div>
          <Alert.Heading>Success!</Alert.Heading>
          <p>Visitor created successfully.</p>
        </Alert>
      )}
    </div>
  );
};

export default AddVisitorForm;
