import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import MemberSearch from "../../../../views/dashboard/app/memberSearch";
import OrgSearch from "../../../../views/dashboard/app/orgAdminSearch";
import MeetingSearch from "../../../../views/dashboard/app/meetingSearch";
import { useLocation } from "react-router-dom";
import MyReferralSearch from "../../../../views/dashboard/app/myReferralSearch";
import SentReferralSearch from "../../../../views/dashboard/app/sentReferralSearch";
import AllMeetingSearch from "../../../../views/dashboard/app/allMeetingSearch";
import AllReferralSearch from "../../../../views/dashboard/app/allReferralSearch";
import ChapterMeetingSearch from "../../../../views/dashboard/app/chapterMeetingSearch";
import IndexSearch from "../../../../views/dashboard/indexSearch";
import MemberMeetingAdminSearch from "../../../../views/dashboard/app/memberMeetingAdminSearch";
import ChapterReceivedReferralSearch from "../../../../views/dashboard/app/ChapterReceivedReferralsSearch";
import ChapterSentReferralSearch from "../../../../views/dashboard/app/chapterSentReferralsSearch";

const RightSidebar = () => {
  const [paddingTop, setPaddingTop] = useState("3vh");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const newPaddingTop = window.innerWidth < 768 ? "13vh" : "3vh";
      setPaddingTop(newPaddingTop);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOutside = (event) => {
    const rightSidebar = sidebarRef.current;

    if (
      rightSidebar &&
      isSidebarOpen &&
      !rightSidebar.contains(event.target) &&
      !event.target.classList.contains("right-sidebar-toggle")
    ) {
      // Clicked outside the RightSidebar and not on the search icon, hide it
      setIsSidebarOpen(false);
    }
  };

  const handleClickSearchIcon = () => {
    // Toggle the sidebar state when clicking the search icon
    setIsSidebarOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen]);

  const determineCurrentView = () => {
    const path = location.pathname;

    if (path.includes("/for-me")) {
      return "myReferralSearch";
    } else if (path.includes("/by-me")) {
      return "sentReferralSearch";
    } else if (path.includes("/meeting")) {
      return "meetingSearch";
    } else if (path.includes("/AllMeetingList")) {
      return "allMeetingSearch";
    } else if (path.includes("/memberMeetingAdmin")) {
      return "memberMeetingAdminSearch";
    } else if (path.includes("/referralsList")) {
      return "allReferralSearch";
    } else if (path.includes("/chapter-received-referrals")) {
      return "chapterReceivedReferralSearch";
    } else if (path.includes("/chapter-sent-referrals")) {
      return "chapterSentReferralSearch";
    } else if (path.includes("/chapter-meeting")) {
      return "chapterMeeting";
    } else if (path.includes("/userposts")) {
      return "userPost";
    } else if (path.includes("/organizations")) {
      return "orgSearch";
    } else if (
      path.includes("/dashboards/app/members") ||
      path.includes("dashboard/app/MembersList")
    ) {
      return "memberSearch";
    }
  };

  const currentView = determineCurrentView();

  return (
    <div
      className={`right-sidebar-mini ${isSidebarOpen ? "right-sidebar" : ""}`}
      id="rightSidebar"
      ref={sidebarRef}
    >
      <div className="right-sidebar-panel p-0">
        <Card className="shadow-none">
          <Card.Body style={{ paddingTop }}>
            {currentView === "memberSearch" && <MemberSearch />}
            {currentView === "orgSearch" && <OrgSearch />}
            {currentView === "myReferralSearch" && <MyReferralSearch />}
            {currentView === "sentReferralSearch" && <SentReferralSearch />}
            {currentView === "meetingSearch" && <MeetingSearch />}
            {currentView === "allMeetingSearch" && <AllMeetingSearch />}
            {currentView === "memberMeetingAdminSearch" && (
              <MemberMeetingAdminSearch />
            )}
            {currentView === "allReferralSearch" && <AllReferralSearch />}
            {currentView === "chapterReceivedReferralSearch" && (
              <ChapterReceivedReferralSearch />
            )}
            {currentView === "chapterSentReferralSearch" && (
              <ChapterSentReferralSearch />
            )}
            {currentView === "chapterMeeting" && <ChapterMeetingSearch />}
            {currentView === "userPost" && <IndexSearch />}
          </Card.Body>

          <div
            className="right-sidebar-toggle bg-danger text-white d-flex"
            onClick={handleClickSearchIcon}
            style={{ marginTop: "20vh" }}
          >
            <span className="material-symbols-outlined">search</span>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default RightSidebar;
