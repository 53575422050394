import { useState, useEffect } from "react";

const useOrgNaming = (org_id, token) => {
    const [getOrgNames, setGetOrgNames] = useState([]);
    const [getSettingNames, setGetSetting] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const fetchOrgNaming = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/org/getOrgNaming/${org_id}`, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const data = await response.json();
            setGetOrgNames(data.result);
            setSuccess(true);
            setError(null);
        } catch (error) {
            setError(error.message);
            setSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    const getOrgSetting = async () => {
        try {      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/org/getOrgSetting`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ OrgID: org_id }),
          });
      
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
      
          const data = await response.json();
          setGetSetting(data.result);
        } catch (error) {
          console.error("Error occurred while fetching org settings:", error);
          throw error;
        }
      };

    useEffect(() => {
        fetchOrgNaming();
        getOrgSetting();
    }, [org_id, token]);

    return { getOrgNames, getSettingNames, loading, success, error, refreshOrgNames: fetchOrgNaming };
};

export default useOrgNaming;
