import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CustomToggle from "../../components/dropdowns";
import ShareOffcanvas from "../../components/share-offcanvas";
import { useLocation } from "react-router-dom";
import AdminPosts from "./adminPosts";
import { FaTimes } from "react-icons/fa";

//image
import small1 from "../../assets/images/small/07.png";
import profileImg from "../../assets/images/user/1.png";
import user1 from "../../assets/images/user/1.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user2 from "../../assets/images/user/02.jpg";
import user3 from "../../assets/images/user/03.jpg";
import user4 from "../../assets/images/user/04.jpg";
import img1 from "../../assets/images/small/07.png";
import img2 from "../../assets/images/small/08.png";
import img3 from "../../assets/images/small/09.png";
import img4 from "../../assets/images/small/10.png";
import img5 from "../../assets/images/small/11.png";
import img6 from "../../assets/images/small/12.png";
import img7 from "../../assets/images/small/13.png";
import img8 from "../../assets/images/small/14.png";
import p1 from "../../assets/images/page-img/p1.jpg";
import s1 from "../../assets/images/page-img/s1.jpg";
import s2 from "../../assets/images/page-img/s2.jpg";
import s3 from "../../assets/images/page-img/s3.jpg";
import s4 from "../../assets/images/page-img/s4.jpg";
import s5 from "../../assets/images/page-img/s5.jpg";
import p2 from "../../assets/images/page-img/p2.jpg";
import p3 from "../../assets/images/page-img/p3.jpg";
import p4 from "../../assets/images/page-img/p4.jpg";
import p5 from "../../assets/images/page-img/p5.jpg";
import img42 from "../../assets/images/page-img/42.png";
import icon1 from "../../assets/images/icon/01.png";
import iconD from "../../assets/images/icon/15.png";
import icon2 from "../../assets/images/icon/02.png";
import icon3 from "../../assets/images/icon/03.png";
import icon4 from "../../assets/images/icon/04.png";
import icon5 from "../../assets/images/icon/05.png";
import icon6 from "../../assets/images/icon/06.png";
import icon7 from "../../assets/images/icon/07.png";
import img9 from "../../assets/images/small/img-1.jpg";
import img10 from "../../assets/images/small/img-2.jpg";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import EventPost from "./app/eventPost";
import ChapterMeetingPost from "../../views/dashboard/app/chapterMeetingPost";
import { IoMdAddCircleOutline } from "react-icons/io";

const Index = () => {
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [postData, setPostData] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [postData, setPostData] = useState('')
  const [textValue, setTextValue] = useState("");
  const [successMessages, setSuccessMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const fileInputRef = useRef(null);
  const location = useLocation();
  let filteredState = (location.state ?? {}).filteredResult ?? [];
  const [filteredDataState, setFilteredDataState] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [likedPosts, setLikedPosts] = useState({}); // Track liked posts
  const [dislikes, setDislikes] = useState({});

  const handleClose = () => {
    setShow(false);
    setInputValue("");
    setTextValue("");
    setSelectedImage(false);

    setIsImageSelected(false);
  };
  const [postComment, setPostComment] = useState("");
  const [postLiked, setPostLiked] = useState(false);
  const [userIDd, setUserIDd] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [clicked, setClicked] = useState(false);
  const [userIddddd, setUserIddddd] = useState("");
  const [chapterID, setChapterID] = useState("");
  const [memberID, setMemberID] = useState("");
  const [commentsVisible, setCommentsVisible] = useState({});
  const [activeCommentForm, setActiveCommentForm] = useState(null);

  const token = sessionStorage.getItem("token");
  // Get FCM token from local storage
  const fcmToken = localStorage.getItem("fcmToken");

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  //useEfferct to fetching user id from local storage

  useEffect(() => {
    const userIddd = sessionStorage.getItem("member_id");
    setUserIddddd(userIddd);
    const chapterID = sessionStorage.getItem("chapter_id");
    setChapterID(chapterID);

    const memberID = sessionStorage.getItem("member_id");
    setMemberID(memberID);
  }, []);

  // to fetch likes after few seconds
  const toggleCommentForm = (postId) => {
    setActiveCommentForm((prevFormId) =>
      prevFormId === postId ? null : postId
    );
  };

  useEffect(() => {
    // Poll for updates every 30 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchPost();
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [chapterID]);

  useEffect(() => {
    const storedUserID = sessionStorage.getItem("userID");
    if (storedUserID) {
      // Set the user ID in state
      setUserIDd(storedUserID);
    }
  }, []);

  const handleAddPohoto = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
  };

  function handleTextArea(e) {
    setTextValue(e.target.value);
  }

  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  // useEffect(() => {
  //   const fetchReactionStatus = async () => {
  //     const userId = sessionStorage.getItem("member_id");

  //     // Loop through the posts to fetch reaction statuses
  //     const reactions = await Promise.all(
  //       postData.map(async (post) => {
  //         try {
  //           const response = await axios.post(process.env.REACT_APP_API_URL + "/pcomment/checkPostLiked", { post_id: post.post_id, member_id: userId });
  //           return { post_id: post.post_id, reaction: response.data.reaction }; // Store the reaction type
  //         } catch (error) {
  //           console.error(`Error fetching reaction for post ${post.post_id}:`, error);
  //           return { post_id: post.post_id, reaction: null }; // Fallback if error occurs
  //         }
  //       })
  //     );

  //     // Update local state based on fetched reactions
  //     reactions.forEach(({ post_id, reaction }) => {
  //       if (reaction) {
  //         // Update the state based on whether it's 'like' or 'dislike'
  //         if (reaction === 'like') {
  //           setLikedPosts((prev) => ({ ...prev, [post_id]: (prev[post_id] || 0) + 1 }));
  //           setDislikes((prev) => ({ ...prev, [post_id]: (prev[post_id] || 0) - 1 }));
  //         } else if (reaction === 'dislike') {
  //           setDislikes((prev) => ({ ...prev, [post_id]: (prev[post_id] || 0) + 1 }));
  //           setLikedPosts((prev) => ({ ...prev, [post_id]: (prev[post_id] || 0) - 1 }));
  //         }
  //       }
  //     });
  //   };

  //   fetchReactionStatus();
  // }, [postData]);

  const handlePost = async () => {
    if (!clicked) {
      // Set the state to indicate the button has been clicked
      setClicked(true);

      try {
        // Create a FormData object and append the image file to it
        const formData = new FormData();
        formData.append("post_photovideo_path", selectedImage);
        // Append other data to the FormData object
        formData.append("post_title", inputValue);
        formData.append("created_by", userIddddd);
        formData.append("member_id", memberID);
        formData.append("chapter_id", chapterID);
        formData.append("post_content", textValue);
        // Include FCM token in the request payload
        formData.append("fcmToken", fcmToken);
        // Perform the POST request using async/await
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/postt/userpostnew`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // Optionally, reset the input field after a successful post
        setInputValue("");
        setTextValue("");
        setSelectedImage(false);
        setIsImageSelected(false);
        fetchPost();
        showSuccess();
        handleClose();
        setClicked(false);
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  };

  const fetchPost = async () => {
    var url;

    setChapterID(sessionStorage.getItem("chapter_id"));

    if (chapterID == null || chapterID == "null") {
      setChapterID("org_id" + sessionStorage.getItem("org_id"));
      url = `/postt/getpost/allusers/${chapterID}`;
    } else {
      url = `/postt/getpost/allusers/${chapterID}`;
    }
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + url, {
        headers: {
          Authorization: `Bearer ${token}`, // including the token in the headers
        },
      });
      const data = response.data; // Use response.data directly, as axios parses JSON
      console.log("data related to post ----->>>>>", data);

      // Parse all_comments string into an array of objects
      //   const updatedData = data.postsWithPresignedUrls.map(item => ({
      //     ...item,
      //     all_comments: JSON.parse(item.all_comments),
      //   }));

      setPostData(data.postsWithPresignedUrls);
    } catch (error) {
      console.error("Error fetching post data", error);
    }
  };

  useEffect(() => {
    fetchPost();
    //fetchMemberData();
  }, [userIddddd]);

  const sortedDataa = useMemo(() => {
    if (Array.isArray(postData)) {
      return [...postData].sort(
        (a, b) => new Date(b.post_id) - new Date(a.post_id)
      );
    } else {
      console.error("postData is not an array");
      return [];
    }
  }, [postData]); // Ensure that postData is the only dependency, not sortedData

  // function handleComment() {
  //   setShowComments(!showComments);
  // }

  const handleComment = (postId) => {
    setCommentsVisible((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId], // Toggle visibility for the specific post
    }));
    toggleCommentForm(postId);
  };

  const handleChange = (e) => {
    const inputV = e.target.value;
    console.log("input value---->>>", inputV);
    setInputValue(inputV);
  };

  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    // Do something with the selected file, e.g., upload or process it
    console.log("Selected file:", selectedFile);
    setSelectedImage(selectedFile);
    console.log("selected Image 2---->>>>", selectedImage);
    if (selectedFile) {
      setIsImageSelected(true);
    } else {
      setIsImageSelected(false);
    }
  };

  const handlePostComment = (e) => {
    setPostComment(e.target.value);
  };

  const postCommentFunction = async (postID) => {
    // e.preventDefault();
    console.log("postID----->>>", postID);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/pcomment/postcomment/user",
        {
          comments: postComment,
          created_by: userIddddd,
          member_id: memberID,
          post_id: postID,
        }
      );

      if (!response.status === 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = response.data; // Access response data directly

      console.log("post data-->", data);
      fetchPost();
      setPostComment("");
    } catch (err) {
      console.error("Error saving post comment:", err.message);
    }
  };

  // //   //     if(postLiked){

  // //   //         try{

  // //   //             const response =  await axios.put(process.env.REACT_APP_API_URL +'/pcomment/postlikes/user', {
  // //   //                 post_id : postID,
  // //   //                 post_likes:postLikes-1,
  // //   //             })

  // //   //             if(response.status !== 201){
  // //   //             throw new Error(`HTTP error! Status: ${response.status}`);

  // //   //             }
  // //   //             const data = response.data;  // Access response data directly

  // //   //             console.log('post dislike-->', data);
  // //   //         setPostLiked(false);
  // //   //         fetchPost();
  // //   //         return;

  // //   //         }catch(err){
  // //   //             if(err){
  // //   //                 console.log('error while dislike post',err.message)
  // //   //             }
  // //   //         }
  // //   //     }

  // //   //    else{
  // //   if (!postLiked) {
  // //     try {
  // //       const response = await axios.put(
  // //         process.env.REACT_APP_API_URL + "/pcomment/postlikes/user",
  // //         {
  // //           post_id: postID,
  // //           post_likes: postLikes + 1,
  // //         }
  // //       );

  // //       if (response.status !== 201) {
  // //         throw new Error(`HTTP error! Status: ${response.status}`);
  // //       }

  // //       // Assuming the like is successfully updated on the server,
  // //       // set postLiked to true to indicate that the post has been liked
  // //       setPostLiked(true);

  // //       // Fetch updated post data
  // //       fetchPost();
  // //     } catch (err) {
  // //       console.error("Error while liking post:", err.message);
  // //     }
  // //   }
  // // };
  // const postLikeFunction = async (postID, postLikes) => {
  //   try {
  //     // Optimistically update the UI
  //     const newLikes = postLiked ? postLikes - 1 : postLikes + 1;
  //     setPostLiked(!postLiked);

  //     // Make the API request to update the like count on the server
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
  //       {
  //         post_id: postID,
  //         post_likes: newLikes,
  //       }
  //     );

  //     if (response.status !== 201) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     if (response) {
  //       setPostData((prevData) =>
  //         prevData.map((post) =>
  //           post.post_id === postID ? { ...post, post_likes: newLikes } : post
  //         )
  //       );

  //     }
  //     // Fetch updated post data (optional, depending on how you want to handle it)
  //     fetchPost();
  //   } catch (err) {
  //     console.error(
  //       `Error while ${postLiked ? "disliking" : "liking"} post:`,
  //       err.message
  //     );

  //     // Revert the optimistic UI update if the API call fails
  //     setPostLiked(postLiked);
  //     setPostData((prevData) =>
  //       prevData.map((post) =>
  //         post.post_id === postID
  //           ? { ...post, post_likes: postLiked ? postLikes + 1 : postLikes - 1 }
  //           : post
  //       )
  //     );
  //   }
  // };

  // const postDisLikeFunction = async (postID, postLikes) => {
  //   try {
  //     // Optimistically update the UI
  //     const newLikes = postLikes - 1;
  //     setPostLiked(false);
  //     setPostData((prevData) =>
  //       prevData.map((post) =>
  //         post.post_id === postID ? { ...post, post_likes: newLikes } : post
  //       )
  //     );

  //     // Make the API request to update the dislike count on the server
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
  //       {
  //         post_id: postID,
  //         post_likes: newLikes,
  //       }
  //     );

  //     if (response.status !== 201) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     // Optionally, fetch updated post data
  //     fetchPost();
  //   } catch (err) {
  //     console.error("Error while disliking post:", err.message);

  //     // Revert the optimistic UI update if the API call fails
  //     setPostLiked(true);
  //     setPostData((prevData) =>
  //       prevData.map((post) =>
  //         post.post_id === postID ? { ...post, post_likes: postLikes } : post
  //       )
  //     );
  //   }
  // };

  const postLikeFunction = async (postId) => {
    const userId = sessionStorage.getItem("member_id");

    // Check if the post is already liked by the current user
    // if (likedPosts[postId]) {
    //   return; // Exit if the post is already liked
    // }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/pcomment/checkPostLiked",
        { post_id: postId, member_id: userId }
      );
      if (response.data.liked === "like") {
        return;
      }
      // return { post_id: post.post_id, reaction: response.data.reaction }; // Store the reaction type
    } catch (error) {
      console.error(`Error fetching reaction for post ${postId}:`, error);
      // return { post_id: post.post_id, reaction: null }; // Fallback if error occurs
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
        {
          post_id: postId,
          user_id: userId,
          reaction_type: "like",
        }
      );

      if (response.status === 200) {
        // Update the local liked posts state
        setLikedPosts((prev) => ({ ...prev, [postId]: true }));

        // Optionally update the post likes count in the UI
        setPostData((prevPosts) =>
          prevPosts.map((post) =>
            post.post_id === postId
              ? { ...post, post_likes: post.post_likes + 1 }
              : post
          )
        );
      }
    } catch (error) {
      console.error("Error liking the post:", error);
    }
  };

  const postDisLikeFunction = async (postId) => {
    const userId = sessionStorage.getItem("member_id");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/pcomment/postlikes/user`,
        { post_id: postId, user_id: userId, reaction_type: "dislike" }
      );
      if (response.status === 200) {
        // Update local state
        setDislikes((prev) => ({ ...prev, [postId]: (prev[postId] || 0) + 1 }));
        if (likedPosts[postId]) {
          setLikedPosts((prev) => ({ ...prev, [postId]: prev[postId] - 1 }));
        }
      }
    } catch (error) {
      console.error("Error disliking the post:", error);
    }
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col className="row m-0 p-0">
              <Card id="post-modal-data">
                <Card.Body
                  data-toggle="modal"
                  data-target="#post-modal"
                  style={{
                    cursor: "pointer",
                    padding: ".5rem",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    onClick={handleShow}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <IoMdAddCircleOutline
                      style={{
                        fontSize: "2rem",
                        marginRight: ".5rem",
                        color: "#07bff",
                      }}
                    />
                    <h4 style={{ margin: 0, color: "#333" }}>
                      Create a post...
                    </h4>
                  </div>
                </Card.Body>

                <Modal
                  show={show}
                  onHide={handleClose}
                  size="lg"
                  style={{ marginTop: "100px" }}
                >
                  <Modal.Header className="d-flex justify-content-between">
                    <h5 className="modal-title" id="post-modalLabel">
                      Create Post
                    </h5>
                    <button
                      type="button"
                      className="btn btn-secondary lh-1"
                      onClick={handleClose}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body
                    style={{ maxHeight: "60vh", overflowX: "scroll" }}
                  >
                    <div className="d-flex align-items-center">
                      <form className="post-text ms-3 w-100" action="">
                        <textarea
                          className="form-control rounded"
                          placeholder="Write something about your post.."
                          style={{ border: "none" }}
                          value={inputValue}
                          onChange={handleChange}
                          onClick={handleShow}
                        />
                      </form>
                    </div>
                    <hr />
                    <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                      <li className="w-100 mb-2" onClick={handleAddPohoto}>
                        <div className="bg-soft-primary rounded p-2 pointer">
                          <Link to="#"></Link>
                          <img
                            loading="lazy"
                            src={small1}
                            alt="icon"
                            className="img-fluid"
                          />{" "}
                          Add Photo/Video
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange2}
                        />

                        <li className="col-md-12">
                          {selectedImage && (
                            <div
                              style={{
                                width: "100%",
                                height: "20vh",
                                padding: ".5rem",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="Selected"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          )}
                        </li>
                      </li>
                    </ul>

                    <div className="other-option">
                      <h6>
                        <textarea
                          value={textValue}
                          onChange={handleTextArea}
                          placeholder="Write the details about your post..."
                          style={{
                            width: "100%",
                            height: "10rem",
                            padding: ".3rem",
                          }}
                        ></textarea>
                      </h6>
                    </div>
                    <Button
                      variant="primary"
                      className="d-block w-100 mt-3"
                      onClick={handlePost}
                      disabled={clicked}
                    >
                      Post
                    </Button>
                  </Modal.Body>
                </Modal>
              </Card>
              <div>
                <div
                  onClick={toggleCollapse}
                  style={{
                    cursor: "pointer",
                    padding: ".5rem .8rem",
                    margin: ".5rem 0",
                    backgroundColor: isCollapsed ? "#007BFF" : "#0056b3", // Change color when expanded
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontWeight: "bold",
                    transition: "background-color 0.3s",
                  }}
                >
                  {isCollapsed ? "View Announcements" : "Hide Announcements"}
                </div>

                {!isCollapsed && (
                  <div
                    style={{
                      height: "80vh",
                      overflowY: "scroll",
                      border: "3px solid #ccc",
                      borderRadius: "5px",
                      marginTop: "10px",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    {filteredState.length === 0 && (
                      <>
                        <AdminPosts />
                        <EventPost />
                        <ChapterMeetingPost />
                      </>
                    )}
                  </div>
                )}
              </div>

              {(filteredState && filteredState.length > 0
                ? filteredState
                : sortedDataa
              ).map((item, index) => (
                <Col sm={12} key={index}>
                  <div className="card card-block card-stretch card-height">
                    <div className="card-body">
                      <div className="user-post-data">
                        <div className="d-flex justify-content-between">
                          <div className="me-3">
                            <img
                              loading="lazy"
                              className="rounded-circle  avatar-60"
                              src={item.profilePresignedUrl || profileImg}
                              alt=""
                            />
                          </div>
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h5 className="mb-0 d-inline-block" key={index}>
                                  {item.first_name} {item.last_name}
                                </h5>
                                {/* <p className="mb-0 ps-1 d-inline-block">Added New Video in his Timeline</p>
                                                        <p className="mb-0 text-primary">1 day ago</p> */}
                                <p className="mb-0 text-primary" key={index}>
                                  {item.post_title}
                                </p>
                              </div>
                              <div className="card-post-toolbar">
                                <Dropdown>
                                  <Dropdown.Toggle variant="bg-transparent">
                                    <span className="material-symbols-outlined">
                                      more_horiz
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                    <Dropdown.Item className=" p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <div className="h4">
                                          <i className="ri-save-line"></i>
                                        </div>
                                        <div className="data ms-2">
                                          <h6>Save Post</h6>
                                          <p className="mb-0">
                                            Add this to your saved items
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-close-circle-line h4"></i>
                                        <div className="data ms-2">
                                          <h6>Hide Post</h6>
                                          <p className="mb-0">
                                            See fewer posts like this.
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className=" p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-user-unfollow-line h4"></i>
                                        <div className="data ms-2">
                                          <h6>Unfollow User</h6>
                                          <p className="mb-0">
                                            Stop seeing posts but stay friends.
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className=" p-3" to="#">
                                      <div className="d-flex align-items-top">
                                        <i className="ri-notification-line h4"></i>
                                        <div className="data ms-2">
                                          <h6>Notifications</h6>
                                          <p className="mb-0">
                                            Turn on notifications for this post
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.presignedUrl ? null : (
                        <div className="mt-3">
                          <p key={index}>{item.post_content}</p>
                        </div>
                      )}

                      <div className="user-post">
                        {item.presignedUrl ? (
                          <div>
                            <div className="mt-3">
                              <p key={index}>{item.post_content}</p>
                            </div>
                            <div>
                              <img
                                loading="lazy"
                                src={item.presignedUrl}
                                alt={item.post_content}
                                className="img-fluid w-100"
                              />
                            </div>
                          </div>
                        ) : (
                          <p key={index}>{item.post_content}</p>
                        )}
                      </div>
                      <div className="comment-area mt-3">
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                          <div className="like-block position-relative d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              {/* Like Section */}
                              <div className="d-flex align-items-center">
                                <Dropdown key={item.post_id}>
                                  <Dropdown.Toggle
                                    as={CustomToggle}
                                    id={`like-toggle-${item.post_id}`}
                                    onClick={() =>
                                      postLikeFunction(item.post_id)
                                    }
                                    disabled={likedPosts[item.post_id]} // Disable if already liked
                                    className="like-toggle-btn"
                                  >
                                    <img
                                      src={icon1}
                                      className="img-fluid"
                                      alt="Like"
                                    />
                                    <span className="ms-2">
                                      {Math.max(item.post_likes, 0)} Likes
                                    </span>
                                  </Dropdown.Toggle>
                                </Dropdown>
                               
                              </div>
                              <div
                                style={{ marginLeft: "10px" }}
                                className="d-flex align-items-center"
                              >
                                <Dropdown key={item.post_id}>
                                  <Dropdown.Toggle
                                    as={CustomToggle}
                                    id={`dislike-toggle-${item.post_id}`}
                                    onClick={() =>
                                      postDisLikeFunction(item.post_id)
                                    } // Directly call the function with post_id
                                    disabled={postLiked} // Disable if post has already been liked
                                  >
                                    <img
                                      src={iconD}
                                      className="img-fluid"
                                      alt="Dislike"
                                   
                                    />
                                     <span className="ms-2">
                                  {/* {dislikes[item.post_id] || 0} */}
                                  Dislikes</span>
                                  </Dropdown.Toggle>
                                </Dropdown>
                               
                              </div>
                            </div>
                            <div
                              style={{ marginLeft: "10px" }}
                              className="total-comment-block"
                            >
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  id="post-option"
                                  onClick={() => handleComment(item.post_id)}
                                >
                                  Comments
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </div>
                          <ShareOffcanvas />
                        </div>
                        <hr />
                        {/* Comments section */}
                        {commentsVisible[item.post_id] && (
                          <ul className="post-comments list-inline p-0 m-0">
                            {item.all_comments &&
                              item.all_comments.map(
                                (comment, commentIndex) =>
                                  comment.username && (
                                    <li key={commentIndex} className="mb-2">
                                      <div className="d-flex flex-wrap">
                                        <div className="user-img">
                                          <img
                                            src={
                                              comment.profilePresignedUrl ||
                                              profileImg
                                            }
                                            alt="user1"
                                            className="avatar-35 rounded-circle img-fluid"
                                          />
                                        </div>
                                        <div className="comment-data-block ms-3">
                                          <h6>{comment.username}</h6>
                                          <p className="mb-0">
                                            {comment.comment}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                              )}
                          </ul>
                        )}

                        {activeCommentForm === item.post_id && (
                          <form className="comment-text d-flex align-items-center mt-3">
                            <input
                              value={postComment}
                              onChange={handlePostComment}
                              type="text"
                              className="form-control rounded"
                              placeholder="Enter Your Comment"
                            />
                            <button
                              style={{ marginLeft: "10px" }}
                              type="button"
                              onClick={() => {
                                postCommentFunction(item.post_id);
                                setActiveCommentForm(null); // Close the form after submission
                              }}
                            >
                              Submit
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
      <Alert
        show={showSuccessAlert}
        variant="success"
        onClose={closeSuccess}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
            onClick={closeSuccess}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        <p>Post Added Successfully .</p>
      </Alert>
    </>
  );
};

export default Index;
