import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
  Card,
} from "react-bootstrap";
//import {  Row, Col, Button, Card, Form, Modal, Table, Alert } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { FaTimes } from "react-icons/fa";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import 'swiper/components/navigation/navigation.scss';

//img
import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.png";
import login2 from "../../../assets/images/login/2.png";
import login3 from "../../../assets/images/login/3.png";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const ResetPass = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const [errorMessage, setErrorMessage] = useState("");
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgGallery, setOrgGallery] = useState([]);
  const [org, setOrg] = useState(logo);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailId = searchParams.get("email_id"); // Get the email parameter from the URL

  useEffect(() => {
    // Use the emailId in your code or state if needed
  }, [emailId, location.search, searchParams]);

  const navigate = useNavigate();

  const handleSuccessM = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  const fetchMembers = async (org_id) => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/org/getOrgImages/${org_id}`)
        .then((response) => {
          if (response.status === 200) {
            const { awsLogoUrls, galleryUrlsWithDesc } = response.data;
            const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
            const G1 = galleryUrlsWithDesc;

            setOrgLogo(L1);
            setOrgGallery(G1);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("No organization found:", error);
          } else {
            console.error("Error fetching organization images:", error);
          }
        });
    } catch (error) {
      console.error("Unexpected error occurred:", error);
    }
  };

  const displayErrorMessage = (message) => {
    setErrorMessage(message);
  };
  //validation to signup form

  //const emailId = 'sachinjadhavdn@gmail.com'

  const handleChangePassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
        // Handle password mismatch
        setError("New password and confirm password don't match");
        return;
      }

      const response = await axios.put(
        process.env.REACT_APP_API_URL + `/auth/change-password`,
        {
          password: newPassword,
          email_id: emailId,
        }
      );

      // Handle success
      if (response) {
        // showSuccess();
        handleSuccess("Password changed successfully: ");
      }
      // Add any additional logic you want to perform after a successful password change
    } catch (error) {
      // Handle errors
      setError("Error in update password request");

      console.error("Error changing password:", error.message);
      // You may want to show an error message to the user
    }
  };

  const handleSuccess = () => {
    navigate("/auth/sign-in");
  };

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0" style={{ margin: "auto" }}>
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 ">
              <div className="sign-in-detail text-white">
                <div
                  className="sign-in-logo mb-5 justify-content-center"
                  to="#"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div class="d-flex align-items-center">
                    {!orgLogo ? (
                      <svg
                        width="50"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <img
                        src={orgLogo}
                        className="img-fluid ml -3"
                        alt="logo"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {!org.organization_name ? (
                      <h1 className="text-white ml-3">
                        &nbsp;<b>CB</b>
                      </h1>
                    ) : (
                      <h1 className="text-white ml-3">
                        <b>{org.organization_name}</b>
                      </h1>
                    )}
                  </div>
                </div>
                <div className="sign-slider overflow-hidden">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0"
                  >
                    {orgGallery.length > 0 ? (
                      orgGallery.map((image, index) => (
                        <SwiperSlide key={index}>
                          <Image
                            style={{ paddingLeft: "30px" }}
                            src={image.url}
                            className="img-fluid mb-4"
                            alt={`Slide ${index + 1}`}
                          />
                          {/* Optionally, display description if available */}
                          {image.desc && (
                            <div className="image-description">
                              <p>{image.desc}</p>
                            </div>
                          )}
                        </SwiperSlide>
                      ))
                    ) : (
                      <>
                        <Swiper
                          spaceBetween={30}
                          centeredSlides={true}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          className="list-inline m-0 p-0 "
                        >
                          <SwiperSlide>
                            <Image
                              src={login1}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Find new friends
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login2}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Connect with the world
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login3}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Create new events
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                        </Swiper>
                      </>
                    )}
                  </Swiper>
                </div>
              </div>
            </Col>
            {/* <Col md="6" className="bg-white pt-5  pt-5 pb-5 lg-5 pb-5"> */}

            <Col
              md="6"
              style={{
                marginTop: "60px",
                marginBottom: "40px",
                maxHeight: "700px",
              }}
            >
              {" "}
              {/* Reduced padding from pt-5 and pb-5 to pt-3 and pb-3 */}
              <Card className="sign-in-from" style={{ marginTop: "40px" }}>
                <Card.Header>
                  <h3 className="text-center">Reset Password</h3>
                  {/* <p>Enter your email address and password to access admin panel.</p> */}
                </Card.Header>
                <Card.Body>
                  <Form className="mt-2">
                    <Form.Group className="form-group">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="mb-0"
                        id="newPassword"
                        placeholder="Enter New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="mb-0"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                    <div className="d-inline-block w-100">
                      <Button
                        variant="primary"
                        type="button"
                        className="float-end"
                        onClick={handleChangePassword}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Success Alert */}
        {/* <AlertComponent show={showSuccessAlert} variant="success" onClose={closeSuccess} message="Your login successfully." /> */}

        {/* Error Alert */}
        {/* {error && <AlertComponent variant="danger" onClose={clearError} message={error} />} */}
      </section>

      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default ResetPass;
