import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const MembersCard = ({ chapterId, onClose }) => {
  const token = sessionStorage.getItem("token");

  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/members/members/chapter/${chapterId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw Error("Failed to fetch members");
        }
        const data = await response.json();
        setMembers(data.results);
      } catch (err) {
        console.error("Error fetching members:", err);
        // setError('An error occurred while fetching members. Please try again later.');
      }
    };

    fetchMembers();
  }, [chapterId]);

  const navigateToFriendProfile = (memberId, user_id) => {
    // Navigate to the friend profile page with memberId
    navigate("/dashboard/app/fprofile", {
      state: { state: memberId, userid: user_id },
    });
  };

  return (
    <Card
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
        marginTop: "5vh",
        marginBottom: "15vh",
        maxHeight: "80vh",
        maxWidth: "90%",
        marginRight: "10%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <h4>Members</h4>
          <div
            className="close-icon"
            onClick={onClose}
            style={{
              cursor: "pointer",
              fontSize: "20px",
            }}
          >
            &#10006;
          </div>
        </div>
      </Card.Header>

      <Card.Body style={{ flex: 1, overflowY: "auto" }}>
        {error ? (
          <p>Error: {error}</p>
        ) : (
          <div className="table-responsive">
            <Table className="mb-0 rounded">
              <thead className="bg-primary text-white">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr key={member.member_id}>
                    <td>{`${member.first_name} ${member.last_name}`}</td>
                    <td>{member.email_id}</td>
                    <td>
                      <FaEye
                        size={15}
                        color="#007BFF"
                        onClick={() =>
                          navigateToFriendProfile(
                            member.member_id,
                            member.user_id
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default MembersCard;
