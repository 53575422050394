import React, { useState } from "react";
import { Container, Row, Card, Button } from "react-bootstrap";
import EventList from "./eventList";
import CreateEventForm from "./createEventForm";

const Event = () => {
  const [showEventList, setShowEventList] = useState(true);

  const handleCreateEventClick = () => {
    setShowEventList(false);
  };

  const handleCancelCreateEvent = () => {
    setShowEventList(true);
  };

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row className="justify-content-center">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  <b>Events</b>
                </h4>
              </div>
              <div className="d-flex flex-column flex-lg-row justify-content-end">
                <Button
                  variant="primary"
                  onClick={
                    showEventList
                      ? handleCreateEventClick
                      : handleCancelCreateEvent
                  }
                  className="my-2"
                >
                  {showEventList ? "Create Event" : "View Events"}
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {showEventList ? (
                <EventList />
              ) : (
                <CreateEventForm onCancel={handleCancelCreateEvent} />
              )}
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default Event;
