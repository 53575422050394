// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3Unr0wQ1wZYB7DnEEgzBiiAZNXj1bbdc",
  authDomain: "cbsocial-2852c.firebaseapp.com",
  projectId: "cbsocial-2852c",
  storageBucket: "cbsocial-2852c.appspot.com",
  messagingSenderId: "565742364153",
  appId: "1:565742364153:web:a69ba10fc7d26933b48928",
  measurementId: "G-GEW3Q9HV9N"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

const checkPermission = async () => {
  if (!('serviceWorker' in navigator)) {
    throw new Error("No support for service worker!")
  }

  if (!('Notification' in window)) {
    throw new Error("No support for notification API");
  }

  if (!('PushManager' in window)) {
    throw new Error("No support for Push API")
  }

  // Check if permission to display notifications has been granted
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    throw new Error("Notification permission not granted")
  }
}

const registerSW = async () => {
  const registration = await navigator.serviceWorker.register('service-worker.js');
  return registration;
}

export const generateToken = async () => {
  try {
    await checkPermission();
    await registerSW();

    // Add your Firebase Cloud Messaging token generation code here
    const token = await getToken(messaging, {
      vapidKey: "BGLM9TjNu9_LfxHLSDgnGqh8erwR6bSI-l2cW6RRUjnmbO2fkgX1DvIyOlzDvKuInV2gPD8MQ-pRK0lRYKw689M"    });
    console.log("token: ", token);
    localStorage.setItem('fcmToken', token);
  } catch (error) {
    console.error("Error generating token: ", error);
  }
}
// export const generateToken = async () => {
//   checkPermission();
//   await requestNotificationPermission();
//   await registerSW();

//   // Add your Firebase Cloud Messaging token generation code here
//   const permission = await Notification.requestPermission();
//   console.log(permission);
//   if (permission === "granted") {
//       const token = await getToken(messaging, {
//           vapidKey: "BGLM9TjNu9_LfxHLSDgnGqh8erwR6bSI-l2cW6RRUjnmbO2fkgX1DvIyOlzDvKuInV2gPD8MQ-pRK0lRYKw689M"
//       });
//       console.log("token-------", token);
//       localStorage.setItem('fcmToken', token);
//   }
// }



// export const generateToken = async () => {
//   const permission = await Notification.requestPermission();
//   console.log(permission);
//   if (permission === "granted") {

//     const token = await getToken(messaging, {
//       vapidKey: "BGLM9TjNu9_LfxHLSDgnGqh8erwR6bSI-l2cW6RRUjnmbO2fkgX1DvIyOlzDvKuInV2gPD8MQ-pRK0lRYKw689M"
//     });
//     console.log("token-------", token);
//     localStorage.setItem('fcmToken', token);
//   }

// }