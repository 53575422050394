import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// react-boostrap
import {
  Container,
  Col,
  Row,
  Dropdown,
  ProgressBar,
  Card,
} from "react-bootstrap";
// fullcalender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { useNavigate } from "react-router-dom";
// components
// import Card from '../../../components/Card'
import CustomToggle from "../../../components/dropdowns";

// Datepicker
import Datepicker from "../../../components/datepicker";
import Swal from "sweetalert2"; // Import Swal

// apex-chart
import Chart from "react-apexcharts";

const Admin = () => {
  const token = sessionStorage.getItem("token");
  const roleId = sessionStorage.getItem("role_id");
  const [currentMonthOrgs, setCurrentMonthOrgs] = useState([]);
  const [lastMonthOrgs, setLastMonthOrgs] = useState([]);
  const [orgCount, setOrgCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [currentMonthMembers, setCurrentMonthMembers] = useState([]);
  const [lastMonthMembers, setLastMonthMembers] = useState([]);
  const [currentMonthMemberCount, setCurrentMonthMemberCount] = useState(0);
  const [lastMonthMemberCount, setLastMonthMemberCount] = useState(0);

  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/getAllOrg",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const orgList = data.result.length; // This should give you the correct count

        console.log("Organization list length:", orgList); // Debugging the length

        // Update the state with the org count
        setOrgCount(orgList);
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        console.error("Error from backend:", errorData.message);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
    }
  };

  const fetchMembersDataFromApi = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/members/members/getAll/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const memberList = data.results.length;

        // Update the state with the filtered lists and their counts
        setMemberCount(memberList);
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        console.error("Error from backend:", errorData.message);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
    }
  };

  // const fetchDataFromApi = async () => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/org/getAllOrg",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       const orgList = data.result.length;

  //       // Get the current date
  //       const currentDate = new Date();

  //       // Function to get the first and last date of the previous month
  //       const getPreviousMonthRange = () => {
  //         const firstDayPrevMonth = new Date(
  //           currentDate.getFullYear(),
  //           currentDate.getMonth() - 1,
  //           1
  //         );
  //         const lastDayPrevMonth = new Date(
  //           currentDate.getFullYear(),
  //           currentDate.getMonth(),
  //           0
  //         );
  //         return { firstDayPrevMonth, lastDayPrevMonth };
  //       };

  //       const { firstDayPrevMonth, lastDayPrevMonth } = getPreviousMonthRange();

  //       // Filter the organizations
  //       const currentMonthOrgs = orgList.filter((org) => {
  //         const createdOn = new Date(org.created_on);
  //         return (
  //           createdOn.getMonth() === currentDate.getMonth() &&
  //           createdOn.getFullYear() === currentDate.getFullYear()
  //         );
  //       });

  //       const lastMonthOrgs = orgList.filter((org) => {
  //         const createdOn = new Date(org.created_on);
  //         return (
  //           createdOn >= firstDayPrevMonth && createdOn <= lastDayPrevMonth
  //         );
  //       });

  //       // Count the organizations
  //       const currentMonthCount = currentMonthOrgs.length;
  //       const lastMonthCount = lastMonthOrgs.length;

  //       // Update the state with the filtered lists and their counts
  //       setOrgCount(orgList);
  //       // setCurrentMonthOrgs(currentMonthOrgs);
  //       // setLastMonthOrgs(lastMonthOrgs);
  //       // setCurrentMonthCount(orgList);
  //       // setLastMonthCount(lastMonthCount);
  //     } else {
  //       // Handle errors from the backend
  //       const errorData = await response.json();
  //       console.error("Error from backend:", errorData.message);
  //     }
  //   } catch (error) {
  //     // Handle unexpected errors
  //     console.error("Unexpected error:", error.message);
  //   }
  // };

  // const fetchMembersDataFromApi = async () => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/members/members/getAll/list",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       const memberList = data.results.length;

  //       // Get the current date
  //       const currentDate = new Date();

  //       // Function to get the first and last date of the previous month
  //       const getPreviousMonthRange = () => {
  //         const firstDayPrevMonth = new Date(
  //           currentDate.getFullYear(),
  //           currentDate.getMonth() - 1,
  //           1
  //         );
  //         const lastDayPrevMonth = new Date(
  //           currentDate.getFullYear(),
  //           currentDate.getMonth(),
  //           0
  //         );
  //         return { firstDayPrevMonth, lastDayPrevMonth };
  //       };

  //       const { firstDayPrevMonth, lastDayPrevMonth } = getPreviousMonthRange();

  //       // Filter the members
  //       const currentMonthMembers = memberList.filter((member) => {
  //         const createdOn = new Date(member.created_on);
  //         return (
  //           createdOn.getMonth() === currentDate.getMonth() &&
  //           createdOn.getFullYear() === currentDate.getFullYear()
  //         );
  //       });

  //       const lastMonthMembers = memberList.filter((member) => {
  //         const createdOn = new Date(member.created_on);
  //         return (
  //           createdOn >= firstDayPrevMonth && createdOn <= lastDayPrevMonth
  //         );
  //       });

  //       // Count the members
  //       const currentMonthMemberCount = currentMonthMembers.length;
  //       const lastMonthMemberCount = lastMonthMembers.length;

  //       // Update the state with the filtered lists and their counts
  //       setMemberCount(memberList);
  //       // setCurrentMonthMembers(currentMonthMembers);
  //       // setLastMonthMembers(lastMonthMembers);
  //       // setCurrentMonthMemberCount(currentMonthMemberCount);
  //       // setLastMonthMemberCount(lastMonthMemberCount);
  //     } else {
  //       // Handle errors from the backend
  //       const errorData = await response.json();
  //       console.error("Error from backend:", errorData.message);
  //     }
  //   } catch (error) {
  //     // Handle unexpected errors
  //     console.error("Unexpected error:", error.message);
  //   }
  // };

  useEffect(() => {
    fetchMembersDataFromApi();
    fetchDataFromApi();
  }, []); // The empty array ensures this runs only once, when the component mounts

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row as="ul" className="list-unstyled mb-3">
            <Col md="6" lg="6" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  {/* <a href="#" onClick={sendMembership}> */}
                  <div className="d-flex flex-column align-items-center">
                    <h5 className="text-muted">Total Organizations</h5>
                    <h4 className="mb-0">{orgCount}</h4>
                    {/* <small className="text-success ms-3">+ 57</small> */}
                  </div>
                  {/* </a> */}
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendMembers}>
                    <span>Current Month</span>
                    <div className="d-flex align-items-center">
                      <h4>Total Organizations: {currentMonthCount}</h4>
                      <small className="text-danger ms-3">- 12,562</small>
                    </div>
                     </a> 
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            <Col md="6" lg="6" className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <h5 className="text-muted">Organization Members: </h5>
                    <h4 className="mb-0"> {memberCount}</h4>
                  </div>
                  {/* </a> */}
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendMeeting}>
                    <span>Current Members of platform</span>
                    <div className="d-flex align-items-center">
                      <h4>Members: {currentMonthMemberCount}</h4>
                    </div>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          <Row>
            {/* <Col md="8" lg="8"> */}
            {/* <Card>
              <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, listPlugin]}
                //   events={
                //     userRole === "SUPER_ADMIN"
                //       ? [...events, ...tranning, ...metting]
                //       : [
                //           ...eventsChapter,
                //           ...tranningChapter,
                //           ...mettingChapter,
                //         ]
                //   }
                //   eventClick={handleEventClick}
                //   eventContent={renderEventContent} // Use the renderEventContent callback                  // eventRender={handleEventRender} // Use the eventRender callback
                  // eventColor='#378006' // Set the color for all events
                />
              </Card.Body>
            </Card> */}
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Row as="ul" className="list-unstyled mb-3"></Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Admin;
