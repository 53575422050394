import React from "react";

// auth
import Recoverpw from "../views/dashboard/auth/recoverpw";
import SignIn from "../views/dashboard/auth/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";
import ResetPass from "../views/dashboard/auth/resetPass";
import SignInType from "../views/dashboard/auth/sign-in-type";

export const SimpleRouter = [
  {
    path: "auth/recoverpw",
    element: <Recoverpw />,
  },
  {
    path: "auth/resetPass",
    element: <ResetPass />,
  },
  {
    path: "/auth/sign-in",
    element: <SignIn />,
  },
  {
    path: "/auth/sign-in-type",
    element: <SignInType />,
  },
  {
    path: "auth/sign-up",
    element: <SignUp />,
  },
];
