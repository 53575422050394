import React from "react";
import ChatLayout from "../layouts/dashboard/chat-layout";

export const ChatRouter = [
  {
    path: "chat",
    element: <ChatLayout />,
    
  },
];
