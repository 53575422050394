import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import EventRegister from "./eventRegister";
import profil1 from "../../../assets/images/user/1.png";

const EventPost = () => {
  const memberId = sessionStorage.getItem("member_id");
  const org_id = sessionStorage.getItem("org_id");
  const role_id = sessionStorage.getItem("role_id");

  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [showRegistrationDiv, setShowRegistrationDiv] = useState(false);
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");
  const fetchDataFromApi = async () => {
    try {
      let response;
      if (userRole == "ORG_ADMIN") {
        // If chapterId is null, call the /events API and filter by orgId
        response = await fetch(
          process.env.REACT_APP_API_URL + `/event/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Filter events by org_id and where cutoff registration date has not passed
          // Filter events by org_id and include events where the cutoff date is today or later
          const filteredEvents = data.processedEvents.filter((event) => {
            const eventDate = new Date(event.event_cutoff_reg_date);
            const today = new Date();

            // Compare only the year, month, and day for the cutoff date
            return (
              event.org_id == org_id &&
              (eventDate.getFullYear() > today.getFullYear() ||
                (eventDate.getFullYear() === today.getFullYear() &&
                  eventDate.getMonth() > today.getMonth()) ||
                (eventDate.getFullYear() === today.getFullYear() &&
                  eventDate.getMonth() === today.getMonth() &&
                  eventDate.getDate() >= today.getDate()))
            );
          });
          // Sort events by start date
          const sortedEvents = filteredEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        } else {
          // const errorData = await response.json();
        }
      } else {
        // If chapterId is not null, call the existing API with chapterId
        response = await fetch(
          process.env.REACT_APP_API_URL + `/event/events/org/${chapterId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          // Filter events where cutoff registration date has not passed
          // const filteredEvents = data.processedEvents.filter(
          //   (event) => new Date(event.event_cutoff_reg_date) >= new Date()
          // );

          const filteredEvents = data.processedEvents.filter((event) => {
            const eventDate = new Date(event.event_cutoff_reg_date);
            const today = new Date();

            // Compare only the year, month, and day
            return (
              eventDate.getFullYear() > today.getFullYear() ||
              (eventDate.getFullYear() === today.getFullYear() &&
                eventDate.getMonth() > today.getMonth()) ||
              (eventDate.getFullYear() === today.getFullYear() &&
                eventDate.getMonth() === today.getMonth() &&
                eventDate.getDate() >= today.getDate())
            );
          });

          // Sort events by start date
          const sortedEvents = filteredEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        } else {
          // const errorData = await response.json();
          // setError(`Error fetching events: ${JSON.stringify(errorData)}`);
        }
      }
    } catch (error) {
      console.error(
        "Error fetching events. Please try again later.",
        error.message
      );
      // setError("Error fetching events. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDataFromApi();

    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRegister = (index) => {
    setShowRegistrationDiv(true);
    console.log("cliked", showRegistrationDiv);
    setSelectedEventIndex(index);
  };

  const handleRegistrationClose = () => {
    setShowRegistrationDiv(false);
    setSelectedEventIndex(null);
  };

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour === 0) {
      formattedHour = 12; // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <>
      <Container>
        <Row>
          {events.length > 0 ? (
            <Col lg="12">
              {events.map((event, index) => (
                <Card>
                  <Card.Header className="p-2">
                    <div key={event.event_id} className="user-post-data">
                      <div className="d-flex justify-content-between">
                        <div className="me-3">
                          <img
                            loading="lazy"
                            className="rounded-circle avatar-60"
                            src={event.createrImageUrlwz || profil1}
                            alt=""
                          />
                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between align-events-center">
                            <div>
                              <div>
                                <h5 className="mb-0 d-inline-block">
                                  &nbsp;
                                  {event.role_id == 4 && (
                                    <>
                                      {event.last_name}
                                      <span className="badge rounded-pill text-bg-light mx-1">
                                        ADMIN
                                      </span>
                                    </>
                                  )}
                                  {event.role_id == 2 && (
                                    <>
                                      {`${event.first_name} ${event.last_name}`}
                                      <span className="badge rounded-pill text-bg-light mx-1">
                                        CHAPTER ADMIN
                                      </span>
                                    </>
                                  )}
                                  <p className="mt-1 mb-0 text-primary">
                                    {event.event_topic}
                                    <span
                                      className="badge rounded-pill mx-1"
                                      style={{
                                        backgroundColor: "blue",
                                        color: "white",
                                      }}
                                    >
                                      E
                                    </span>
                                  </p>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <div>
                      {event.imageUrls ? null : (
                        <div className="mt-3">
                          <p>Organizer: {event?.event_organizer_name}</p>
                          <p>
                            Cut Off Reg Date:{" "}
                            {(
                              new Date(event.event_cutoff_reg_date).getMonth() +
                              1
                            )
                              .toString()
                              .padStart(2, "0")}
                            /
                            {new Date(
                              event.event_cutoff_reg_date
                            ).getFullYear()}
                          </p>
                          <p>Mode: {event?.event_mode}</p>
                          <p>Place: {event?.event_place}</p>
                          <p>
                            Start Date:{" "}
                            {new Date(event.event_start_date)
                              .getDate()
                              .toString()
                              .padStart(2, "0")}
                            /
                            {(new Date(event.event_start_date).getMonth() + 1)
                              .toString()
                              .padStart(2, "0")}
                          </p>
                          <p>
                            End Date:{" "}
                            {(new Date(event.event_end_date).getMonth() + 1)
                              .toString()
                              .padStart(2, "0")}
                            /{new Date(event.event_end_date).getFullYear()}
                          </p>
                          <p>Start Time: {formatTime(event?.event_time_in)}</p>
                          <p>End Time: {formatTime(event?.event_time_out)}</p>
                          <p>Charges: {event?.event_charges}</p>
                          {event?.event_payment_link && (
                            <p>Payment link: {event?.event_payment_link}</p>
                          )}
                        </div>
                      )}
                      <div className="user-post">
                        {event.imageUrls ? (
                          <Container>
                            <Row>
                              <Col lg="12">
                                <Card>
                                  <Card.Body>
                                    <Row>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Organizer:{" "}
                                            {event?.event_organizer_name}
                                          </b>
                                        </p>
                                      </Col>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Cut Off Reg Date:
                                            {new Date(
                                              event.event_cutoff_reg_date
                                            )
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {(
                                              new Date(
                                                event.event_cutoff_reg_date
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {new Date(
                                              event.event_cutoff_reg_date
                                            ).getFullYear()}{" "}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <p>
                                          <b>Mode: {event?.event_mode}</b>
                                        </p>
                                      </Col>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            {event?.event_mode === "Virtual" ? (
                                              <>
                                                Event Link:
                                                <a
                                                  href={event?.event_link}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{ marginLeft: "5px" }} // Optional: Adds space between the label and the link
                                                >
                                                  Join Event
                                                </a>
                                              </>
                                            ) : (
                                              `Place: ${event?.event_place}`
                                            )}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Date:{" "}
                                            {new Date(event.event_start_date)
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {(
                                              new Date(
                                                event.event_start_date
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {new Date(
                                              event.event_start_date
                                            ).getFullYear()}{" "}
                                            to{" "}
                                            {new Date(event.event_end_date)
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {(
                                              new Date(
                                                event.event_end_date
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {new Date(
                                              event.event_end_date
                                            ).getFullYear()}
                                          </b>
                                        </p>
                                      </Col>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Time:{" "}
                                            {formatTime(event?.event_time_in)} -{" "}
                                            {formatTime(event?.event_time_out)}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Description: {event?.event_details}
                                          </b>
                                        </p>
                                      </Col>
                                      <Col lg="6">
                                        <p>
                                          <b>
                                            Charges: ₹ {event?.event_charges}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>
                                    {event?.event_payment_link && (
                                      <Row>
                                        <p>
                                          <b>
                                            Payment link:{" "}
                                            <a
                                              href={event?.event_payment_link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Click to pay
                                            </a>
                                          </b>
                                        </p>
                                      </Row>
                                    )}
                                    <div>
                                      <img
                                        loading="lazy"
                                        src={event.imageUrls}
                                        alt={event.event_details}
                                        className="img-fluid w-100"
                                      />
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <Card className="text-center mt-3">
                            <p
                              className="mb-0"
                              style={{
                                maxHeight: "3em",
                                fontSize: "15px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              No Event available!
                            </p>
                          </Card>
                        )}
                      </div>
                    </div>
                  </Card.Body>

                  <Card.Footer>
                    {!showRegistrationDiv && selectedEventIndex !== index && (
                      <>
                        <Row>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="primary"
                              onClick={() => handleRegister(index)}
                            >
                              <b>Register</b>
                            </Button>
                          </div>
                        </Row>
                      </>
                    )}
                    {showRegistrationDiv && selectedEventIndex === index && (
                      <Row>
                        <Col lg="12">
                          <div className="registration-div">
                            <EventRegister
                              onClose={handleRegistrationClose}
                              eventId={event.event_id}
                              event_payment_link={event.event_payment_link}
                              memberId={memberId}
                              createdBy={memberId}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Card.Footer>
                </Card>
              ))}
            </Col>
          ) : null
          // <Card className="text-center mt-3">
          //   <p
          //     className="mb-0"
          //     style={{
          //       maxHeight: "3em",
          //       fontSize: "15px",
          //       overflow: "hidden",
          //       textOverflow: "ellipsis",
          //     }}
          //   >
          //     No events available!
          //   </p>
          // </Card>
          }
        </Row>
      </Container>
    </>
  );
};

export default EventPost;
