import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Modal,
  Alert,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaTimes } from "react-icons/fa";

import user5 from "../../../assets/images/user/1.png";
import useOrgNaming from "../../../hooks/getNamingHook";
const MembersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchResults, alertMessage } = location.state || [];
  const [members, setMembers] = useState([]);
  const member_id = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setMembers(searchResults); // Update members with search results
    } else if (!alertMessage) {
      // Fetch the default member list only if alertMessage is not present
      fetchMembers();
    }
  }, [searchResults, alertMessage]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/members/members/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const filteredMembers = response.data.imageUrls.filter(
        (member) => member.member_id != member_id
      );
      setMembers(filteredMembers);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const [showviewProfileForm, setShowviewProfileForm] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = () => setShowdelete(true);
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit = () => setShowedit(false);
  const handleShowedit = () => setShowedit(true);

  const openviewProfileForm = (memberidd, userid) => {
    // if (member && member.member_id) {
    navigate("/dashboard/app/fprofile", {
      state: { state: memberidd },
    });
    //}
  };

  const closeviewProfileForm = () => {
    setShowviewProfileForm(false);
  };

  const closeAlert = () => {
    // Clear the alertMessage state to revert to default member list
    navigate("/dashboard/app/MembersList", { state: { alertMessage: null } });
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body className="p-3">
                  <h4><b>All Members</b></h4>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={12}>
              {alertMessage && (
                <Alert
                  variant="danger"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    border: "1px solid red",
                    color: "red",
                    width: "300px",
                    zIndex: 1000,
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <FaTimes
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "15px",
                      }}
                      onClick={closeAlert}
                    />
                  </div>
                  <Alert.Heading>Alert!</Alert.Heading>
                  <p>{alertMessage}</p>
                </Alert>
              )}

              {members.length > 0 ? (
                <Card>
                  <Card.Body
                    className="p-0"
                    style={{ overflowY: "auto", height: "80vh" }}
                  >
                    <div>
                      <Table className="rounded">
                        <thead className="bg-primary text-center text-white">
                          <tr>
                            <th></th>
                            <th>
                              <b>Name</b>
                            </th>
                            <th>
                              <b>
                                {getOrgNames?.alt_membership_category ||
                                  "Category"}
                              </b>
                            </th>

                            <th>
                              <b>{getOrgNames?.alt_chapter || "Chapter"}</b>
                            </th>

                            <th>
                              <b></b>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="text-center">
                          {members.map((member, index) => (
                            <React.Fragment key={member.member_id}>
                              <tr>
                                <td className="col-lg-1">
                                  {member.imageUrl ? (
                                    <img
                                      src={member.imageUrl}
                                      alt="story-img"
                                      className="rounded-circle avatar-40"
                                    />
                                  ) : (
                                    <img
                                      src={user5}
                                      alt="story-img"
                                      className="rounded-circle avatar-40"
                                    />
                                  )}
                                </td>
                                <td className="col-lg-3">
                                  <b>
                                    {member.first_name} {member.last_name}
                                  </b>
                                </td>
                                <td className="col-lg-2">
                                  <b>{member.member_category}</b>
                                </td>
                                <td className="col-lg-3">
                                  <b>{member.chapter_name}</b>
                                </td>
                                <td className="col-lg-3">
                                  <FaEye
                                    size={20}
                                    color="#007BFF"
                                    onClick={() =>
                                      openviewProfileForm(
                                        member.member_id,
                                        member.user_id
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                !alertMessage && <p>No Members Available</p>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MembersList;
