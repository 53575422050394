import React, { useState } from "react";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Form,
  Alert,
} from "react-bootstrap";

import { AiOutlineClose } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const CreateTraining = ({ onCancel }) => {
  const [trainingTitle, setTrainingTitle] = useState("");
  const [trainingDescription, setTrainingDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [trainerName, setTrainerName] = useState("");
  const [trainingStartDate, setTrainingStartDate] = useState("");
  const [trainingEndDate, setTrainingEndDate] = useState("");
  const [lastRegDate, setLastRegDate] = useState("");
  const [trainingStartTime, setTrainingStartTime] = useState("");
  const [trainingEndTime, setTrainingEndTime] = useState("");
  const [trainingMode, setTrainingMode] = useState("");
  const [trainingLink, setTrainingLink] = useState("");
  const [trainingPlace, setTrainingPlace] = useState("");
  const [trainingCharges, setTrainingCharges] = useState("");
  const [payment_link, setPayment_link] = useState("");

  // to show success message
  const [show, setShow] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFormAlert, setShowFormAlert] = useState(false);
  // Get FCM token from local storage
  const fcmToken = localStorage.getItem("fcmToken");

  // get logged in user id for created_by field
  const member_Id = sessionStorage.getItem("member_id");
  const chapterID = sessionStorage.getItem("chapter_id");
  const token = sessionStorage.getItem("token");

  const handleTrainingClick = (e) => {
    //e.preventDefault();
    if (
      !trainingTitle ||
      !trainingDescription ||
      !selectedImage ||
      !trainerName
    ) {
      setShowFormAlert(true); // Show the alert message
      return; // Exit the function early if any field is missing
    }

    // check all fields are filled up
    // if (!trainingTitle || !trainingDescription || !selectedImage || !trainerName) {
    //   alert('Please fill all the fields');
    //   return; // Exit the function early if any field is missing
    // }

    // Assuming you want to upload the selected image along with other data
    const formData = new FormData();
    formData.append("training_topic", trainingTitle);
    formData.append("training_details", trainingDescription);
    formData.append("train_img_path", selectedImage);
    formData.append("created_by", member_Id);
    formData.append("trainer_name", trainerName);
    formData.append("train_start_date", trainingStartDate);
    formData.append("train_end_date", trainingEndDate);
    formData.append("train_cutoff_reg_date", lastRegDate);
    formData.append("train_start_time", trainingStartTime);
    formData.append("train_end_time", trainingEndTime);
    formData.append("training_mode", trainingMode);
    formData.append("training_link", trainingLink);
    formData.append("training_place", trainingPlace);
    formData.append("training_charges", trainingCharges);
    formData.append("payment_link", payment_link);
    // Include FCM token in the request payload
    formData.append("fcmToken", fcmToken);

    // Now you can make a POST request to your backend to save the data
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/training/newtraining/schedule`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Training created successfully:", response.data);
        // Optionally, you can reset the form fields here
        setTrainingTitle("");
        setTrainingDescription("");
        setSelectedImage(null);
        setTrainingStartDate("");
        setTrainingEndDate("");
        setTrainingStartTime("");
        setTrainingEndTime("");
        setTrainingMode("");
        setTrainingPlace("");
        setTrainingLink("");
        setTrainerName("");
        setLastRegDate("");
        setTrainingCharges("");
        showSuccess();
        setTimeout(() => {
          onCancel(); // Call onCancel after a delay (e.g., 2000 milliseconds)
        }, 2000);
      })
      .catch((error) => {
        console.error("Error creating training:", error);
      })
      .finally(() => {
        // onCancel(); // Call onCancel regardless of success or failure
      });
  };

  const handleImageChange = (e) => {
    // This function will be triggered when the user selects an image
    setSelectedImage(e.target.files[0]);
  };

  // to display success message
  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  function closeFormAlert() {
    setShowFormAlert(false);
  }

  return (
    <div>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h3>Schedule New Training</h3>
                  <AiOutlineClose
                    onClick={onCancel}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                </Card.Header>

                <Card.Body>
                  <div className="form-items">
                    <Row>
                      <Col lg="6">
                        <Form.Group>
                          <div>
                            <Form.Label>Training Title :</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter title"
                              value={trainingTitle}
                              onChange={(e) => setTrainingTitle(e.target.value)}
                              required
                            />
                            {/* <input type='text' placeholder='enter title' value={trainingTitle} onChange={(e) => setTrainingTitle(e.target.value)} /> */}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Label>Trainer Name :</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter trainer name"
                          value={trainerName}
                          onChange={(e) => setTrainerName(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Form.Label>Training Start Date :</Form.Label>
                        <Form.Control
                          type="Date"
                          value={trainingStartDate}
                          onChange={(e) => setTrainingStartDate(e.target.value)}
                          min={
                            new Date(new Date().getTime() + 86400000)
                              .toISOString()
                              .split("T")[0]
                          } // Tomorrow's date
                        ></Form.Control>
                      </Col>
                      <Col lg="6">
                        <Form.Label>Training End Date :</Form.Label>
                        <Form.Control
                          type="Date"
                          value={trainingEndDate}
                          onChange={(e) => setTrainingEndDate(e.target.value)}
                          min={trainingStartDate}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Form.Label>Training Start Time :</Form.Label>
                        <Form.Control
                          type="Time"
                          value={trainingStartTime}
                          onChange={(e) => setTrainingStartTime(e.target.value)}
                        ></Form.Control>
                      </Col>
                      <Col lg="6">
                        <Form.Label>Training End Time :</Form.Label>
                        <Form.Control
                          type="Time"
                          value={trainingEndTime}
                          onChange={(e) => setTrainingEndTime(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <Form.Label>Training Charges :</Form.Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{
                                background: "#0d6efd",
                                color: "#fff",
                                fontSize: "12px",
                              }}
                            >
                              ₹
                            </span>
                          </div>
                          <Form.Control
                            type="number"
                            placeholder="Enter training charges"
                            value={trainingCharges}
                            onChange={(e) => setTrainingCharges(e.target.value)}
                          ></Form.Control>
                        </div>
                      </Col>
                      <Col lg="6">
                        <Form.Label>Registration Last Date :</Form.Label>
                        <Form.Control
                          type="Date"
                          value={lastRegDate}
                          onChange={(e) => setLastRegDate(e.target.value)}
                          min={new Date().toISOString().split("T")[0]}
                          max={
                            trainingStartDate
                              ? new Date(
                                  new Date(trainingStartDate).getTime() -
                                    86400000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Form.Group controlId="event_charges">
                          <Form.Label>
                            Payment link
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </Form.Label>
                          <div className="input-group">
                            <Form.Control
                              type="text"
                              placeholder="Enter Payment link"
                              name="event_charges"
                              value={payment_link}
                              onChange={(e) => setPayment_link(e.target.value)}
                              // required
                              className="form-control"
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid payment link
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <Form.Label>Training Mode:</Form.Label>
                        <Form.Select
                          value={trainingMode}
                          onChange={(e) => setTrainingMode(e.target.value)}
                        >
                          <option value="select">Select Option</option>
                          <option value="virtual">Virtual</option>
                          <option value="in-person">In-Person</option>
                        </Form.Select>
                      </Col>
                      {trainingMode && trainingMode === "virtual" && (
                        <Col lg="6">
                          <Form.Label>Training Link:</Form.Label>
                          <Form.Control
                            type="text"
                            value={trainingLink}
                            onChange={(e) => setTrainingLink(e.target.value)}
                          />
                        </Col>
                      )}

                      {trainingMode && trainingMode === "in-person" && (
                        <Col lg="6">
                          <Form.Label>Training Place:</Form.Label>
                          <Form.Control
                            type="text"
                            value={trainingPlace}
                            onChange={(e) => setTrainingPlace(e.target.value)}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col lg="6">
                        <Form.Label>Training Description :</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter description"
                          value={trainingDescription}
                          onChange={(e) =>
                            setTrainingDescription(e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                      <Col lg="6">
                        <Form.Label>Select Image</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleImageChange}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
                <Card.Footer style={{ textAlign: "right" }}>
                  <Button variant="primary" onClick={handleTrainingClick}>
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>{" "}
        </Container>

        <Alert
          show={showSuccessAlert}
          variant="success"
          onClose={closeSuccess}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid green",
            color: "green",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
              onClick={closeSuccess}
            />
          </div>
          <Alert.Heading>Success!</Alert.Heading>
          <p>Training Scheduled Successfully.</p>
        </Alert>
        <Alert
          show={showFormAlert}
          variant="success"
          onClose={closeFormAlert}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={closeFormAlert}
            />
          </div>
          <Alert.Heading>Alert!</Alert.Heading>
          <p>Please Fill In all the fields</p>
        </Alert>
      </div>
    </div>
  );
};

export default CreateTraining;
