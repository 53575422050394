import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";

const UserEditCreditials = () => {
  const [memberData, setMemberData] = useState({
    Email: "",
    oldEmail: "",
    oldPassword: "",
    newPassword: "",
    verifyPassword: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const userRole = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("userID");
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleClose = (memberidd) => {
    navigate("/dashboard/app/fprofile", { state: memberidd });
  };
  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `/chapters/chapters/members/${member_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { Email, Password } = response.data.result; // Assuming the API response provides email and password
        // setMemberData(response.data.result);
        setMemberData((prevData) => ({
          ...prevData,
          Email,
          oldEmail: Email,
          oldPassword: Password,
        }));
      } catch (error) {
        console.error("Error fetching member data:", error);
      }
    };
    fetchMemberData();
  }, [member_id, token]);

  const handleMemberDataChange = useCallback(
    (key, value) => {
      setMemberData((prevData) => ({ ...prevData, [key]: value }));
    },
    [setMemberData]
  );

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };
  const handlePasswordUpdate = async () => {
    if (submitting) {
      return; // Prevent multiple clicks while submitting
    }

    setSubmitting(true);

    // Email validation
    if (!isValidEmail(memberData.Email)) {
      setError("Please enter a valid email address.");
      setSubmitting(false);
      return;
    } else {
      setError(null);
    }

    // Password validation
    if (!memberData.newPassword || memberData.newPassword.length < 6) {
      setPasswordMatchError("Password must be at least 6 characters long.");
      setSubmitting(false);
      return;
    } else {
      setPasswordMatchError(null);
    }

    if (memberData.newPassword === memberData.oldPassword) {
      setPasswordMatchError(
        "New password must be different from the old password."
      );
      setSubmitting(false);
      return;
    } else {
      setPasswordMatchError(null);
    }

    if (memberData.newPassword !== memberData.verifyPassword) {
      setPasswordMatchError("New password and verify password do not match.");
      setSubmitting(false);
      return;
    } else {
      setPasswordMatchError(null);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/password/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            login_id: memberData.Email,
            password: memberData.newPassword,
            user_id: member_id,
          }),
        }
      );

      if (response.ok) {
        handleSuccess("Password updated successfully!");
      } else {
        console.error(`Password update failed with status ${response.status}`);
        setError("An error occurred during password update. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred during password update:", error);
      setError("An error occurred during password update. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (userRole === "SUPER_ADMIN") {
      navigate("/superAdminDashboard");
    } else if (userRole === "ORG_ADMIN" || userRole === "CHAPTER_ADMIN") {
      navigate("/adminDashboard");
    } else {
      // Handle other roles or default case
      navigate("/userDashboard");
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={12} style={{ height: "80vh" }}>
          <Card className="my-3 mx-2">
            <Card.Header className="d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Change Admin Login Creditials</h4>
              </div>
            </Card.Header>

            <Card.Body>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>
                        New Email<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={"email"}
                          className="form-control"
                          onChange={(e) =>
                            handleMemberDataChange("Email", e.target.value)
                          }
                          required
                        />
                      </div>
                      {passwordMatchError && (
                        <div className="text-danger">{passwordMatchError}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>
                        New Password<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          onChange={(e) =>
                            handleMemberDataChange(
                              "newPassword",
                              e.target.value
                            )
                          }
                          required
                        />
                        <span
                          className="position-absolute end-0 mx-2 top-50 translate-middle-y"
                          onClick={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                      {passwordMatchError && (
                        <div className="text-danger">{passwordMatchError}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>
                        Confirm Password<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          onChange={(e) =>
                            handleMemberDataChange(
                              "verifyPassword",
                              e.target.value
                            )
                          }
                          required
                        />
                        <span
                          className="position-absolute end-0 mx-2 top-50 translate-middle-y"
                          onClick={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                      {passwordMatchError && (
                        <div className="text-danger">{passwordMatchError}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>

            <Card.Footer className="d-flex justify-content-end">
              <Button
                variant="danger"
                className="mx-2"
                // onClick={(e) => { e.preventDefault(); handleClose(memberData.member_id); }}
                onClick={handleCancel}
              >
                <b>Cancel</b>
              </Button>
              <Button
                variant="primary"
                onClick={handlePasswordUpdate}
                disabled={submitting}
              >
                <b>{submitting ? "Submitting..." : "Submit"}</b>
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => handleClose(member_id)}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
        <div style={{ textAlign: "right" }}>
          <button
            class="btn btn-outline-success px-1 py-0"
            onClick={() => handleClose(member_id)}
          >
            <b>OK</b>
          </button>
        </div>
      </Alert>

      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </Container>
  );
};

export default UserEditCreditials;
