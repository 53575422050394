import React, { useState } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { FaTimes } from "react-icons/fa";

const DeleteReferral = ({ referralId, onClose }) => {

  const token = sessionStorage.getItem("token");
  const [alert, setAlert] = useState(null);

  const handleDelete = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/delete-referral/${referralId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete referral!');
      }

      // Set success alert
      setAlert({ type: 'success', message: 'Referral deleted successfully!' });
    } catch (error) {
      // Set error alert
      setAlert({ type: 'error', message: error.message });
    }
  };

  const closeAlert = () => {
    // Clear the alert
    setAlert(null);
    onClose();
  };

  return (
    <div>
      <Card className='mx-4'>
        {/* <Card.Header>Delete Referral</Card.Header> */}
        <Card.Body><b>Are you sure, you want to delete this referral?</b></Card.Body>
        <Card.Footer className="text-end">
          <button className='btn btn-secondary me-2' onClick={onClose}>Cancel</button>
          <button className='btn btn-danger' onClick={handleDelete}>Delete</button>
        </Card.Footer>
      </Card>

      {/* Display Alert */}
      {alert && (
        <Alert
          variant={alert.type === 'success' ? 'success' : 'danger'}
          // onClose={closeAlert}
          // dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: alert.type === 'success' ? "green" : "red",
            border: alert.type === 'success' ? "1px solid green" : "1px solid red",
            backgroundColor: "white",
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FaTimes
              style={{ cursor: 'pointer', color: 'black', fontSize: "15px" }}
              onClick={closeAlert}
            />
          </div>
          <Alert.Heading>{alert.type === 'success' ? 'Deleted!' : 'Error!'}</Alert.Heading>
          <p>{alert.message}</p>
        </Alert>
      )}
    </div>
  );
};

export default DeleteReferral;
