import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import MeetingComment from "./meetingComment";
import DeleteMeeting from "./deleteMeeting";
import EditMeeting from "./editMeeting";
import useOrgNaming from "../../../hooks/getNamingHook";
const AllMeetingList = ({ uId, mId, state }) => {
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location for all meetings: ", location);
  const [userMeetings, setUserMeetings] = useState([]);
  const [memberMeetings, setMemberMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [showPreviousMeetings, setShowPreviousMeetings] = useState(false);

  useEffect(() => {
    const fetchUserMeetings = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/meetings/data/total-meetings/org/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add your authentication token here
              "Content-Type": "application/json", // Adjust the content type if needed
            },
          }
        );
        if (response.status === 502) {
          // Don't throw error for 502 status code
          console.error(
            "Bad Gateway error occurred while fetching sent referrals"
          );
          return;
        }
        if (!response.ok) {
          throw Error("Failed to fetch user meetings");
        }
        const data = await response.json();
        if (data && data.results) {
          setUserMeetings(data.results);
        }
      } catch (err) {
        console.error("Error fetching user meetings:", err);
        // setError(
        //   "An error occurred while fetching user meetings. Please try again later."
        // );
      }
    };

    fetchUserMeetings();
  }, []);

  const alertMessage = location.state && location.state.alertMessage;
  const searchResults = location.state && location.state.searchResults;

  const combinedMeetings =
    searchResults && searchResults.length > 0
      ? [...searchResults]
      : [...userMeetings];

  const meetingsToDisplay = combinedMeetings.slice().sort((a, b) => {
    const dateA = new Date(a.meeting_date);
    const dateB = new Date(b.meeting_date);

    return dateA - dateB;
  });

  // console.log('sorted meetings: ', meetingsToDisplay);

  const currentDate = new Date();
  const previousMeetings = meetingsToDisplay.filter(
    (meetings) => new Date(meetings.meeting_date) < currentDate
  );
  const upcomingMeetings = meetingsToDisplay.filter(
    (meetings) => new Date(meetings.meeting_date) >= currentDate
  );

  const reversedPreviousMeetings = [...previousMeetings].sort((a, b) => {
    const dateA = new Date(b.meeting_date);
    const dateB = new Date(a.meeting_date);
    return dateA - dateB;
  });

  // console.log('previous meetings: ', previousMeetings);

  const filteredMeetings =
    searchResults && searchResults.length > 0
      ? searchResults
      : showPreviousMeetings
      ? reversedPreviousMeetings
      : upcomingMeetings;

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const clearError = () => {
    setError(null);
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  // Function to convert time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };
  const toggleMeetings = () => {
    setShowPreviousMeetings(!showPreviousMeetings);
  };
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <b>{getOrgNames?.alt_meeting || "Meetings"}</b>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body
                  className="p-0"
                  style={{ overflowY: "auto", height: "80vh" }}
                >
                  <div>
                    {(!searchResults || searchResults.length === 0) && (
                      <div
                        onClick={() =>
                          setShowPreviousMeetings(!showPreviousMeetings)
                        }
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        className="text-center text-primary pb-1"
                      >
                        <u onClick={toggleMeetings}>
                          {showPreviousMeetings
                            ? `View Upcoming ${
                                getOrgNames?.alt_meeting || "Meeting"
                              }`
                            : `View Previous ${
                                getOrgNames?.alt_meeting || "Meeting"
                              }`}
                        </u>
                      </div>
                    )}
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Date</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          <th>
                            <b>Subject</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {meetingsToDisplay.length > 0 ? (
                        <tbody>
                          {filteredMeetings.slice().map((meeting, index) => (
                            <React.Fragment key={meeting.meeting_id}>
                              <tr>
                                <td className="col-lg-3">
                                  <b>
                                    {new Date(meeting.meeting_date)
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {(
                                      new Date(
                                        meeting.meeting_date
                                      ).getMonth() + 1
                                    )
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {new Date(
                                      meeting.meeting_date
                                    ).getFullYear()}
                                  </b>
                                </td>
                                <td className="col-lg-3">
                                  <b>
                                    {meeting.meeting_status === "Schedule"
                                      ? "Scheduled"
                                      : meeting.meeting_status}
                                  </b>
                                </td>
                                <td className="col-lg-3">
                                  <b>{meeting.meeting_subject}</b>
                                </td>
                                <td className="col-lg-3">
                                  <div className="d-lg-none">
                                    <Dropdown
                                      show={showActionsDropdown === index}
                                      onSelect={() =>
                                        setShowActionsDropdown(null)
                                      }
                                    >
                                      <Dropdown.Toggle
                                        variant="link"
                                        id={`dropdown-basic-${index}`}
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "skyblue",
                                        }}
                                      >
                                        <FaEllipsisV
                                          className="ellipsis-icon"
                                          onClick={() =>
                                            toggleActionsDropdown(index)
                                          }
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        >
                                          <FaEye className="viewicon" /> View
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <FaEye
                                      className="viewicon"
                                      size={15}
                                      color="#007BFF"
                                      onClick={() => toggleEyeDetails(index)}
                                    />
                                    &nbsp;&nbsp;
                                  </div>
                                </td>
                              </tr>
                              {eyeClickedRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEyeClickedRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <p>
                                          {meeting.meeting_type !==
                                            "Chapter" && (
                                            <p>
                                              <b>
                                                Members:{" "}
                                                {meeting.participant_name}
                                              </b>
                                            </p>
                                          )}
                                          {meeting.meeting_type ===
                                            "Chapter" && (
                                            <p>
                                              <b>
                                                {getOrgNames?.alt_chapter ||
                                                  "Chapter"}{" "}
                                                {getOrgNames?.alt_meeting ||
                                                  "Meetings"}
                                              </b>
                                            </p>
                                          )}
                                        </p>
                                        <p>
                                          <b>
                                            Created By: {meeting.organizer_name}
                                          </b>
                                        </p>
                                        <p>
                                          <b>Mode: {meeting.meeting_mode}</b>
                                        </p>
                                        <p>
                                          <b>
                                            {meeting.meeting_mode ===
                                            "Virtual" ? (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meetings"}{" "}
                                                  Link:{" "}
                                                  {meeting.meeting_link ||
                                                    "N/A"}
                                                </p>
                                              </div>
                                            ) : (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meetings"}{" "}
                                                  Place:{" "}
                                                  {meeting.meeting_place ||
                                                    "N/A"}
                                                </p>
                                              </div>
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            {getOrgNames?.alt_meeting ||
                                              "Meetings"}{" "}
                                            Link: meeting.meeting_link
                                          </b>
                                        </p>
                                      </div>
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Start Time:{" "}
                                            {formatTime(
                                              meeting.meeting_start_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            End Time:
                                            {formatTime(
                                              meeting.meeting_end_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Description: {meeting.meeting_desc}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            {getOrgNames?.alt_meeting ||
                                              "Meetings"}{" "}
                                            Charges: {meeting.meeting_charges}
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {commentRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setCommentRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <MeetingComment
                                      meetingId={meeting.meeting_id}
                                    />
                                  </td>
                                </tr>
                              )}
                              {editRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEditRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <EditMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setEditRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                              {deleteRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setDeleteRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <DeleteMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setDeleteRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ) : (
                        <p>
                          No {getOrgNames?.alt_meeting || "meetings"} scheduled.
                        </p>
                      )}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Error Alert */}

      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}

      {alertMessage && (
        <Alert
          variant="danger"
          onClose={() =>
            navigate("/dashboard/app/AllMeetingList", {
              state: { alertMessage: null },
            })
          }
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() =>
                navigate("/dashboard/app/AllMeetingList", {
                  state: { alertMessage: null },
                })
              }
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default AllMeetingList;
