// process.env.REACT_APP_API_URL + `/chapters/chapters/members/${userId}`,
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
//image../../../../assets/images/user/1.png
import user1 from "../../../assets/images/user/profile-edit.png";
import useOrgNaming from "../../../hooks/getNamingHook";
const UserProfileEdit = () => {
  const [memberData, setMemberData] = useState(null);
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const userID = sessionStorage.getItem("userID");
  const org_id = sessionStorage.getItem("org_id");
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [databaseImage, setDatabaseImage] = useState(""); // Set the database image URL here
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [databaseLogo, setDatabaseLogo] = useState(""); // Set the database image URL here
  const [errorMessage, setErrorMessage] = useState("");
  const { getOrgNames } = useOrgNaming(org_id, token);

  const navigate = useNavigate();
  const navigateToFriendProfile = (memberId, user_id) => {
    // Navigate to the friend profile page with memberId
    navigate("/dashboard/app/fprofile", {
      state: { state: memberId },
    });
  };
  // const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/members/members/${member_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Image URL:", response.data.imageUrl);
        // const imagepath = response.data.imageUrl
        setMemberData(response.data.result);
        setDatabaseImage(response.data.imageUrl);
        setDatabaseLogo(response.data.imagelogoUrl);
      } catch (error) {
        console.error("Error fetching member data:", error);
      }
    };
    fetchMemberData();
  }, [member_id, token]);

  const handleClose = (memberidd) => {
    navigate("/userposts");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        // Call the image update API here
        updateMemberImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Determine which image to display
  const displayImage = selectedImage || databaseImage;

  const updateMemberImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("profileImage", file);
      formData.append("fieldName", "profileImage");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members/upload-image/${member_id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        // Handle non-successful response
        console.error("Error updating member image:", response.statusText);
        // Show error message to the user
        // setError('There was a problem with the update request');
      } else {
        // Handle the successful response
        const responseData = await response.json();
      }
    } catch (error) {
      // Handle errors
      console.error("Error updating member image:", error);
      // Show error message to the user
      // setError('There was a problem with the update request');
      // ... rest of the code to handle errors
    }
  };

  //handel logo change function
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Handle the selected logo
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
        updateMemberlogo(file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Determine which logo to display
  const displayLogo = selectedLogo || databaseLogo;

  const updateMemberlogo = async (file) => {
    try {
      console.log();
      const formData = new FormData();
      formData.append("logoImage", file);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/members/upload-logo/${member_id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the successful response
    } catch (error) {
      // Handle errors
      console.error("Error updating member image:", error);

      // Show error message to the user
      // setError('There was a problem with the update request');

      // ... rest of the code to handle errors
    }
  };

  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  const handleMemberDataChange = useCallback(
    (key, value) => {
      // Check if the key is 'dob' (Date of Birth) or 'is_active'
      if (key === "dob") {
        // Ensure the date is in the correct format (yyyy-mm-dd)
        const formattedDate = new Date(value).toISOString().split("T")[0];
        setMemberData((prevData) => ({ ...prevData, [key]: formattedDate }));
      } else if (key === "is_active") {
        // Convert string value to boolean
        const updatedValue = value === "true";
        setMemberData((prevData) => ({ ...prevData, [key]: updatedValue }));
      } else {
        // For other fields, update normally
        setMemberData((prevData) => ({ ...prevData, [key]: value }));
      }
    },
    [setMemberData]
  );

  const handelnav = () => {
    navigate("/dashboard/app/fprofile", { state: member_id });
  };

  const updateMemberData = async (updatedData, e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      // Update member data without image
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/members/members/${member_id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Handle success, e.g., show a success message or redirect to another page
      handleSuccess("Member data updated successfully");
      console.log("Member data updated:", response.data);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      // Show error message to the user
      setError("There was a problem with the update request");
      // navigate("/dashboard/app/fprofile", { state: member_id });
    }
  };

  return (
    <>
      <Container>
        <Tab.Container defaultActiveKey="first">
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body className="p-0">
                  <div>
                    <Nav
                      as="ul"
                      variant="pills"
                      className="iq-edit-profile row"
                    >
                      <Nav.Item as="li" className="col-md-3 p-0">
                        <Nav.Link eventKey="first" role="button">
                          Personal Information
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-md-3 p-0">
                        <Nav.Link eventKey="second" role="button">
                          Corporate Information
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="third" role="button">
                                                    Change Password
                                                </Nav.Link>
                                            </Nav.Item> */}
                      <Nav.Item as="li" className="col-md-3 p-0">
                        <Nav.Link eventKey="third" role="button">
                          Membership
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              {/* <div className="iq-edit-list-data"> */}
              <Tab.Content style={{ overflowX: "scroll" }}>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Personal Information</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {memberData && (
                        <Form encType="multipart/form-data">
                          <Form.Group className="form-group align-items-center">
                            <Col md="12">
                              <div className="profile-img-edit">
                                {displayImage ? (
                                  <li>
                                    {/* Display the selected or database logo */}
                                    <img
                                      className="profile-pic"
                                      src={displayImage}
                                      alt="company-logo"
                                    />
                                  </li>
                                ) : (
                                  <li>
                                    {/* Display the default image when no logo is available */}
                                    <img
                                      className="profile-pic img-fluid"
                                      src={user1}
                                      alt="default-user"
                                    />
                                  </li>
                                )}
                                <li>
                                  {/* <div className="p-image"> */}
                                  <label className="p-image  material-symbols-outlined">
                                    edit
                                    <input
                                      name="profileImage"
                                      className="file-upload"
                                      type="file"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </label>
                                  {/* </div> */}
                                </li>
                              </div>
                            </Col>
                          </Form.Group>
                          <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="fname"
                                className="form-label"
                              >
                                First Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.first_name}
                                id="fname"
                                placeholder="Enter your first name"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "first_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="lname"
                                className="form-label"
                              >
                                Last Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.last_name}
                                id="lname"
                                placeholder="Enter your last name"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "last_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label d-block">
                                Gender:
                              </Form.Label>
                              <Form.Check className="form-check form-check-inline">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="radio"
                                  name="genderOptions"
                                  id="maleOption"
                                  value="Male"
                                  checked={memberData.gender === "Male"}
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "gender",
                                      e.target.value
                                    )
                                  }
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="maleOption"
                                >
                                  Male
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check className="form-check form-check-inline">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="radio"
                                  name="genderOptions"
                                  id="femaleOption"
                                  value="Female"
                                  checked={memberData.gender === "Female"}
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "gender",
                                      e.target.value
                                    )
                                  }
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="femaleOption"
                                >
                                  Female
                                </Form.Check.Label>
                              </Form.Check>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Date Of Birth:
                              </Form.Label>
                              <Form.Control
                                type="date"
                                className="form-control"
                                id="dob"
                                value={
                                  memberData.dob
                                    ? new Date(memberData.dob)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                onChange={(e) =>
                                  handleMemberDataChange("dob", e.target.value)
                                } // Add this onChange handler
                                placeholder="Enter Date of Birth..."
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Email Id:
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className="form-control"
                                id="age"
                                value={memberData.email_id}
                                placeholder="Enter email"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "email_id",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Contact No.:
                              </Form.Label>
                              <Form.Control
                                type="tel"
                                pattern="^\d{10}$"
                                maxLength="10"
                                className="form-control"
                                id="age"
                                value={memberData.contact_no}
                                placeholder="Enter Contact No"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "contact_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Country:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.country}
                                placeholder="Enter Country"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "country",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                State:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.state}
                                placeholder="Enter State"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "state",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                City:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.city}
                                placeholder="Enter City"
                                onChange={(e) =>
                                  handleMemberDataChange("city", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Zip Code:
                              </Form.Label>
                              <Form.Control
                                type="number"
                                className="form-control"
                                id="age"
                                value={memberData.zip_code}
                                placeholder="Enter Zip Code"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "zip_code",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                              <Form.Label className="form-label">
                                Address:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.street_address}
                                placeholder="Enter Address"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "street_address",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Row>
                          {/* <div className="d-flex justify-content-end p-1">
                            <Button
                              type="reset"
                              className="btn bg-danger me-2"
                              onClick={() => handleClose(memberData.member_id)}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              onClick={(e) => updateMemberData(memberData, e)}
                            >
                              Submit
                            </Button>
                          </div> */}
                        </Form>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <div className="d-flex justify-content-end">
                        <Button
                          type="reset"
                          variant="danger"
                          className="btn mx-2"
                          onClick={() => handleClose(memberData.member_id)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => updateMemberData(memberData, e)}
                        >
                          Submit
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Corporate Information</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {memberData && (
                        <Form encType="multipart/form-data">
                          <Form.Group className="form-group align-items-center">
                            <Col md="12">
                              <div className="profile-img-edit">
                                {displayLogo ? (
                                  <li>
                                    {/* Display the selected or database logo */}
                                    <img
                                      className="profile-pic"
                                      src={displayLogo}
                                      alt="company-logo"
                                    />
                                  </li>
                                ) : (
                                  <li>
                                    {/* Display the default image when no logo is available */}
                                    <img
                                      className="profile-pic img-fluid"
                                      src={user1}
                                      alt="default-user"
                                    />
                                  </li>
                                )}{" "}
                                <li>
                                  <div className="p-image material-symbols-outlined">
                                    <label className=" material-symbols-outlined">
                                      edit
                                      <input
                                        name="companyLogo"
                                        className="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleLogoChange}
                                      />
                                    </label>
                                  </div>
                                </li>
                              </div>
                            </Col>
                          </Form.Group>
                          <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="fname"
                                className="form-label"
                              >
                                Company Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_name}
                                id="fname"
                                placeholder="Enter Company Name"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="lname"
                                className="form-label"
                              >
                                Company Title:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_title}
                                id="lname"
                                placeholder="Enter Company Title"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_title",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Company LinkedIn URL:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_linkedIn_url}
                                id="dob"
                                placeholder="Enter LinkedIn URL"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_linkedIn_url",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Company Email:
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className="form-control"
                                value={memberData.company_email}
                                id="email"
                                placeholder="Enter Email"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_email",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company Website:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_website}
                                id="company_website"
                                placeholder="Enter Website"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_website",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company Size:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_size}
                                id="company_size"
                                placeholder="Enter Company Size"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_size",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company Contact No.:
                              </Form.Label>
                              <Form.Control
                                type="tel"
                                pattern="^\d{10}$"
                                maxLength="10"
                                className="form-control"
                                value={memberData.company_contact_no}
                                id="company_contact_no"
                                placeholder="Enter Contact No"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_contact_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company Country:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_country}
                                id="country"
                                placeholder="Enter Country"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_country",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company State:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_state}
                                id="state"
                                placeholder="Enter State"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_state",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company City:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_city}
                                id="city"
                                placeholder="Enter City"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_city",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Company Zip Code:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_zip_code}
                                id="zip_code"
                                placeholder="Enter Zip Code"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_zip_code",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                              <Form.Label className="form-label">
                                Company Address:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={memberData.company_address}
                                id="street_address"
                                placeholder="Enter Address"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "company_address",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Row>
                          {/* <div className="d-flex justify-content-end p-1">
                            <Button
                              type="reset"
                              className="btn bg-danger me-2"
                              onClick={() => handleClose(memberData.member_id)}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              onClick={(e) => updateMemberData(memberData, e)}
                            >
                              Submit
                            </Button>
                          </div> */}
                        </Form>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <div className="d-flex justify-content-end">
                        <Button
                          type="reset"
                          variant="danger"
                          className="btn mx-2"
                          onClick={() => handleClose(memberData.member_id)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => updateMemberData(memberData, e)}
                        >
                          Submit
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Tab.Pane>

                <Tab.Pane eventKey="third" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Membership</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {memberData && (
                        <Form encType="multipart/form-data">
                          <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                {getOrgNames?.alt_membership_plan ||
                                  "Membership Plan "}
                                :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.membership_plan}
                                placeholder="Enter membership plan"
                                disabled
                                readOnly // Add readOnly attribute here
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "membership_plan",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                {getOrgNames?.alt_membership_category ||
                                  " Member Category"}
                                :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.member_category}
                                placeholder="Enter member category"
                                disabled
                                readOnly // Add readOnly attribute here
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "member_category",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Member Type:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.member_type}
                                placeholder="Enter member type"
                                disabled
                                readOnly // Add readOnly attribute here
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "member_type",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                {getOrgNames?.alt_chapter || "Chapter"} Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="age"
                                value={memberData.chapter_name}
                                placeholder="Chapter Name"
                                disabled
                                readOnly // Add readOnly attribute here
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "chapter_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Membership Start Date:
                              </Form.Label>
                              <Form.Control
                                type="date"
                                className="form-control"
                                disabled
                                readOnly // Add readOnly attribute here
                                value={
                                  memberData.membership_start_date
                                    ? new Date(memberData.membership_start_date)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                id="cno"
                                placeholder="Enter membership Start date"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "membership_start_date",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>

                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="email"
                                className="form-label"
                              >
                                Membership End Date:
                              </Form.Label>
                              <Form.Control
                                type="date"
                                className="form-control"
                                disabled
                                value={
                                  memberData.membership_end_date
                                    ? new Date(memberData.membership_end_date)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                id="email"
                                placeholder="Enter membership End date"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "membership_end_date",
                                    e.target.value
                                  )
                                }
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="url" className="form-label">
                                Membership Renewal Date:
                              </Form.Label>
                              <Form.Control
                                type="date"
                                className="form-control"
                                disabled
                                value={
                                  memberData.membership_renewal_date
                                    ? new Date(
                                        memberData.membership_renewal_date
                                      )
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                id="url"
                                placeholder="Enter membership Renewal date"
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "membership_renewal_date",
                                    e.target.value
                                  )
                                }
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label d-block">
                                Member Status:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="member_status"
                                disabled
                                value={memberData.member_status}
                                placeholder="Enter member member_status"
                                readOnly // Add readOnly attribute here
                                onChange={(e) =>
                                  handleMemberDataChange(
                                    "member_status",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Row>
                          {/* <div className="d-flex justify-content-end p-1">
                            <Button
                              type="reset"
                              className="btn bg-danger me-2"
                              onClick={() => handleClose(memberData.member_id)}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              onClick={(e) => updateMemberData(memberData, e)}
                            >
                              Submit
                            </Button>
                          </div> */}
                        </Form>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <div className="d-flex justify-content-end">
                        <Button
                          type="reset"
                          variant="danger"
                          className=" mx-2"
                          onClick={() => handleClose(memberData.member_id)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => updateMemberData(memberData, e)}
                        >
                          Submit
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
              {/* </div> */}
            </Col>
          </Row>
        </Tab.Container>
      </Container>

      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
        <div style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            onClick={() => navigateToFriendProfile(member_id, userID)}
          >
            OK
          </button>
        </div>
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default UserProfileEdit;
