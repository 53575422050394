import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import DataTable from "./recivedDataTable";




const OrgThankYouNoteRecieved = () => {
  const [thankYouNotes, setThankYouNotes] = useState([]);
  const member_id = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");

  //To fetch the thank you note data 
  const fetchThankYouNotes = async () => {
    try {  
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/data/get-ThankyouNote`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch Thank You Note data");
      }
  
      const data = await response.json();
  
      // Filter the thank you notes based on user role
    const filteredThankYouNotes = data.results.filter(note => {
      if (userRole === 'ORG_ADMIN') {
          // For ORG_ADMIN: Check if the org_id matches
          return org_id == note.referred_by_org_id;
      } else if (userRole === 'CHAPTER_ADMIN') {
          // For CHAPTER_ADMIN: Check if the chapterId matches
          return chapterId == note.referred_by_memberChapterId;
      } else if (userRole === 'MEMBER') {
          // For MEMBER: Check if the member_id matches
          return member_id == note.referred_by;
      }
    })
    setThankYouNotes(filteredThankYouNotes);

    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  
useEffect(() => {
// Call the function to fetch the Thank You Note data
fetchThankYouNotes();
}, []); 
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card>
                <Card.Header>
                  <h4><b>Thank You Notes Received</b></h4>
                </Card.Header>
                <Card.Body>
                  <DataTable 
                  thankYouNotes={thankYouNotes}/>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OrgThankYouNoteRecieved;
