import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaEllipsisV,
  FaPowerOff,
  FaTimes,
} from "react-icons/fa";
import useOrgNaming from "../../../hooks/getNamingHook";
// import { useOrgNamingContext } from '../../../hooks/OrgNamingContext';
const NamingConvention = () => {
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");
  const [organizationData, setOrganizationData] = useState({
    alt_chapter: "",
    alt_meeting: "",
    alt_membership_plan: "",
    alt_membership_category: "",
    // alt_event: "",
    created_by: memberId,
    org_id: org_id,
  });
  const { getOrgNames, refreshOrgNames } = useOrgNaming(org_id, token);
  // const { refreshOrgNames } = useOrgNamingContext();

  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedRequest, setselectedRequest] = useState(null);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [showviewProfileForm, setShowviewProfileForm] = useState(false);
  const [editRow, setEditRow] = useState(null); // Manages row state for commenting
  const [deleteRow, setDeleteRow] = useState(null); // Manages row state for deleting

  // Handle document click to close expanded rows if clicked outside
  const handleDocumentClick = (event) => {
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // // Only close the form if the user clicked outside the edit form.
    if (!clickedInsideExpandedRow && !event.target.closest(".form-control")) {
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [editRow, deleteRow]);

  // Toggle visibility of comment section
  const toggleEditSection = (getOrgNames) => {
    setEditRow((prev) => (prev === getOrgNames ? null : getOrgNames));
    setselectedRequest(getOrgNames);
    setselectedRequestId(getOrgNames.org_alt_id);
  };

  // Toggle visibility of delete section
  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };
  const handleUpdate = async (e) => {
    try {
      setLoadingSubmit(true);
       // Check if all fields are empty
       const { alt_chapter, alt_meeting, alt_membership_plan, alt_membership_category } = selectedRequest;
       if (!alt_chapter && !alt_meeting && !alt_membership_plan && !alt_membership_category) {
           setError("At least one input field must be filled.");
           setLoadingSubmit(false);
           return;
       }
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/org/updateOrgNaming/${selectedRequestId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            alt_chapter: selectedRequest.alt_chapter,
            alt_meeting: selectedRequest.alt_meeting,
            alt_membership_plan: selectedRequest.alt_membership_plan,
            alt_membership_category: selectedRequest.alt_membership_category,
            alt_event: selectedRequest.alt_event,
            updated_by: memberId,
          }),
        }
      );

      if (response.ok) {
        handleSuccess("Organization naming Updated successfully");
        setEditRow(null);
        refreshOrgNames();
      } else {
        const errorData = await response.json();
        setError(
          errorData.error ||
            "Error updating Organization. Please try again later."
        );
      }
    } catch (error) {
      setError("Error updating Organization. Please try again later.");
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleClickTest = (e) => {
    console.log("button clicked");
  };

  const closeviewProfileForm = () => {
    setShowviewProfileForm(false);
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setselectedRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

     // Check if all fields are empty
     const { alt_chapter, alt_meeting, alt_membership_plan, alt_membership_category } = organizationData;
     if (!alt_chapter && !alt_meeting && !alt_membership_plan && !alt_membership_category) {
         setError("At least one input field must be filled.");
         return;
     }

    // Check if form is valid
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    try {
      setLoadingSubmit(true); // Start loading state

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrgNaming",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(organizationData),
        }
      );

      const responseData = await response.json();

      if (response.status === 200) {
        handleSuccess("Organization naming created successfully");
        resetForm();
        refreshOrgNames();
      } else if (response.status === 400) {
        handleSuccess(
          "Naming record already exists for this organization. Please update it from Alternative Name list."
        );
        resetForm();
      } else {
        setError(
          responseData.error ||
            "Error creating Organization. Please try again later."
        );
      }
    } catch (error) {
      setError("Error creating Organization. Please try again later.");
    } finally {
      setLoadingSubmit(false); // Stop loading state
    }
  };

  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const resetForm = () => {
    setOrganizationData({
      alt_chapter: "",
      alt_meeting: "",
      alt_membership_plan: "",
      alt_membership_category: "",
      //   alt_event: "",
      created_by: memberId,
      org_id: org_id,
    });

    setValidated(false);
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title">
                    <b>Naming Convention for Organization</b>
                  </h4>
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="alt_chapter_event">
                          <Form.Label>Alternative For Chapter Name </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter alternative chapter name"
                            name="alt_chapter"
                            value={organizationData.alt_chapter}
                            onChange={handleChange}
                            required
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please enter the alternative chapter name.
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="alt_meeting">
                          <Form.Label>Alternative For Meeting Name </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter alternative meeting name"
                            name="alt_meeting"
                            value={organizationData.alt_meeting}
                            onChange={handleChange}
                            required
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please enter the alternative meeting name.
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="alt_membership_plan">
                          <Form.Label>
                            Alternative For Membership Plan Name{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter alternative membership plan name"
                            name="alt_membership_plan"
                            value={organizationData.alt_membership_plan}
                            onChange={handleChange}
                            required
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please enter the alternative membership plan name.
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="alt_membership_category">
                          <Form.Label>
                            Alternative For Membership Category Name{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter alternative membership category name"
                            name="alt_membership_category"
                            value={organizationData.alt_membership_category}
                            onChange={handleChange}
                            required
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please enter the alternative membership category
                            name.
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        {/* <Form.Group controlId="alt_event">
                          <Form.Label>Alternative For Event Name </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter alternative event name"
                            name="alt_event"
                            value={organizationData.alt_event}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the alternative name for event.
                          </Form.Control.Feedback>
                        </Form.Group> */}
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  <Button
                    style={{
                      backgroundColor: "#ff9b8a",
                      color: "white",
                      borderColor: "#ff9b8a",
                    }}
                    className="mr-2"
                    variant="outline-secondary"
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loadingSubmit}
                  >
                    {loadingSubmit ? "Creating..." : "Create"}
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="iq-header-title">
                    {/* {chapterMembers.length > 0 && ( */}
                    <h4 className="card-title">Alternative Name List</h4>
                    {/* )} */}
                  </div>
                </Card.Header>

                <Card.Body
                  className="p-0"
                  style={{ overflowY: "auto", height: "80vh" }}
                >
                  <Table className="rounded" style={{ width: "100%" }}>
                    <thead
                      className="bg-primary text-white"
                      style={{ width: "100%" }}
                    >
                      <tr>
                        <th>
                          <b>Chapter</b>
                        </th>
                        <th>
                          <b> Meeting</b>
                        </th>
                        <th>
                          <b> Membership Plan</b>
                        </th>
                        <th>
                          <b> Membership Catogory</b>
                        </th>
                        <th>
                          <b> </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {chapterMembers.map((chaptermember, index) => ( */}
                      {/* <React.Fragment key={index}> */}
                      <React.Fragment>
                        <tr>
                          <td className="col-sm-2">
                            <b>{getOrgNames?.alt_chapter}</b>
                          </td>
                          <td className="col-sm-3">
                            <b>{getOrgNames?.alt_meeting}</b>
                          </td>
                          <td className="col-sm-4">
                            <b>{getOrgNames?.alt_membership_plan}</b>
                          </td>
                          <td className="col-sm-4">
                            <b>{getOrgNames?.alt_membership_category}</b>
                          </td>

                          <td className="col-lg-3">
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="link"
                                  id="dropdown-basic"
                                >
                                  {/* Horizontal three-dot icon */}
                                  <FaEllipsisV size={20} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      toggleEditSection(getOrgNames)
                                    }
                                  >
                                    {/* Edit icon */}
                                    <FaEdit
                                      size={15}
                                      style={{
                                        color: "#007BFF",
                                      }}
                                    />
                                    &nbsp; Edit
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                        {editRow === getOrgNames && (
                          <tr className="expanded-row">
                            <td colSpan="8" style={{ position: "relative" }}>
                              {/* Close Icon */}
                              <div
                                className="close-icon"
                                onClick={() => setEditRow(null)}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "15px",
                                  cursor: "pointer",
                                }}
                              >
                                &#10006;
                              </div>

                              {/* AddComment Component */}
                              <Card className="mx-8">
                                <Card.Header>
                                  <h2>Edit Naming for organization</h2>
                                </Card.Header>
                                <Card.Body>
                                  {selectedRequest && (
                                    <Form>
                                      <Form.Group className="form-group col-sm-12">
                                        <Form.Label
                                          htmlFor="alt_chapter"
                                          className="form-label"
                                        >
                                          Alternative For Chapter Name :
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          value={selectedRequest.alt_chapter}
                                          id="alt_chapter"
                                          placeholder="Enter chapter name"
                                          onChange={handleUpdateChange}
                                          name="alt_chapter"
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-sm-12">
                                        <Form.Label
                                          htmlFor="alt_meeting"
                                          className="form-label"
                                        >
                                          Alternative For Meeting Name:
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          value={selectedRequest.alt_meeting}
                                          id="alt_meeting"
                                          placeholder="Enter meeting name"
                                          onChange={handleUpdateChange}
                                          name="alt_meeting"
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-sm-12">
                                        <Form.Label
                                          htmlFor="alt_membership_plan"
                                          className="form-label"
                                        >
                                          Alternative For Membership Plan Name:
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          value={
                                            selectedRequest.alt_membership_plan
                                          }
                                          id="alt_membership_plan"
                                          placeholder="Enter membership plan name"
                                          onChange={handleUpdateChange}
                                          name="alt_membership_plan"
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-sm-12">
                                        <Form.Label
                                          htmlFor="alt_membership_category"
                                          className="form-label"
                                        >
                                          Alternative For Membership Category
                                          Name:
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          value={
                                            selectedRequest.alt_membership_category
                                          }
                                          id="alt_membership_category"
                                          placeholder="Enter membership category name"
                                          onChange={handleUpdateChange}
                                          name="alt_membership_category"
                                        />
                                      </Form.Group>
                                    </Form>
                                  )}
                                </Card.Body>
                                <Card.Footer className="text-end">
                                  <Button
                                    variant="secondary"
                                    onClick={() => setEditRow(null)}
                                    style={{
                                      backgroundColor: "#6c757d",
                                      border: "none",
                                      marginRight: ".2rem",
                                    }}
                                  >
                                    Close
                                  </Button>

                                  <Button
                                    variant="secondary"
                                    onClick={() => handleUpdate()}
                                    style={{
                                      backgroundColor: "#007BFF",
                                      border: "none",
                                      marginRight: ".2rem",
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Card.Footer>
                              </Card>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                      {/* ))} */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Success Alert */}
        <Alert
          show={successMessages.length > 0}
          variant="success"
          onClose={() => setSuccessMessages([])}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid green",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={() => setSuccessMessages([])}
            />
          </div>
          <Alert.Heading>Success!</Alert.Heading>
          {successMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </Alert>

        {/* Error Alert */}
        {error && (
          <Alert
            variant="danger"
            onClose={clearError}
            dismissible
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid red",
              color: "black",
              width: "300px",
              zIndex: 1000,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
                onClick={clearError}
              />
            </div>
            <Alert.Heading>Error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default NamingConvention;
