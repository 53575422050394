import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Form,
    Alert,
} from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import { FaTimes } from "react-icons/fa";
import axios from "axios";  // Import axios for API calls
import { useNavigate } from "react-router-dom";

const OrgGallery = ({ onCancel }) => {
    const navigate = useNavigate();
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [imageFiles, setImageFiles] = useState(Array(5).fill(null));
    const [imagePreviews, setImagePreviews] = useState(Array(5).fill(null));
    const [imageDescriptions, setImageDescriptions] = useState(Array(5).fill(''));
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [error, setError] = useState(null);
    const memberId = sessionStorage.getItem("member_id");
    const token = sessionStorage.getItem("token");
    const orgId = sessionStorage.getItem("org_id");

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const updatedFiles = [...imageFiles];
            updatedFiles[index] = file;
            setImageFiles(updatedFiles);

            const updatedPreviews = [...imagePreviews];
            updatedPreviews[index] = URL.createObjectURL(file);
            setImagePreviews(updatedPreviews);
        }
    };

    const handleDescriptionChange = (e, index) => {
        const updatedDescriptions = [...imageDescriptions];
        updatedDescriptions[index] = e.target.value;
        setImageDescriptions(updatedDescriptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);

        try {
            const formData = new FormData();
            if (logo) {
                formData.append('logo', logo);
            }
            imageFiles.forEach((image, index) => {
                if (image) {
                    formData.append(`gallery_${index}`, image);
                    formData.append(`description_${index}`, imageDescriptions[index]);
                }
            });

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/org/uploadOrgImages/${orgId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                
                try {
                    axios
                      .get(`${process.env.REACT_APP_API_URL}/org/getOrgImages/${orgId}`)
                      .then((response) => {
                        if (response.status === 200) {
                          const { awsLogoUrls, galleryUrlsWithDesc } = response.data;
                          const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
                          const G1 = galleryUrlsWithDesc;
            
                          sessionStorage.setItem("org_logo", L1);
                            console.log("here2",L1);
                          // Optionally, handle the gallery images with descriptions
                          // For example, you can store them in sessionStorage or another state if needed
                          sessionStorage.setItem("org_gallery", JSON.stringify(G1));
                        } else {
                          console.error("Unexpected response status:", response.status);
                        }
                      })
                      .catch((error) => {
                        if (error.response && error.response.status === 404) {
                          console.error("No organization found:", error);
                        } else {
                          console.error("Error fetching organization images:", error);
                        }
                      });
                  } catch (error) {
                    console.error("Unexpected error occurred:", error);
                  }
            
                setShowSuccessAlert(true);
                resetForm();
            }
        } catch (err) {
            setError(err.response ? err.response.data.error : 'Error uploading images');
        } finally {
            setLoadingSubmit(false);
        }
    };

    const resetForm = () => {
        setLogo(null);
        setLogoPreview(null);
        setImageFiles(Array(5).fill(null));
        setImagePreviews(Array(5).fill(null));
        setImageDescriptions(Array(5).fill(''));
    };

    const closeSuccess = () => {
        setShowSuccessAlert(false);
         navigate("/orgGallery")
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <>
            <div id="content-page" className="content-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            <b>Organization Gallery</b>
                                        </h4>
                                    </div>
                                    <Button variant="link" onClick={onCancel} aria-label="Close">
                                        <FaTimes />
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit} style={{ marginLeft: "50px" }}>
                                        <Form.Group>
                                            <Form.Label>Logo</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                onChange={handleLogoChange}
                                                aria-label="Upload logo"
                                            />
                                            {logoPreview && (
                                                <img
                                                    src={logoPreview}
                                                    alt="Logo Preview"
                                                    style={{ maxWidth: "100%", marginTop: "10px" }}
                                                />
                                            )}
                                        </Form.Group>
                                        {[...Array(5)].map((_, index) => (
                                            <Form.Group key={index}>
                                                <Form.Label>Image {index + 1}</Form.Label>
                                                <Row>
                                                    
                                                    <Col md={4}>
                                                        <Form.Control
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageChange(e, index)}
                                                            aria-label={`Upload image ${index + 1}`}
                                                        />
                                                        {imagePreviews[index] && (
                                                            <img
                                                                src={imagePreviews[index]}
                                                                alt={`Preview ${index + 1}`}
                                                                style={{ maxWidth: "100%", marginTop: "10px" }}
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Description for image ${index + 1}`}
                                                            value={imageDescriptions[index]}
                                                            onChange={(e) => handleDescriptionChange(e, index)}
                                                            aria-label={`Description for image ${index + 1}`}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        ))}
                                    </Form>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end">
                                    <Button
                                        className="mr-2"
                                        variant="danger"
                                        onClick={resetForm}
                                        aria-label="Reset form"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        variant="primary"
                                        onClick={handleSubmit}
                                        disabled={loadingSubmit}
                                        aria-label="Submit form"
                                    >
                                        {loadingSubmit ? "Updating..." : "Update"}
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {showSuccessAlert && (
                    <Alert
                        variant="success"
                        onClose={closeSuccess}
                        dismissible
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "white",
                            border: "1px solid green",
                            color: "green",
                            width: "300px",
                            zIndex: 1000,
                        }}
                    >
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>Organization Gallery updated successfully.</p>
                    </Alert>
                )}

                {error && (
                    <Alert
                        variant="danger"
                        onClose={clearError}
                        dismissible
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1000,
                        }}
                    >
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                )}
            </div>
        </>
    );
};

export default OrgGallery;
