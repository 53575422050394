import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import { FaTimes } from "react-icons/fa";
import useOrgNaming from "../../../hooks/getNamingHook";
import axios from "axios";

const OrganizationSetting = ({ onCancel }) => {
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");

  const [organizationData, setOrganizationData] = useState({
    org_name: "",
    owner_name: "",
    contact: "",
    email: "",
    created_by: memberId,
    url: "",
  });
  const [copied, setCopied] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const orgId = sessionStorage.getItem("org_id");
  const [orgList, setorgList] = useState([]);
  const { getOrgNames } = useOrgNaming(orgId, token);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [orgSettingsIn, setOrgSettingsIn] = useState({});
  const [orgSettings, setOrgSettings] = useState({
    trainings_across_chapter: "",
    referrals_across_chapter: "",
    events_across_chapter: "",
    posts_across_chapter: "",
    meetings_across_chapter: "",
    member_onboard_authority: "",
    chapter_referral_thirdparty_data: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const isFormValid = () => {
    // Check your form validation conditions here
    // Return true if the form is valid, false otherwise
    return (
      organizationData.org_name !== "" &&
      organizationData.owner_name !== "" &&
      organizationData.contact !== "" &&
      organizationData.email !== ""
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setValidated(true); // Trigger Bootstrap validation styles
      return;
    }

    try {
      setLoadingSubmit(true); // Start loading state
      // console.log("==organizationData org==>",organizationData);

      const protocol = window.location.protocol;

      const hostname = window.location.hostname;

      const port = window.location.port;

      const url = protocol + "//" + hostname + ":" + port;

      organizationData.url = url;
      const combinedData = {
        ...orgSettings,
        ...organizationData,
      };
      combinedData.url = url;
      setOrganizationData(combinedData);
      console.log(organizationData);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(combinedData),
        }
      );

      if (response.ok) {
        // console.log("Organization created successfully");
        showSuccess();
      } else {
        const errorData = await response.json();
        console.error("Error creating Organization:", errorData);
        setError(
          errorData.error ||
            "Error creating Organization. Please try again later."
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating Organization. Please try again later.");
    } finally {
      setLoadingSubmit(false); // Stop loading state
    }
  };

  const fetchDataFromApi = async () => {
    if (orgId) {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/org/getOrg/", {
            org_id: orgId,
          })
          .then((response) => {
            let logo1 = response.data.result[0];
            console.log("orgid2==>", logo1, orgId);
            setOrganizationData({
              org_name: logo1.organization_name || "", // Extract organization name
              owner_name: logo1.org_owner_name || "", // Extract owner name
              contact: logo1.contact_no || "", // Extract contact number
              email: logo1.email_id || "", // Extract email
              created_by: memberId, // Keep the existing member ID
              url: logo1.org_link || "", // Extract URL
            });
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("No org found:", error);
        } else {
          console.error("Error fetching org:", error);
        }
      }
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/org/getOrgSetting`,
        {
          method: "POST",
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ OrgID: orgId }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        const orgList = data.result;
        setOrgSettingsIn(orgList);
        if (orgList) {
          setOrgSettings((prevSetting) => ({
            ...prevSetting,
            org_option_id: orgList.org_option_id,
            trainings_across_chapter: orgList.trainings_across_chapter || 0,
            referrals_across_chapter: orgList.referrals_across_chapter || 0,
            events_across_chapter: orgList.events_across_chapter || 0,
            posts_across_chapter: orgList.posts_across_chapter || 0,
            meetings_across_chapter: orgList.meetings_across_chapter || 0,
            member_onboard_authority: orgList.member_onboard_authority || 0,
            chapter_referral_thirdparty_data:
              orgList.chapter_referral_thirdparty_data || 0,
            updated_by: memberId,
            org_id: orgId,
          }));
        } else {
          console.error("Organization settings data is missing.");
        }
      } else {
        const errorData = await response.json();
        setError(`Error fetching orgList: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error fetching orgList. Please try again later.");
    }
  };

  const fetchOrgData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/getAllOrg",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const orgData = data.result;
        // Filter the organizations by orgId
        const filteredOrgList = orgData.filter((org) => org.org_id == orgId);
        setorgList(filteredOrgList);
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
    fetchOrgData();
    return () => {};
  }, [token, orgId]);

  const handleToggle = (settingName, value) => {
    setOrgSettings((prevSettings) => ({
      ...prevSettings,
      [settingName]: value,
    }));
  };

  // const handleSave = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/org/createOrgSetting",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(orgSettings),
  //       }
  //     );

  //     if (response.ok) {
  //       showSuccess();
  //       // alert("Organization setting updated successfully"); // Show alert
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Error creating Organization setting:", errorData);
  //       setError("Error creating Organization. Please try again later.");
  //     }
  //   } catch (error) {
  //     console.error("Unexpected error:", error.message);
  //     setError("Error creating Organization setting. Please try again later.");
  //   }
  // };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      console.log("------------------------------------>", orgSettings);

      // Combine orgSettings and organizationData into one object
      const dataToSend = {
        orgSettings: orgSettings,
        orgDetails: organizationData, // Include orgDetails
      };
      console.log("------------------------------------>", dataToSend);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/org/updateOrganizationSettings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend), // Send the combined object
        }
      );

      if (response.ok) {
        showSuccess();
        // Optionally show an alert or perform other actions
      } else {
        const errorData = await response.json();
        console.error("Error creating Organization setting:", errorData);
        setError("Error creating Organization. Please try again later.");
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating Organization setting. Please try again later.");
    }
  };

  const resetForm = () => {
    setOrgSettings(orgSettingsIn);
  };

  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  const clearError = () => {
    setError(null);
  };

  const handleCopyClick = (orgLink, orgId) => {
    handleCopy(orgLink, orgId);
    setCopiedId(orgId);

    // Reset copiedId after 3 seconds
    setTimeout(() => {
      setCopiedId(null);
    }, 3000);
  };
  const handleCopy = (text, orgId) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(orgId);
        setTimeout(() => {
          setCopied(null);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col lg="12">
              <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">
                    <b>Organization</b>
                  </h4>
                  <div className="d-flex align-items-center">
                    {orgList.map((org) => (
                      <Button
                        key={org.org_id}
                        variant="primary"
                        className="ml-2"
                        onClick={() =>
                          handleCopyClick(org.org_link, org.org_id)
                        }
                        disabled={copiedId === org.org_id}
                      >
                        {copiedId === org.org_id ? "Copied" : "Copy Link"}
                      </Button>
                    ))}
                  </div>
                </Card.Header>

                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="org_name">
                          <Form.Label>
                            Organization <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Update organization name"
                            name="org_name"
                            value={organizationData.org_name}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the updated organization name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="owner_name">
                          <Form.Label>
                            Owner Name <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Update owner name"
                            name="owner_name"
                            value={organizationData.owner_name}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the updated owner name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group controlId="contact">
                          <Form.Label>
                            Contact <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            name="contact"
                            placeholder="Update the contact number"
                            value={organizationData.contact}
                            onChange={handleChange}
                            maxLength="10"
                            pattern="[0-9]{10}"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the updated contact number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="email">
                          <Form.Label>
                            Email <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Update email"
                            name="email"
                            value={organizationData.email}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid email.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>

                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">
                    <b>Settings</b>
                  </h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-lg-6 px-3">
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Member Onboarding Authority</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="post-switch"
                            label=""
                            checked={orgSettings.member_onboard_authority === 1}
                            onChange={(e) =>
                              handleToggle(
                                "member_onboard_authority",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Posts Across Chapter</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="post-switch"
                            label=""
                            checked={orgSettings.posts_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "posts_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Trainings Across Chapter</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="training-switch"
                            label=""
                            checked={orgSettings.trainings_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "trainings_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Chapter Referral Thirdparty</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="referral-switch"
                            label=""
                            checked={
                              orgSettings.chapter_referral_thirdparty_data === 1
                            }
                            onChange={(e) =>
                              handleToggle(
                                "chapter_referral_thirdparty_data",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                    </div>

                    <div className="col-lg-6 px-3">
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Events Across Chapter</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="events-switch"
                            label=""
                            checked={orgSettings.events_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "events_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>

                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Meetings Across Chapter</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="meeting-switch"
                            label=""
                            checked={orgSettings.meetings_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "meetings_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>
                          <b>Referrals Across Chapter</b>
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="referral-switch"
                            label=""
                            checked={orgSettings.referrals_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "referrals_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>

                      {/* <p style={{ textAlign: "right" }}>
                        <Button
                          className="mr-2"
                          variant="danger"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                        &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                        <Button
                          className="mr-2"
                          variant="primary"
                          onClick={handleSave}
                        >
                          {loadingSubmit ? "Updating..." : "Update"}
                        </Button>
                      </p> */}
                    </div>
                  </div>
                  <div className="row px-3">
                    <p style={{ textAlign: "right" }}>
                      <Button
                        className="mx-3"
                        variant="danger"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                      <Button variant="primary" onClick={handleSave}>
                        {loadingSubmit ? "Updating..." : "Update"}
                      </Button>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {showSuccessAlert && (
          <Alert
            variant="success"
            onClose={closeSuccess}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid green",
              color: "green",
              width: "300px",
              zIndex: 1000,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
                onClick={closeSuccess}
              />
            </div>
            <Alert.Heading>Success!</Alert.Heading>
            <p>Organization Settings updated successfully.</p>
          </Alert>
        )}

        {error && (
          <Alert
            variant="danger"
            onClose={clearError}
            dismissible
            className="position-fixed"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <Alert.Heading>Error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default OrganizationSetting;
