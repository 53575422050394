import React from "react";
import SignIn from "../views/dashboard/auth/sign-in";

//layoutpages
import Default from "../layouts/dashboard/default";

import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";
 
export const IndexRouters = [
  {
    path: "/",
    element: <Default />,
    children: [
      { path: "/", element: <SignIn /> },
      ...DefaultRouter, ...Layout1Router],
  },
];
