import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { FaTimes } from "react-icons/fa";

const EditReferral = ({ referralId, onClose }) => {

  const token = sessionStorage.getItem("token");
  const member_Id = sessionStorage.getItem("member_id");
  const [alert, setAlert] = useState(null);
  const [editedReferral, setEditedReferral] = useState({
    referral_name: '',
    company_name: '',
    email_id: '',
    contact_no: '',
    referral_desc: '',
    referred_by: '',
    referred_to: '',
    ref_status: '',
    created_by: '',
    referred_to_first_name: '',
    // Add more properties as needed
  });

  useEffect(() => {
    const fetchReferralDetails = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/referrals/get-referral/referral-id/${referralId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch referral details');
        }

        const data = await response.json();

        if (data && data.results && data.results.length > 0) {
          const fetchedReferral = data.results[0];

          setEditedReferral((prevReferral) => ({
            ...prevReferral,
            referId: fetchedReferral.referral_id || '',
            referral_name: fetchedReferral.referral_name || '',
            company_name: fetchedReferral.company_name || '',
            email_id: fetchedReferral.email_id || '',
            contact_no: fetchedReferral.contact_no || '',
            referral_desc: fetchedReferral.referral_desc || '',
            referred_by: fetchedReferral.referred_by || '',
            referred_to: fetchedReferral.referred_to || '',
            ref_status: fetchedReferral.ref_status || '',
            created_by: fetchedReferral.created_by || '',
            referred_to_first_name: fetchedReferral.referred_to_first_name || '',
            // Add more properties as needed
          }));
        }
      } catch (error) {
        console.error('Error fetching referral details:', error);
      }
    };

    fetchReferralDetails();
  }, [referralId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReferral((prevReferral) => ({
      ...prevReferral,
      [name]: value,
      created_by: `${member_Id}`,
    }));
  };

  const handleSave = async () => {
    try {
      const editedReferralUpdated = { ...editedReferral };
      delete editedReferralUpdated.referred_to_first_name;

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/update-referral/${referralId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedReferralUpdated),
        }
      );

      console.log('referral details in handle save: ', editedReferralUpdated);

      if (!response.ok) {
        throw new Error('Failed to update referral');
      }

      // Assuming the API returns the updated referral data
      const updatedReferral = await response.json();

      console.log('Updated referral:', updatedReferral);
      setAlert({ type: 'success', message: 'Referral updated successfully!' });
      
    } catch (error) {
      console.error('Error updating referral:', error);
      setAlert({ type: 'error', message: error.message });
      // Handle error, show an alert, etc.
    }
  };

  const closeAlert = () => {
    // Clear the alert
    setAlert(null);
    onClose();
  };

  return (
    <>
    <Card className='mx-4'>
      <Card.Header>
        <h2>Edit Referral</h2>
      </Card.Header>
      <Card.Body>
        <Form>
        <Form.Group controlId="formReferredToFirstName">
            <Form.Label>Referred To:</Form.Label>
            <Form.Control
              type="text"
              name="referred_to_first_name"
              value={editedReferral.referred_to_first_name}
              onChange={handleInputChange}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="formReferralName">
            <Form.Label>Referral Name:</Form.Label>
            <Form.Control
              type="text"
              name="referral_name"
              value={editedReferral.referral_name}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formCompanyName">
            <Form.Label>Company Name:</Form.Label>
            <Form.Control
              type="text"
              name="company_name"
              value={editedReferral.company_name}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="text"
              name="email_id"
              value={editedReferral.email_id}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formContactNumber">
            <Form.Label>Contact Number:</Form.Label>
            <Form.Control
              type="text"
              name="contact_no"
              value={editedReferral.contact_no}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formReferralDesc">
            <Form.Label>Referral Description:</Form.Label>
            <Form.Control
              as="textarea"
              name="referral_desc"
              value={editedReferral.referral_desc}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formRefStatus">
            <Form.Label>Status:</Form.Label>
            <Form.Control
              type="text"
              name="ref_status"
              value={editedReferral.ref_status}
              onChange={handleInputChange}
              readOnly
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button className='me-2' variant="danger" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" type="button" onClick={handleSave}>
          Save
        </Button>
      </Card.Footer>
    </Card>

    
    {alert && (
      <Alert
        variant={alert.type === 'success' ? 'success' : 'danger'}
        // onClose={closeAlert}
        // dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: alert.type === 'success' ? "green" : "red",
          border: alert.type === 'success' ? "1px solid green" : "1px solid red",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FaTimes
              style={{ cursor: 'pointer', color: 'black', fontSize: "15px" }}
              onClick={closeAlert}
            />
        </div>
        <Alert.Heading>{alert.type === 'success' ? 'Updated!' : 'Error!'}</Alert.Heading>
        <p>{alert.message}</p>
      </Alert>
    )}
    
    </>
  );
};

export default EditReferral;
