import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEye,
  FaEllipsisV,
  FaEdit,
  FaTimes,
  FaUserCheck,
} from "react-icons/fa";
import RegisteredMembers from "../eventRegMembers";
import "./customcss/admintabstyle.css";

const EventList = () => {
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const roleId = sessionStorage.getItem("role_id");
  const org_id = sessionStorage.getItem("org_id");
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [membersRow, setMembersRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [tokenn, setTokenn] = useState(`${token}`);

  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/event/events",
        {
          headers: {
            Authorization: `Bearer ${tokenn}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Use roleId to determine which events to set
        if (roleId == 2) {
          const orgEvents = data.processedEvents.filter(
            (event) =>
              event.org_id == org_id &&
              event.chapter_id != null &&
              event.chapter_id == chapterId
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        } else if (roleId == 4) {
          const orgEvents = data.processedEvents.filter(
            (event) => event.org_id == org_id
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        }
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        // setError(`Error fetching events: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error(
        "Error fetching events. Please try again later.",
        error.message
      );
      // setError("Error fetching events. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDataFromApi();
    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleMembersSection = (index) => {
    setMembersRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setMembersRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, editRow, deleteRow]);

   // Function to convert time from 24-hour to 12-hour format
   const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);
  
    if (formattedHour === 0) {
      formattedHour = 12;  // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }
  
    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card>
                {/* <Card.Header className="d-flex justify-content-between align-items-center">
                  <h4>Upcoming Events</h4>
                </Card.Header> */}
                <Card.Body className="p-0">
                  <Table className="rounded">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>
                          <b>Start Date</b>
                        </th>
                        <th>
                          <b>Topic</b>
                        </th>
                        <th>
                          <b>Mode</b>
                        </th>
                        <th>
                          <b></b>
                        </th>
                      </tr>
                    </thead>
                    {events.length > 0 ? (
                      <tbody>
                        {events.map((event, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <b>
                                  {new Date(event.event_start_date)
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}
                                  /
                                  {(
                                    new Date(
                                      event.event_start_date
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}
                                  /
                                  {new Date(
                                    event.event_start_date
                                  ).getFullYear()}
                                </b>
                              </td>
                              <td>
                                <b>{event.event_topic}</b>
                              </td>
                              <td>
                                <b>{event.event_mode}</b>
                              </td>
                              <td>
                                <div className="d-lg-none">
                                  <Dropdown
                                    show={showActionsDropdown === index}
                                    onSelect={() =>
                                      setShowActionsDropdown(null)
                                    }
                                  >
                                    <Dropdown.Toggle
                                      variant="link"
                                      id={`dropdown-basic-${index}`}
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "skyblue",
                                      }}
                                    >
                                      <FaEllipsisV
                                        className="ellipsis-icon"
                                        onClick={() =>
                                          toggleActionsDropdown(index)
                                        }
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => toggleEyeDetails(index)}
                                      >
                                        <FaEye className="viewicon" /> View
                                        Details
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          toggleMembersSection(index)
                                        }
                                      >
                                        <FaUserCheck className="editicon" />{" "}
                                        View Members
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item onClick={() => toggleEditSection(index)}>
                                                                            <FaEdit className="editicon" /> Edit
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => toggleDeleteSection(index)}>
                                                                            <FaTrash className="deleteicon" /> Delete
                                                                        </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="d-none d-lg-block">
                                  <FaEye
                                    className="viewicon"
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => toggleEyeDetails(index)}
                                  />
                                  &nbsp;&nbsp;
                                  <FaUserCheck
                                    // className="editicon"
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => toggleMembersSection(index)}
                                  />
                                  &nbsp;&nbsp;
                                  {/* <FaEdit
                                                                        className="editicon"
                                                                        size={15}
                                                                        color="#007BFF"
                                                                        onClick={() => toggleEditSection(index)}
                                                                    />
                                                                    &nbsp;&nbsp;
                                                                    <FaTrash
                                                                        className="deleteicon"
                                                                        size={15}
                                                                        color="red"
                                                                        onClick={() => toggleDeleteSection(index)}
                                                                    /> */}
                                </div>
                              </td>
                            </tr>
                            {eyeClickedRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setEyeClickedRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <p>
                                        <b>
                                          Organizer:{" "}
                                          {event.event_organizer_name}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                          Cut-Off Reg. Date:{" "}
                                          {new Date(event.event_cutoff_reg_date)
                                            .getDate()
                                            .toString()
                                            .padStart(2, "0")}
                                          /
                                          {(
                                            new Date(
                                              event.event_cutoff_reg_date
                                            ).getMonth() + 1
                                          )
                                            .toString()
                                            .padStart(2, "0")}
                                          /
                                          {new Date(
                                            event.event_cutoff_reg_date
                                          ).getFullYear()}
                                        </b>
                                      </p>
                                      {/* <p>
                                        <b>Chapter: {event.chapter_name}</b>
                                      </p> */}
                                      <p>
                                        <b>Charge: ₹ {event.event_charges}</b>
                                      </p>
                                      <p>
                                        <b>
                                          {event.event_mode === "Virtual" ? (
                                            <div>
                                              <p>
                                                Event Link:{" "}
                                                {event.event_link || "N/A"}
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p>
                                                Event Place:{" "}
                                                {event.event_place || "N/A"}
                                              </p>
                                            </div>
                                          )}
                                        </b>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <p>
                                        <b>
                                          Start Date:{" "}
                                          {new Date(event.event_start_date)
                                            .getDate()
                                            .toString()
                                            .padStart(2, "0")}
                                          /
                                          {(
                                            new Date(
                                              event.event_start_date
                                            ).getMonth() + 1
                                          )
                                            .toString()
                                            .padStart(2, "0")}
                                          /
                                          {new Date(
                                            event.event_start_date
                                          ).getFullYear()}
                                        </b>
                                      </p>

                                      {event.event_end_date && (
                                        <p>
                                          <b>
                                            End Date:{" "}
                                            {new Date(event.event_end_date)
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {(
                                              new Date(
                                                event.event_end_date
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}
                                            /
                                            {new Date(
                                              event.event_end_date
                                            ).getFullYear()}
                                          </b>
                                        </p>
                                      )}
                                      <p>
                                        <b>
                                          Start Time:{" "}
                                          {formatTime(event.event_time_in)}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                          End Time:{" "}
                                          {formatTime(event.event_time_out)}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                          Description: {event.event_details}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {membersRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setMembersRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <RegisteredMembers
                                    eventId={event.event_id}
                                    onClose={() => setMembersRow(null)}
                                  />
                                </td>
                              </tr>
                            )}

                            {/* {editRow === index && (
                                                            <tr className="expanded-row">
                                                                <td colSpan="4" style={{ position: 'relative' }}>
                                                                    <div
                                                                    className="close-icon"
                                                                    onClick={() => setEditRow(null)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '10px',
                                                                        right: '20px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    >
                                                                    &#10006;
                                                                    </div>
                                                                    <EditMeeting meetingId={meeting.meeting_id} onClose={() => setEditRow(null)}/>
                                                                </td>
                                                            </tr>
                                                        )} */}

                            {/* {deleteRow === index && (
                                                            <tr className="expanded-row">
                                                                <td colSpan="4" style={{ position: 'relative' }}>
                                                                    <div
                                                                    className="close-icon"
                                                                    onClick={() => setDeleteRow(null)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '10px',
                                                                        right: '20px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    >
                                                                    &#10006;
                                                                    </div>
                                                                    <DeleteMeeting meetingId={meeting.meeting_id} onClose={() => setDeleteRow(null)}/>
                                                                </td>
                                                            </tr>
                                                        )} */}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <p>No events available!</p>
                    )}
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EventList;
