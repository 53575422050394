import React, { useEffect, useRef,useState } from 'react';
import * as SettingSelector from '../../../../store/setting/selectors';
import { useSelector } from 'react-redux';
import Verticalnav from './verticalnav';
import Scrollbar from 'smooth-scrollbar';
import useOrgNaming from "../../../../hooks/getNamingHook";


const Sidebar = () => {
  // const [getOrgNames, setGetOrgNames] = useState([]);
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token"); // Fetch the token from localStorage
  const role = sessionStorage.getItem("role"); // Fetch the token from localStorage
  const { getOrgNames, getSettingNames } = useOrgNaming(org_id, token);
  const orgNamesHandler = role === 'ORG_ADMIN' || role === 'CHAPTER_ADMIN' ? getOrgNames : () => {};
  const settingNamesHandler = role === 'ORG_ADMIN' || role === 'CHAPTER_ADMIN' ? getSettingNames : () => {};
  
  // Redux selectors
  const sidebarType = useSelector(SettingSelector.sidebar_type);
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  // Ref for the sidebar element
  const sidebar = useRef(null);
   
   useEffect(() => {
    // DOM elements
    const tabs = document.querySelectorAll('.nav');
    const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');
    const scrollbarContainer = document.querySelector('.data-scrollbar');

    // Initialize Scrollbar
    Scrollbar.init(scrollbarContainer);

    // Set responsive classes when the component mounts
    const setResponsiveClasses = () => {
      if (window.innerWidth < 1025) {
        // Add classes for small screens
        Array.from(tabs, (elem) => elem.classList.add('flex-column', 'on-resize'));

        if (sidebarResponsive !== null && !sidebarResponsive.classList.contains('sidebar-mini')) {
          // Add sidebar-mini class for small screens
          sidebarResponsive.classList.add('sidebar-mini', 'on-resize');
        }
      } else {
        // Remove classes for larger screens
        Array.from(tabs, (elem) => elem.classList.remove('flex-column', 'on-resize'));

        if (sidebarResponsive !== null && sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
          // Remove sidebar-mini class for larger screens
          sidebarResponsive.classList.remove('sidebar-mini', 'on-resize');
        }
      }
    };

    // Handle window resize
    const handleResize = () => {
      setResponsiveClasses();
    };

    // Function to toggle mini sidebar
    const minisidebar = () => {
      document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
    };

    // Handle clicks outside the sidebar to hide it except the menu icon as menu icon will toggle the sidebar
    const handleOutsideClick = (event) => {
      if (sidebar.current) {
        // Check if the sidebar is in mini mode
        const isSidebarMini = sidebar.current.classList.contains('sidebar-mini'); // If the class 'sidebar-mini' is present then side bar is open

        // Check if the clicked element is the menu icon
        const isMenuIcon = event.target.classList.contains('iq-burger-menu'); // Here 'iq-burger-menu' is the actual class of your menu icon, this will be the exception for outside click

        if (!isSidebarMini && !isMenuIcon && !sidebar.current.contains(event.target)) {
          // Close sidebar and toggle mini sidebar if it's not in mini mode and the clicked element is outside the sidebar
          sidebar.current.classList.remove('show');
          minisidebar();
        }
      }
    };

    // Initial setup
    setResponsiveClasses();

    // Add event listeners
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleOutsideClick);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  

  // Handler for menu item click
  const handleMenuItemClick = () => {
    alert('Menu item clicked');
    if (sidebar.current) {
      sidebar.current.classList.remove('show');
    }
  };


  return (
    <>
      <aside
        ref={sidebar}
        className={`${sidebarType.join(' ')} ${sidebarMenuStyle} sidebar sidebar-default sidebar-base navs-rounded-all sidebar-mini`}
        id="first-tour"
        data-toggle="main-sidebar"
        data-sidebar="responsive"
      >
        <div className="sidebar-body pt-0 data-scrollbar">
          <div className="sidebar-list">
            <Verticalnav onMenuItemClick={handleMenuItemClick} 
              getOrgNames={orgNamesHandler}
              getSettingNames={settingNamesHandler}
            />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default Sidebar;



