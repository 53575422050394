import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { FaTrash, FaEye, FaEllipsisV, FaEdit, FaTimes, FaUserCheck  } from "react-icons/fa";


const token = sessionStorage.getItem('token');

function TrainingRegMembers({trainingID}) {
    const [regMembers,setRegMembers] = useState([])

const navigate = useNavigate();
// list of members registered for particular training

const fetchRegMembers = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/training/registered/members/${trainingID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

      if (response) {
        const data = await response.json();
        setRegMembers(data.results); // Assuming imageUrls is the property name
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
       // setError(`Error fetching registered members: ${JSON.stringify(errorData)}`);
       console.log('error whilw fetching member list')
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('Unexpected error:', error.message);
     // setError('Error fetching events. Please try again later.');
    }
  };


  useEffect(() => {
    fetchRegMembers();
    const intervalId = setInterval(() => {
        fetchRegMembers();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const navigateToFriendProfile = (memberId, user_id) => {
    // Navigate to the friend profile page with memberId
    navigate("/dashboard/app/fprofile", {
      state: { state: memberId, userid: user_id },
    });
  };


  return (
    <div>

<Container>
        <Row>
          {regMembers&&regMembers.length > 0 ? (
            <Col lg="12">
              <Card>
                <Card.Header>
                  <h4>Registered Members</h4>
                </Card.Header>
                <Card.Body>
                  <Table className="rounded">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {regMembers.map((member, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{member.first_name} {member.last_name}</td>
                            <td>{member.email_id}</td>
                            <td>
                                <FaEye
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => navigateToFriendProfile(member.member_id, member.user_id)}
                                  />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <p>No registered members available!</p>
          )}
        </Row>
      </Container>


    </div>
  )
}

export default TrainingRegMembers