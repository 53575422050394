import React, { useState, useEffect } from "react";
import { Table, Container, Row, Col, Card, Alert, Dropdown, Modal, Button } from "react-bootstrap";
import { FaEdit, FaTrash, FaEye, FaEllipsisV, FaTimes, FaUserPlus } from "react-icons/fa";
import AddVisitorForm from "./addVisitorForm";
// import EditVisitor from "./editVisitor";
// import DeleteVisitor from "./deleteVisitor";

const VisitorList = () => {


    const [visitors, setVisitors] = useState([]);
    const [error, setError] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [deleteRow, setDeleteRow] = useState(null);
    const [showActionsDropdown, setShowActionsDropdown] = useState(null);
    const [showMeetingDetails, setShowMeetingDetails] = useState(false);
    const [meetingDetails, setMeetingDetails] = useState(null);

    const token = sessionStorage.getItem("token");
    const org_id = sessionStorage.getItem("org_id");

    const fetchVisitors = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/meetings/getAllVisitor/${org_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (data && data.results) {
                setVisitors(data.results);
            }
        } catch (err) {
            console.error("Error fetching visitors:", err);
            setError("An error occurred while fetching visitors. Please try again later.");
        }
    };
    const fetchMeetingDetails = async (meeting_id) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/meetings/meetings-list/meeting-id/${meeting_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            console.log("meet data===>", data);

            if (data) {
                setMeetingDetails(data.results);
                setShowMeetingDetails(true);
            }
        } catch (err) {
            console.error("Error fetching meeting details:", err);
            setError("An error occurred while fetching meeting details. Please try again later.");
        }
    };


    useEffect(() => {
        fetchVisitors();
    }, []);

    const toggleEditSection = (index) => {
        setEditRow((prev) => (prev === index ? null : index));
    };

    const toggleDeleteSection = (index) => {
        setDeleteRow((prev) => (prev === index ? null : index));
    };

    const toggleActionsDropdown = (index) => {
        setShowActionsDropdown((prev) => (prev === index ? null : index));
    };

    const handleCloseMeetingDetails = () => setShowMeetingDetails(false);


    return (
        <>
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            <b>
                                                Visitor
                                            </b>
                                        </h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Table className="rounded">
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                <th><b>Name</b></th>
                                                <th><b>Mobile</b></th>
                                                <th><b>Email</b></th>
                                                <th><b>Address</b></th>
                                                <th><b></b></th>
                                            </tr>
                                        </thead>
                                        {visitors.length > 0 ? (
                                            <tbody>
                                                {visitors.map((visitor, index) => (
                                                    <React.Fragment key={visitor.visitor_id}>
                                                        <tr>
                                                            <td><b>{visitor.visitor_name}</b></td>
                                                            <td><b>{visitor.visitor_mob}</b></td>
                                                            <td><b>{visitor.visitor_email}</b></td>
                                                            <td><b>{visitor.visitor_address}</b></td>
                                                            <td>
                                                                <div className="d-lg-none">
                                                                    <Dropdown
                                                                        show={showActionsDropdown === index}
                                                                        onSelect={() => setShowActionsDropdown(null)}
                                                                    >
                                                                        <Dropdown.Toggle
                                                                            variant="link"
                                                                            id={`dropdown-basic-${index}`}
                                                                            style={{ fontSize: "1.5rem", color: "skyblue" }}
                                                                        >
                                                                            <FaEllipsisV
                                                                                className="ellipsis-icon"
                                                                                onClick={() => toggleActionsDropdown(index)}
                                                                            />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {/* <Dropdown.Item onClick={() => toggleEditSection(index)}>
                                                                                <FaEdit className="editicon" size={13} /> Edit
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => toggleDeleteSection(index)}>
                                                                                <FaTrash className="deleteicon" size={13} /> Delete
                                                                            </Dropdown.Item> */}
                                                                            <Dropdown.Item onClick={() => fetchMeetingDetails(visitor.meeting_id)}>
                                                                                <FaEye className="viewicon" size={13} /> View Meeting Details
                                                                            </Dropdown.Item>

                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <div className="d-none d-lg-block">
                                                                    {/* <FaEdit
                                                                        className="editicon"
                                                                        size={15}
                                                                        color="#007BFF"
                                                                        onClick={() => toggleEditSection(index)}
                                                                    />
                                                                    &nbsp;&nbsp;
                                                                    <FaTrash
                                                                        className="deleteicon"
                                                                        size={15}
                                                                        color="red"
                                                                        onClick={() => toggleDeleteSection(index)}
                                                                    /> */}
                                                                    {/* &nbsp;&nbsp; */}
                                                                    <FaEye
                                                                        className="viewicon"
                                                                        size={15}
                                                                        color="#007BFF"

                                                                        onClick={() => fetchMeetingDetails(visitor.meeting_id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {editRow === index && (
                                                            <tr className="expanded-row">
                                                                <td colSpan="5" style={{ position: "relative" }}>
                                                                    <div
                                                                        className="close-icon"
                                                                        onClick={() => setEditRow(null)}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "10px",
                                                                            right: "20px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        &#10006;
                                                                    </div>
                                                                    {/* <EditVisitor visitorId={visitor.visitor_id} onClose={() => setEditRow(null)} /> */}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {deleteRow === index && (
                                                            <tr className="expanded-row">
                                                                <td colSpan="5" style={{ position: "relative" }}>
                                                                    <div
                                                                        className="close-icon"
                                                                        onClick={() => setDeleteRow(null)}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "10px",
                                                                            right: "20px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        &#10006;
                                                                    </div>
                                                                    {/* <DeleteVisitor visitorId={visitor.visitor_id} onClose={() => setDeleteRow(null)} /> */}
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        ) : (
                                            <p>No visitors found.</p>
                                        )}
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={showMeetingDetails} onHide={handleCloseMeetingDetails}>
                <Modal.Header closeButton>
                    <Modal.Title>Meeting Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {meetingDetails ? (
                        <div style={{ padding: "10px 20px", lineHeight: "1.6" }}>
                            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                                Meeting Details
                            </h3>
                            <p style={{ fontSize: "1.1rem", color: "#333" }}>
                                <b>Meeting Subject:</b> {meetingDetails.meeting_subject}
                            </p>
                            <p style={{ fontSize: "1.1rem", color: "#333" }}>
                                <b>Meeting Date:</b> {new Date(meetingDetails.meeting_date).toLocaleDateString()}
                            </p>
                            <p style={{ fontSize: "1.1rem", color: "#333" }}>
                                <b>Meeting Mode:</b> {meetingDetails.meeting_mode}
                            </p>
                            <p style={{ fontSize: "1.1rem", color: "#333" }}>
                                <b>Chapter Name:</b> {meetingDetails.chapter_name}
                            </p>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseMeetingDetails}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Error Alert */}
            {error && (
                <Alert
                    variant="danger"
                    onClose={() => setError(null)}
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        border: "1px solid red",
                        color: "red",
                        width: "300px",
                        zIndex: 1000,
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FaTimes
                            style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
                            onClick={() => setError(null)}
                        />
                    </div>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>{error}</p>
                </Alert>
            )}
        </>
    );
};

export default VisitorList;
