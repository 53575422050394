import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
  Card,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

//img
import logo from "../../../assets/images/logo.png";
import login1 from "../../../assets/images/login/1.png";
import login2 from "../../../assets/images/login/2.png";
import login3 from "../../../assets/images/login/3.png";
import { jwtDecode } from "jwt-decode";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const navigate = useNavigate();
  const [email_id, setEmail_id] = useState("");
  const [password, setPassword] = useState("");
  const [org, setOrg] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgGallery, setOrgGallery] = useState([]);
  //for show alert messege
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "password":
        setShowNewPassword(!showNewPassword);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getQueryParam = (url, param) => {
      const queryParams = new URL(url).searchParams;
      return queryParams.get(param);
    };
    let token;
    if (sessionStorage.getItem("login_token")) {
      token = sessionStorage.getItem("login_token");
    } else {
      token = getQueryParam(window.location.href, "token");
    }

    if (!token) {
      navigate("/auth/sign-in");
      return;
    }
    if (token) {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/org/verify-token/", { token })
          .then((response) => {
            let org_info = response.data.result[0];
            console.log("orgid==>", org_info.organization_name);
            sessionStorage.setItem("org_name", org_info.organization_name);

            setOrg(org_info);
          });
        sessionStorage.setItem("login_token", token);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("No org found:", error);
        } else {
          console.error("Error fetching org:", error);
        }
      }
      console.log("here", org);
    }

    const decodedToken = jwtDecode(token);
    const verifiedOrgId = decodedToken.org_id;
    setOrgId(verifiedOrgId);
    if (verifiedOrgId) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/org/getOrgImages/${verifiedOrgId}`
          )
          .then((response) => {
            if (response.status === 200) {
              const { awsLogoUrls, galleryUrlsWithDesc } = response.data;
              const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
              const G1 = galleryUrlsWithDesc;

              setOrgLogo(L1);
              setOrgGallery(G1);
              sessionStorage.setItem("org_logo", L1);

              // Optionally, handle the gallery images with descriptions
              // For example, you can store them in sessionStorage or another state if needed
              sessionStorage.setItem("org_gallery", JSON.stringify(G1));
            } else {
              console.error("Unexpected response status:", response.status);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              console.error("No organization found:", error);
            } else {
              console.error("Error fetching organization images:", error);
            }
          });
      } catch (error) {
        console.error("Unexpected error occurred:", error);
      }
    }
    // fetchMembers(verifiedOrgId);
  }, [navigate, sessionStorage.getItem("login_token")]);

  const showSuccess = () => {
    console.log("entering here");
    setShowSuccessModal(showSuccessModal);
    console.log("setShowSuccessModal", showSuccessModal);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const clearError = () => {
    setError(null);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email_id,
          password,
        }
      );

      const data = response.data;

      if (!data.error) {
        const token = data.response.token;
        document.cookie = `token=${token}; path=/;`;
        // Store token in sessionStorage
        sessionStorage.setItem("token", token);
        // Store other relevant data in sessionStorage
        sessionStorage.setItem("userID", data.response.user_id);
        sessionStorage.setItem("role_id", data.response.role_id);
        sessionStorage.setItem("userName", data.response.user_name);
        sessionStorage.setItem("imageUrl", data.response.imageUrl);
        sessionStorage.setItem("role", data.response.role_name);
        sessionStorage.setItem("member_id", data.response.member_id);
        sessionStorage.setItem("chapter_id", data.response.chapter_id);
        sessionStorage.setItem("chapter_name", data.response.chapter_name);
        sessionStorage.setItem("org_id", data.response.org_id);
        // Redirect to appropriate dashboard based on role
        const role = data.response.role_name;
        const orgIdString = sessionStorage.getItem("org_id");
        if (role === "ORG_ADMIN") {
          console.log(orgId, "---", sessionStorage.getItem("org_id"));

          if (orgId !== parseInt(orgIdString, 10)) {
            console.log(orgId, "===", parseInt(orgIdString, 10));

            throw new Error("Please login with provided link");
          }
          navigate("/adminDashboard");
        } else if (role === "CHAPTER_ADMIN") {
          if (orgId !== parseInt(orgIdString, 10)) {
            console.log(orgId, "===", parseInt(orgIdString, 10));
            throw new Error("Please login with provided link");
          }
          navigate("/auth/sign-in-type", { state: { email_id, password } });
        } else if (role === "MEMBER") {
          if (orgId !== parseInt(orgIdString, 10)) {
            throw new Error("Please login with provided link");
          }
          navigate("/userposts");
        } else if (role === "SUPER_ADMIN") {
          navigate("/superAdminDashboard");
        }
      } else {
        console.log(data.message);
        throw new Error(data.message);
        // throw new Error("Enter valid email address or enter valid password");
      }
    } catch (error) {
      console.error("Login failed:", error);
      // setError("Enter valid email address or enter valid password");
      let errorMessage = "An unknown error occurred. Please try again.";

      if (error.message) {
        errorMessage = error.message;
      }
      // Check if error.response and error.response.data exist
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        // Check if the error data contains the expected structure
        if (errorData.error && errorData.error.message) {
          errorMessage = errorData.error.message;
        } else if (errorData.message) {
          // Handle cases where the error message might be directly in the data
          errorMessage = errorData.message;
        }
      }

      // Set the error message to be displayed to the user
      setError(errorMessage);

      // Handle login error here (e.g., show an error message)
      // Set state or use other techniques to display the error message
    }
  };

  // login functionality when enter key is pressed

  function handleEnter(e) {
    if (e.key === "Enter") {
      console.log("entered values", e.target.value);
    }
  }

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0" style={{ margin: "auto" }}>
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 ">
              <div className="sign-in-detail text-white">
                <div
                  className="sign-in-logo mb-5 justify-content-center"
                  to="#"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div class="d-flex align-items-center">
                    {!orgLogo ? (
                      <svg
                        width="50"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      // <img
                      //   src={logo}
                      //   className="img-fluid ml-3 -4"
                      //   alt="logo"
                      //   style={{ marginRight: "5px" }}
                      // />
                      <img
                        src={orgLogo}
                        className="img-fluid ml-3 -4"
                        alt="logo"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {!org ? (
                      <h1 className="text-white ml-3">
                        &nbsp;<b>CB</b>
                      </h1>
                    ) : (
                      <h1 className="text-white ml-3">
                        <b>{org.organization_name}</b>
                      </h1>
                    )}
                  </div>
                </div>

                <div className="sign-slider overflow-hidden ">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0"
                  >
                    {orgGallery.length > 0 ? (
                      orgGallery.map((image, index) => (
                        <SwiperSlide key={index}>
                          <Image
                            style={{ paddingLeft: "30px" }}
                            src={image.url}
                            className="img-fluid mb-4"
                            alt={`Slide ${index + 1}`}
                          />
                          {/* Optionally, display description if available */}
                          {image.desc && (
                            <div className="image-description">
                              <p>{image.desc}</p>
                            </div>
                          )}
                        </SwiperSlide>
                      ))
                    ) : (
                      <>
                        <Swiper
                          spaceBetween={30}
                          centeredSlides={true}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          className="list-inline m-0 p-0 "
                        >
                          <SwiperSlide>
                            <Image
                              src={login1}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Find new friends
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login2}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Connect with the world
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login3}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Create new events
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                        </Swiper>
                      </>
                    )}
                  </Swiper>
                </div>
              </div>
            </Col>

            <Col
              md="6"
              style={{
                marginTop: "60px",
                marginBottom: "40px",
                maxHeight: "700px",
              }}
            >
              {" "}
              {/* Reduced padding from pt-5 and pb-5 to pt-3 and pb-3 */}
              <Card className="sign-in-from" style={{ marginTop: "40px" }}>
                <Card.Header>
                  <h3 className="text-center">Sign In Here!</h3>
                </Card.Header>

                <Card.Body>
                  <Form className="mt-2">
                    <Form.Group className="form-group">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        className="mb-0"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                        value={email_id}
                        onChange={(e) => {
                          setEmail_id(e.target.value);
                          clearError();
                        }}
                        onKeyDown={handleEnter}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={showNewPassword ? "text" : "password"}
                          className="mb-0"
                          id="exampleInputPassword1"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            clearError();
                          }}
                          onKeyDown={handleEnter}
                        />
                        <span
                          className="position-absolute end-0 mx-2 top-50 translate-middle-y"
                          onClick={() => togglePasswordVisibility("password")}
                        >
                          {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </Form.Group>
                    {error && (
                      <Alert
                        variant="danger"
                        onClose={() => setError(null)}
                        dismissible
                        style={{
                          color: "red",
                          background: "none",
                          border: "none",
                        }}
                      >
                        {error}
                      </Alert>
                    )}
                    <div className="d-inline-block w-100">
                      <div className="d-inline-block mt-2 pt-1">
                        {sessionStorage.getItem("login_token") ? (
                          <Link
                            to={`/auth/recoverpw?token=${sessionStorage.getItem(
                              "login_token"
                            )}`}
                          >
                            Forgot Password?
                          </Link>
                        ) : (
                          <Link to="/auth/recoverpw">Forgot Password?</Link>
                        )}
                        <Link to="" className="float-start"></Link>
                        <br></br>
                      </div>
                      <Button
                        variant="primary"
                        type="button"
                        className="float-end"
                        onClick={handleLogin}
                      >
                        Sign in
                      </Button>
                    </div>
                    <div className="sign-info">
                      <span className="dark-color d-inline-block line-height-2">
                        Don't have an account?{" "}
                        {sessionStorage.getItem("login_token") ? (
                          <Link
                            to={`/auth/sign-up?token=${sessionStorage.getItem(
                              "login_token"
                            )}`}
                          >
                            Sign up
                          </Link>
                        ) : (
                          <Link to="/auth/sign-up">Sign up</Link>
                        )}
                      </span>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
