import React, { useState } from "react";
import { Container, Row, Card, Button } from "react-bootstrap";
import OrganizationList from "./orgList";
import CreateOrganization from "./createOrg";
import { useLocation } from "react-router-dom";
const Organizations = () => {
  const [showOrganizations, setShowOrganizations] = useState(true);
  const location = useLocation();
  const { searchResults, alertMessage } = location.state || [];

  const handleCreateEventClick = () => {
    setShowOrganizations(false);
  };

  const handleCancelCreateEvent = () => {
    setShowOrganizations(true);
  };

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row className="justify-content-center">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  <b>Organizations</b>
                </h4>
              </div>
              <div className="d-flex flex-column flex-lg-row justify-content-end">
                <Button
                  variant="primary"
                  onClick={
                    showOrganizations
                      ? handleCreateEventClick
                      : handleCancelCreateEvent
                  }
                  className="my-2"
                >
                  {showOrganizations
                    ? "Create Organization"
                    : "View Organization"}
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {showOrganizations ? (
                <OrganizationList 
                 searchResults={searchResults}
                 alertMessage={alertMessage}/>
              ) : (
                <CreateOrganization onCancel={handleCancelCreateEvent} />
              )}
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default Organizations;
