import React, { useState } from "react";

import { Container, Row, Card, Button } from "react-bootstrap";
import TrainingList from "./trainingList";
import CreateTraining from "./createTreanning";

function Training() {
  const [showTrainingList, setShowTrainingList] = useState(true);

  const handleCreateTrainingClick = () => {
    setShowTrainingList(false);
  };

  const handleCancelCreateTraining = () => {
    setShowTrainingList(true);
  };

  return (
    <div>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="justify-content-center">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    <b>Trainings</b>
                  </h4>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-end">
                  <Button
                    variant="primary"
                    onClick={
                      showTrainingList
                        ? handleCreateTrainingClick
                        : handleCancelCreateTraining
                    }
                    className="my-2"
                  >
                    {showTrainingList ? "Schedule Training" : "Cancel"}
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                {showTrainingList ? (
                  <TrainingList />
                ) : (
                  <CreateTraining onCancel={handleCancelCreateTraining} />
                )}
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Training;
