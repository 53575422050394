import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const MeetingComment = ({ meetingId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const memberId = sessionStorage.getItem("member_id");
  const user = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");

  const fetchCommentsFromBackend = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/comments/meeting-comments/${meetingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch comments (status: ${response.status})`);
      }

      const data = await response.json();

      if (data.comments && data.comments.length) {
        console.log('Fetched meeting comments:', data.comments);
        setComments(data.comments);
      } else {
        console.log("No comments found");
      }
    } catch (error) {
      console.error('Error fetching comments:', error.message);
    }
  };

  // useEffect(() => {
  //   console.log('useEffect is running');
  //   fetchCommentsFromBackend();
  // }, [meetingId]);

  useEffect(() => {
    console.log('useEffect is running');
    
    // Fetch comments initially
    fetchCommentsFromBackend();
  
    // Setup polling interval for fetchCommentsFromBackend
    const intervalId = setInterval(async () => {
      fetchCommentsFromBackend();
    }, 5000); // Adjust the polling interval as needed
  
    // Cleanup interval on component unmount or when meetingId changes
    return () => clearInterval(intervalId);
  }, [meetingId]);
  

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = async () => {
    if (newComment.trim() !== '' && !loading) {
      try {
        setLoading(true); // Set loading state to true
        const response = await fetch(process.env.REACT_APP_API_URL + `/comments/meeting-comment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            meeting_id: meetingId,
            meet_comments: newComment,
            created_by: `${memberId}`,
          }),
        });

        if (!response.ok) {
          throw Error('Failed to add comment');
        }

        // Fetch updated comments after adding a new comment
        fetchCommentsFromBackend();
        setNewComment('');
      } catch (error) {
        console.error('Error adding comment:', error);
      } finally {
        setLoading(false); // Set loading state back to false
      }
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const clientTimeZone = 'Asia/Kolkata';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error('Invalid date string:', dateString);
      return 'Invalid Date';
    }

    const formattedDate = new Intl.DateTimeFormat('en-US', { ...options, timeZone: clientTimeZone }).format(date);
    console.log('Formatted Date:', formattedDate);
    return formattedDate;
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card className="whatsapp-chat-card mx-4">
            <Card.Header className="whatsapp-chat-header">Comments</Card.Header>
            <Card.Body className="whatsapp-chat-body" style={{ overflowY: 'auto', maxHeight: '30vh' }}>
              {comments.map((comment, index) => (
                <div
                  key={comment.meet_comment_id}
                  className={`message d-flex ${comment.user_name === user ? 'justify-content-end' : 'justify-content-start'}`}
                  style={{ marginBottom: "8px", textAlign: "left" }}
                >
                  <div
                    className={`p-2 rounded ${comment.user_name === user ? 'bg-primary text-white ml-auto' : 'bg-light text-dark'}`}
                    style={{ maxWidth: '70%', wordWrap: 'break-word' }}
                  >
                    <strong>{comment.user_name}:</strong><br /> {comment.meet_comments}
                    <br />{formatDate(comment.created_on)}
                  </div>
                </div>
              ))}
            </Card.Body>

            <Card.Footer className="whatsapp-chat-footer">
              <Form>
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <Form.Group controlId="commentTextarea">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type a comment..."
                        value={newComment}
                        onChange={handleCommentChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-auto">
                    <Button variant="primary" onClick={handleAddComment} disabled={loading}>
                      {loading ? 'Sending...' : 'Send'}
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MeetingComment;














