import React, { useState, useEffect } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

const EditMeeting = ({ meetingId, onClose }) => {
  const token = sessionStorage.getItem("token");
  const member_Id = sessionStorage.getItem("member_id");
  const [alert, setAlert] = useState(null);
  const [editedMeeting, setEditedMeeting] = useState({
    meeting_date: "",
    meeting_subject: "",
    meeting_end_time: "",
    meeting_start_time: "",
    meeting_desc: "",
    meeting_mode: "",
    meeting_link: "",
    meeting_place: "",
    meeting_type: "",
    member_name: "",
    chapter_name: "",
    meeting_status: "",
    created_by: "",
  });

  useEffect(() => {
    const fetchMeetingDetails = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/meetings/meetings-list/meeting-id/${meetingId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch meeting details");
        }

        const data = await response.json();

        if (data.results) {
          const fetchedMeeting = data.results;

          setEditedMeeting((prevMeeting) => ({
            ...prevMeeting,
            meeting_date: fetchedMeeting.meeting_date || "",
            meeting_subject: fetchedMeeting.meeting_subject || "",
            meeting_end_time: fetchedMeeting.meeting_end_time || "",
            meeting_start_time: fetchedMeeting.meeting_start_time || "",
            meeting_desc: fetchedMeeting.meeting_desc || "",
            meeting_mode: fetchedMeeting.meeting_mode || "",
            meeting_link: fetchedMeeting.meeting_link || "",
            meeting_place: fetchedMeeting.meeting_place || "",
            meeting_type: fetchedMeeting.meeting_type || "",
            member_name: fetchedMeeting.member_name || "",
            chapter_name: fetchedMeeting.chapter_name || "",
            meeting_status: fetchedMeeting.meeting_status || "",
            created_by: fetchedMeeting.created_by || "",
          }));
        } else {
          console.error("No results in the API response");
        }
      } catch (error) {
        console.error("Error fetching meeting details:", error);
      }
    };

    fetchMeetingDetails();
  }, [meetingId]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string if dateString is falsy (null or undefined)
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedMeeting((prevMeeting) => ({
      ...prevMeeting,
      [name]: value,
      created_by: `${member_Id}`,
    }));
  };
  const meetingModes = ["Virtual", "In-person"];
  const filteredMeetingModes = meetingModes.filter(
    (mode) => mode != editedMeeting.meeting_mode
  );

  const handleSave = async () => {
    try {
      const editedMeetingUpdated = { ...editedMeeting };
      delete editedMeetingUpdated.meeting_type;
      delete editedMeetingUpdated.member_name;
      delete editedMeetingUpdated.chapter_name;

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/meetings/update-meeting/${meetingId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedMeetingUpdated),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update meeting!");
      }

      const updatedMeeting = await response.json();

      setAlert({ type: "success", message: "Meeting updated successfully!" });
    } catch (error) {
      console.error("Error updating meeting:", error);
      setAlert({ type: "error", message: error.message });
    }
  };

  const closeAlert = () => {
    setAlert(null);
    onClose();
  };

  return (
    <>
      <Card className="">
        <Card.Header>
          <h2>Edit Meeting</h2>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formMeetingDate">
              <Form.Label>Meeting Date:</Form.Label>
              <Form.Control
                type="date"
                name="meeting_date"
                value={formatDate(editedMeeting.meeting_date)}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMeetingSubject">
              <Form.Label>Meeting Subject:</Form.Label>
              <Form.Control
                type="text"
                name="meeting_subject"
                value={editedMeeting.meeting_subject}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMeetingStartTime">
              <Form.Label>Meeting Start Time:</Form.Label>
              <Form.Control
                type="time"
                name="meeting_start_time"
                value={editedMeeting.meeting_start_time}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMeetingEndTime">
              <Form.Label>Meeting End Time:</Form.Label>
              <Form.Control
                type="time"
                name="meeting_end_time"
                value={editedMeeting.meeting_end_time}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMeetingDesc">
              <Form.Label>Meeting Description:</Form.Label>
              <Form.Control
                as="textarea"
                name="meeting_desc"
                value={editedMeeting.meeting_desc}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMeetingMode">
              <Form.Label>Meeting Mode:</Form.Label>
              <Form.Control
                as="select"
                name="meeting_mode"
                value={editedMeeting.meeting_mode}
                onChange={handleInputChange}
              >
                <option value={editedMeeting.meeting_mode} disabled>
                  {editedMeeting.meeting_mode}
                </option>
                <option value="Virtual">Virtual</option>
                <option value="In-person">In-person</option>
              </Form.Control>
            </Form.Group>

            {editedMeeting.meeting_mode === "Virtual" && (
              <Form.Group controlId="formMeetingLink">
                <Form.Label>Meeting Link:</Form.Label>
                <Form.Control
                  type="text"
                  name="meeting_link"
                  value={editedMeeting.meeting_link}
                  onChange={handleInputChange}
                  placeholder="Enter meeting link"
                />
              </Form.Group>
            )}

            {editedMeeting.meeting_mode === "In-person" && (
              <Form.Group controlId="formMeetingPlace">
                <Form.Label>Meeting Place:</Form.Label>
                <Form.Control
                  type="text"
                  name="meeting_place"
                  value={editedMeeting.meeting_place}
                  onChange={handleInputChange}
                  placeholder="Enter meeting place"
                />
              </Form.Group>
            )}

            <Form.Group controlId="formMeetingType">
              <Form.Label>Meeting Type:</Form.Label>
              <Form.Control
                type="text"
                name="meeting_type"
                value={editedMeeting.meeting_type}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="formMeetingMember">
              <Form.Label>
                {editedMeeting.meeting_type == "Chapter"
                  ? "Chapter:"
                  : "Member:"}
              </Form.Label>
              <Form.Control
                type="text"
                name={
                  editedMeeting.meeting_type == "Chapter"
                    ? "member_name"
                    : "chapter_name"
                }
                value={
                  editedMeeting.meeting_type == "Chapter"
                    ? editedMeeting.chapter_name
                    : editedMeeting.member_name
                }
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer className="text-end">
          <Button
            className="me-2"
            variant="danger"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="primary" type="button" onClick={handleSave}>
            Save
          </Button>
        </Card.Footer>
      </Card>

      {alert && (
        <Alert
          variant={alert.type === "success" ? "success" : "danger"}
          // onClose={closeAlert}
          // dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: alert.type === "success" ? "green" : "red",
            border:
              alert.type === "success" ? "1px solid green" : "1px solid red",
            backgroundColor: "white",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={closeAlert}
            />
          </div>
          <Alert.Heading>
            {alert.type === "success" ? "Updated!" : "Error!"}
          </Alert.Heading>
          <p>{alert.message}</p>
        </Alert>
      )}
    </>
  );
};

export default EditMeeting;
