import React from 'react';
import { useLocation } from 'react-router-dom';

// Import your components
import Header from '../../components/partials/dashboard/HeaderStyle/header';
import RightSidebar from '../../components/partials/dashboard/SidebarStyle/rightsidebar';
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar';
import Footer from '../../components/partials/dashboard/FooterStyle/footer';
import SettingOffCanvas from '../../components/setting/SettingOffCanvas';
import { Outlet } from 'react-router-dom';

const Default = () => {
    const location = useLocation();
    const isHomeRoute = location.pathname === '/';

    return (
        <>
            {!isHomeRoute && <Sidebar />}
            {!isHomeRoute && <Header />}
            <div className="main-content">
                <Outlet />
            </div>
            {!isHomeRoute && <RightSidebar />}
            {!isHomeRoute && <Footer />}
            <SettingOffCanvas />
        </>
    );
};

export default Default;
