import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const CreateReferral = ({ onClose, memberId, mName, state }) => {
  const chapterId = sessionStorage.getItem("chapter_id");
  const member_Id = sessionStorage.getItem("member_id");
  const userName = sessionStorage.getItem("userName");
  console.log("username-->", userName);
  const token = sessionStorage.getItem("token");
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [memberNames, setMemberNames] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [members, setMembers] = useState([]);
  const [memberid, setMemberid] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const org_id = sessionStorage.getItem("org_id");
  const [newReferral, setNewReferral] = useState({
    referral_name: "",
    company_name: "",
    email_id: "",
    contact_no: "",
    referral_desc: "",
    referred_by: `${member_Id}`,
    referred_to: memberId == member_Id ? "" : memberId,
    ref_status: "Open",
    created_by: `${member_Id}`,
  });

  const resetForm = () => {
    setNewReferral({
      referral_name: "",
      company_name: "",
      email_id: "",
      contact_no: "",
      referral_desc: "",
      referred_by: `${member_Id}`,
      referred_to: memberId == member_Id ? "" : memberId,
      ref_status: "Open",
      created_by: `${member_Id}`,
    });
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/members/members/org/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw Error("Failed to fetch members");
      }
      const data = await response.json();

      // Filter out records where member_id equals mId
      const filteredMembers = data.imageUrls.filter(
        (member) => member.member_id != member_Id
      );

      setMembers(filteredMembers);
    } catch (err) {
      console.error("Error fetching members:", err);
      // setError(
      //   "An error occurred while fetching members. Please try again later."
      // );
    }
  };

  // useEffect(() => {
  //   // Fetch initial data
  //   fetchMembers();

  //   // Poll for updates every 5 seconds (adjust as needed)
  //   const intervalId = setInterval(() => {
  //     fetchMembers();
  //   }, 5000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    // Fetch initial data
    fetchMembers();
  }, []);

  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
    handleClose();
  };

  const clearError = () => {
    setError(null);
  };

  const openReferralForm = () => {
    setShowReferralForm(true);
  };
  const closeReferralForm = () => {
    setShowReferralForm(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewReferral({
      ...newReferral,
      [name]: value,
    });
  };

  const validateAndSubmitReferral = () => {
    // Disable the submit button during processing
    setSubmitting(true);

    // Validate the form fields
    if (
      newReferral.referred_to === "" ||
      newReferral.referral_name === "" ||
      newReferral.contact_no === "" ||
      newReferral.company_name === ""
    ) {
      setError("Please fill in all mandatory fields.");
      // Enable the submit button after validation
      setSubmitting(false);
      return;
    }

    // Send the referral data to the backend
    const referralToSend = {
      ...newReferral,
      referred_by: `${member_Id}`,
      created_by: member_Id,
    };

    fetch(process.env.REACT_APP_API_URL + "/referrals/create-referral", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(referralToSend),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            `Failed to submit referral. Status: ${response.status}`
          );
        }
      })
      .then((data) => {
        console.log(data);

        setNewReferral({
          referral_name: "",
          company_name: "",
          email_id: "",
          contact_no: "",
          referral_desc: "",
          referred_by: `${member_Id}`,
          referred_to: memberId == member_Id ? "" : memberId,
          ref_status: "Open",
          created_by: member_Id,
        });

        setShowReferralForm(false);
        fetchMembers();
        showSuccess();
      })
      .catch((error) => {
        console.error("Error sending referral:", error);
        setError(
          "An error occurred while sending the referral. Please try again later."
        );
      })
      .finally(() => {
        // Enable the submit button after processing is done
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    onClose(); // Call the onClose callback passed from the parent
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h3>Send a referral</h3>
                <div className="d-flex flex-column flex-lg-row justify-content-end">
                  <AiOutlineClose
                    onClick={handleClose}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div>
                  <Form>
                    <div className="p-3">
                      <div className="row">
                        {state == member_Id && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              Referred To
                              <span style={{ color: "red" }}> *</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="referred_to"
                              value={newReferral.referred_to}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Select a Member</option>
                              {members.map((member) => (
                                <option
                                  key={member.member_id}
                                  value={member.member_id}
                                >
                                  {member.first_name} {member.last_name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        )}

                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            Referral Name
                            <span style={{ color: "red" }}> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="referral_name"
                            value={newReferral.referral_name}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email_id"
                            value={newReferral.email_id}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            Contact No<span style={{ color: "red" }}> *</span>
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            name="contact_no"
                            value={newReferral.contact_no}
                            onChange={handleInputChange}
                            minLength="10"
                            maxLength="10"
                            pattern="[0-9]{10}"
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group>
                          <Form.Label>
                            Company Name<span style={{ color: "red" }}> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="company_name"
                            value={newReferral.company_name}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <Form.Group>
                        <Form.Label>Referral Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="referral_desc"
                          value={newReferral.referral_desc}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end p-3">
                      <Button
                        variant="danger"
                        onClick={resetForm}
                        style={{ marginRight: "10px" }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="primary"
                        onClick={validateAndSubmitReferral}
                        disabled={submitting}
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Success Alert */}
      <Alert
        show={showSuccessAlert}
        variant="success"
        onClose={closeSuccess}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
            onClick={closeSuccess}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        <p>Your referral has been scheduled successfully.</p>
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};

export default CreateReferral;
