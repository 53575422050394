import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
  FaUserPlus,
} from "react-icons/fa";
import { MdEventBusy } from "react-icons/md";
import MeetingComment from "./meetingComment";
import MembersCard from "./memberCard";
import EditMeeting from "./editMeeting";
import DeleteMeeting from "./deleteMeeting";
import AddVisitorForm from "./addVisitorForm";

const ChapterMeetingList = ({ searchResults, alertMessage, getOrgNames }) => {
  const navigate = useNavigate();
  const roleId = sessionStorage.getItem("role_id");
  const [userMeetings, setUserMeetings] = useState([]);
  const [memberMeetings, setMemberMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [showPreviousMeetings, setShowPreviousMeetings] = useState(false);
  const [showMembersCard, setShowMembersCard] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const org_id = sessionStorage.getItem("org_id");
  const [showAddVisitorFormRow, setShowAddVisitorFormRow] = useState(null);

  const toggleAddVisitorForm = (index) => {
    setShowAddVisitorFormRow((prev) => (prev === index ? null : index));
  };
  const fetchUserMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/meetings/oneonone/chapter/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (data && data.imageUrls) {
        // Include meetings where meeting_type is not "Chapter" or null
        // Assuming `userRole` contains the role of the current user (ORG_ADMIN or CHAPTER_ADMIN)
        const filteredMeetings = data.imageUrls.filter((meeting) => {
          if (roleId == 2 || roleId == 3) {
            // Apply the chapterId condition for CHAPTER_ADMIN role
            return (
              meeting.meeting_type === "Chapter" &&
              meeting.chapter_id == chapterId
            );
          }
          // Show all chapter meetings for ORG_ADMIN role
          return meeting.meeting_type === "Chapter";
        });
        // const filteredMeetings = data.results.filter(
        //   (meeting) =>
        //     meeting.meeting_type === "Chapter" &&
        //     meeting.chapter_id == chapterId
        // );

        // setUserMeetings(data.results);
        setUserMeetings(filteredMeetings);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // setError(
      //   "An error occurred while fetching user meetings. Please try again later."
      // );
    }
  };

  useEffect(() => {
    fetchUserMeetings();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchUserMeetings();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [refreshNeeded]);

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const toggleMeetings = () => {
    setShowPreviousMeetings(!showPreviousMeetings);
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  // fillter the meeting by date
  const combinedMeetings =
    searchResults && searchResults.length > 0
      ? [...searchResults]
      : [...userMeetings];

  const meetingsToDisplay = combinedMeetings.slice().sort((a, b) => {
    const dateA = new Date(a.meeting_date);
    const dateB = new Date(b.meeting_date);
    return dateA - dateB;
  });

  const currentDate = new Date();
  const previousMeetings = meetingsToDisplay.filter(
    (meeting) => new Date(meeting.meeting_date) < currentDate
  );

  const upcomingMeetings = meetingsToDisplay.filter(
    (meeting) => new Date(meeting.meeting_date) >= currentDate
  );

  const reversedPreviousMeetings = [...previousMeetings].sort((a, b) => {
    const dateA = new Date(b.meeting_date);
    const dateB = new Date(a.meeting_date);
    return dateA - dateB;
  });

  const filteredMeetings =
    searchResults && searchResults.length > 0
      ? searchResults
      : showPreviousMeetings
      ? reversedPreviousMeetings
      : upcomingMeetings;

  // Function to convert time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour === 0) {
      formattedHour = 12; // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <div>
                    {(!searchResults || searchResults.length == 0) && (
                      <div
                        onClick={() =>
                          setShowPreviousMeetings(!showPreviousMeetings)
                        }
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        className="text-center text-primary"
                      >
                        <u>
                          {showPreviousMeetings
                            ? `View Upcoming ${
                                getOrgNames?.alt_meeting || "Meetings"
                              }`
                            : `View Previous ${
                                getOrgNames?.alt_meeting || "Meetings"
                              }`}
                        </u>
                      </div>
                    )}
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Date</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          <th>
                            <b>Subject</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {meetingsToDisplay.length > 0 ? (
                        <tbody>
                          {filteredMeetings.slice().map((meeting, index) => (
                            <React.Fragment key={meeting.meeting_id}>
                              <tr
                                style={{
                                  backgroundColor:
                                    new Date(meeting.meeting_date) <
                                    new Date().setHours(0, 0, 0, 0)
                                      ? "rgba(211, 211, 211, 0.5)"
                                      : "transparent",
                                  color:
                                    new Date(meeting.meeting_date) <
                                    new Date().setHours(0, 0, 0, 0)
                                      ? "darkgray"
                                      : "black",
                                }}
                              >
                                <td>
                                  <b>
                                    {new Date(meeting.meeting_date)
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {(
                                      new Date(
                                        meeting.meeting_date
                                      ).getMonth() + 1
                                    )
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {new Date(
                                      meeting.meeting_date
                                    ).getFullYear()}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {meeting.meeting_status === "Schedule"
                                      ? "Scheduled"
                                      : meeting.meeting_status}
                                  </b>
                                </td>
                                <td>
                                  <b>{meeting.meeting_subject}</b>
                                </td>
                                <td>
                                  <div className="d-lg-none">
                                    <Dropdown
                                      show={showActionsDropdown === index}
                                      onSelect={() =>
                                        setShowActionsDropdown(null)
                                      }
                                    >
                                      <Dropdown.Toggle
                                        variant="link"
                                        id={`dropdown-basic-${index}`}
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "skyblue",
                                        }}
                                      >
                                        <FaEllipsisV
                                          className="ellipsis-icon"
                                          onClick={() =>
                                            toggleActionsDropdown(index)
                                          }
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        >
                                          <FaEye
                                            className="viewicon"
                                            size={13}
                                          />{" "}
                                          View
                                        </Dropdown.Item>

                                        {!showPreviousMeetings &&
                                          memberId == meeting.created_by &&
                                          roleId == 2 &&
                                          meeting.meeting_status !=
                                            "cancelled" && (
                                            <>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  toggleEditSection(index)
                                                }
                                              >
                                                <FaEdit
                                                  className="editicon"
                                                  size={13}
                                                />{" "}
                                                Edit
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={() =>
                                                  toggleCommentSection(index)
                                                }
                                              >
                                                <FaComment
                                                  className="commenticon"
                                                  size={13}
                                                />{" "}
                                                Comment
                                              </Dropdown.Item>

                                              {/* <Dropdown.Item
                                                onClick={() =>
                                                  toggleDeleteSection(index)
                                                }
                                              >
                                                <MdEventBusy
                                                  className="deleteicon"
                                                  size={15}
                                                />{" "}
                                                Cancel
                                              </Dropdown.Item> */}
                                              <Dropdown.Item
                                                onClick={() =>
                                                  toggleAddVisitorForm(index)
                                                }
                                              >
                                                <FaUserPlus className="createicon" />{" "}
                                                Add Visitor
                                              </Dropdown.Item>
                                            </>
                                          )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <FaEye
                                      className="viewicon"
                                      size={15}
                                      color="#007BFF"
                                      onClick={() => toggleEyeDetails(index)}
                                    />
                                    &nbsp;&nbsp;
                                    {meeting.meeting_status != "cancelled" &&
                                      roleId == 2 &&
                                      !showPreviousMeetings && (
                                        <FaUserPlus
                                          className="createicon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleAddVisitorForm(index)
                                          }
                                        />
                                      )}
                                    &nbsp;&nbsp;
                                    {meeting.meeting_status != "cancelled" &&
                                      roleId == 2 &&
                                      !showPreviousMeetings && (
                                        <FaComment
                                          className="commenticon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleCommentSection(index)
                                          }
                                        />
                                      )}
                                    &nbsp;&nbsp;
                                    {memberId == meeting.created_by &&
                                      roleId == 2 &&
                                      meeting.meeting_status != "cancelled" &&
                                      !showPreviousMeetings && (
                                        <>
                                          <FaEdit
                                            className="editicon"
                                            size={15}
                                            color="#007BFF"
                                            onClick={() =>
                                              toggleEditSection(index)
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          {/* <MdEventBusy
                                            className="deleteicon"
                                            size={17}
                                            color="red"
                                            onClick={() =>
                                              toggleDeleteSection(index)
                                            }
                                          /> */}
                                        </>
                                      )}
                                  </div>
                                </td>
                              </tr>
                              {eyeClickedRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEyeClickedRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Members:{" "}
                                            <button
                                              className="btn btn-primary"
                                              onClick={() =>
                                                setShowMembersCard(
                                                  meeting.chapter_id
                                                )
                                              }
                                            >
                                              View Members
                                            </button>
                                            {showMembersCard && (
                                              <MembersCard
                                                chapterId={showMembersCard}
                                                onClose={() =>
                                                  setShowMembersCard(false)
                                                }
                                              />
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Created By: {meeting.organizer_name}{" "}
                                          </b>
                                        </p>
                                        <p>
                                          <b>Mode: {meeting.meeting_mode}</b>
                                        </p>
                                        <p>
                                          <b>
                                            {meeting.meeting_mode ===
                                            "Virtual" ? (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meeting"}{" "}
                                                  Link:{" "}
                                                  <a
                                                    href={meeting.meeting_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    Click here
                                                    {/* {meeting.meeting_link} */}
                                                  </a>
                                                </p>
                                              </div>
                                            ) : (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meeting"}{" "}
                                                  Place:{" "}
                                                  {meeting.meeting_place ||
                                                    "N/A"}
                                                </p>
                                              </div>
                                            )}
                                          </b>
                                          <b>
                                            <p>
                                              {getOrgNames?.alt_meeting ||
                                                "Meeting"}{" "}
                                              Charges:{" "}
                                              {meeting.meeting_charges || "N/A"}
                                            </p>
                                          </b>
                                        </p>
                                      </div>
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Start Time:{" "}
                                            {formatTime(
                                              meeting.meeting_start_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            End Time:{" "}
                                            {formatTime(
                                              meeting.meeting_end_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Description: {meeting.meeting_desc}
                                          </b>
                                        </p>
                                        {meeting?.meeting_payment_link && (
                                          <p>
                                            <b>
                                              Payment Link:{" "}
                                              <a
                                                href={
                                                  meeting.meeting_payment_link
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                click here
                                                {/* {meeting.meeting_payment_link} */}
                                              </a>
                                            </b>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {commentRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setCommentRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <MeetingComment
                                      meetingId={meeting.meeting_id}
                                    />
                                  </td>
                                </tr>
                              )}

                              {editRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEditRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "0.5rem",
                                        right: "1rem",
                                        cursor: "pointer",
                                        zIndex: "9999",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <EditMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setEditRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                              {showAddVisitorFormRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="5"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() =>
                                        setShowAddVisitorFormRow(null)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: ".5rem",
                                        right: "1rem",
                                        cursor: "pointer",
                                        zIndex: "999999",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <AddVisitorForm
                                      meetingId={meeting.meeting_id}
                                      onClose={() =>
                                        setShowAddVisitorFormRow(null)
                                      }
                                    />
                                  </td>
                                </tr>
                              )}
                              {deleteRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setDeleteRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <DeleteMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setDeleteRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ) : (
                        <p>
                          No {getOrgNames?.alt_meeting || "Meeting"} scheduled.
                        </p>
                      )}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}

      {alertMessage && (
        <Alert
          variant="danger"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() =>
                navigate("/dashboards/app/chapter-meeting", {
                  state: { alertMessage: null },
                })
              }
            />
          </div>
          <Alert.Heading>Alert!</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default ChapterMeetingList;
