import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OrgAdminSearch = () => {
  const token = sessionStorage.getItem("token");

  const [filters, setFilters] = useState({
    search_text: "",
  });
  const navigate = useNavigate();
  const [allOrgs, setAllOrgs] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchAllOrgs = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/org/getAllOrg`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data && response.data.result) {
        setAllOrgs(response.data.result);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // handleSearchError("An error occurred while fetching user meetings. Please try again later.");
    }
  };

  useEffect(() => {
    fetchAllOrgs();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("dashboard/app/organizations", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text } = filters;
    console.log("filter values: ", filters);

    const updatedResults = allOrgs.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.organization_name &&
          result.organization_name
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      return isSearchMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("dashboard/app/organizations", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("dashboard/app/organizations", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
    });
    navigate("dashboard/app/organizations", { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default OrgAdminSearch;
