import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function IndexSearch() {
  const [postData, setPostData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryy, setSearchQueryy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [freeSearchQuery, setFreeSearchQuery] = useState("");
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  var chapterID = sessionStorage.getItem("chapter_id");

  const fetchPost = async () => {
    var url;
    if (!chapterID||chapterID=="null") {
      chapterID = "org_id" + sessionStorage.getItem("org_id");
      url = `/postt/getpost/allusers/${chapterID}`;
    } else {
      url = `/postt/getpost/allusers/${chapterID}`;
    }
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + url, {
        headers: {
          Authorization: `Bearer ${token}`, // including the token in the headers
        },
      });
      const data = response.data;
      setPostData(data.postsWithPresignedUrls);
    } catch (error) {
      console.error("Error fetching post data", error);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const sortedDataa = useMemo(() => {
    if (Array.isArray(postData)) {
      return [...postData].sort(
        (a, b) => new Date(b.post_id) - new Date(a.post_id)
      );
    } else {
      console.error("postData is not an array");
      return [];
    }
  }, [postData]); // Ensure that postData is the only dependency, not sortedData

  console.log(
    "sorted post data IN indexSearch MEMO USED----->>>>",
    sortedDataa
  );

  const handleSearchInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "searchQuery") {
      setSearchQuery(value);
    } else if (name === "searchQueryy") {
      setSearchQueryy(value);
    } else if (name === "freeSearchQuery") {
      setFreeSearchQuery(value);
    }
  };

  const handleSearch = () => {
    console.log("-------------------->sortedDataa",sortedDataa);

    const filtered = sortedDataa.filter(
      (user) =>
        (user.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          "") &&
        (user.last_name?.toLowerCase().includes(searchQueryy.toLowerCase()) ||
          "") &&
        (user.post_content
          ?.toLowerCase()
          .includes(freeSearchQuery.toLowerCase()) ||
          user.member_category
            ?.toLowerCase()
            .includes(freeSearchQuery.toLowerCase()) ||
          user.member_type
            ?.toLowerCase()
            .includes(freeSearchQuery.toLowerCase()) ||
          user.membership_plan
            ?.toLowerCase()
            .includes(freeSearchQuery.toLowerCase()) ||
          user.post_title
            ?.toLowerCase()
            .includes(freeSearchQuery.toLowerCase()) ||
          user.first_name
            ?.toLowerCase()
            .includes(freeSearchQuery.toLowerCase()) || // Added optional chaining here
          user.last_name?.toLowerCase().includes(freeSearchQuery.toLowerCase())) // Added optional chaining here
    );
    console.log("-------------------->filtered",filtered);

    setFilteredData(filtered);
    console.log("-------------------->filteredData",filteredData);
    
    navigate("/userposts", { state: { filteredResult: filtered } });
  };

  function handleReset() {
    setSearchQuery("");
    setSearchQueryy("");
    setFreeSearchQuery("");
    navigate("/userposts", { state: { filteredResult: [] } });
  }

  return (
    //         <div>

    //             <div className="input-group pb-2">

    //             <input   style={{marginTop:'40px'}}
    //                 type="text"
    //                 name="freeSearchQuery"
    //                 placeholder="Free search ..."
    //                 value={freeSearchQuery}
    //                 onChange={handleSearchInputChange}
    //             />

    //             <input    style={{marginTop:'10px'}}
    //                 type="text"
    //                 name="searchQuery"
    //                 placeholder="Search user by first name"
    //                 value={searchQuery}
    //                 onChange={handleSearchInputChange}
    //             />

    // <input           style={{marginTop:'10px'}}
    //                 type="text"
    //                 name="searchQueryy"
    //                 placeholder="Search user by last name"
    //                 value={searchQueryy}
    //                 onChange={handleSearchInputChange}
    //             />
    //             </div>

    //             <div className="row g-2">

    //             </div>
    //             <div className='col-12 text-center pt-2'>
    //             <button className="btn btn-primary"  onClick={handleSearch}>Search</button>
    //             </div>
    //             <div className='col-12 text-center pt-2'>
    //             <button className="btn btn-danger" onClick={handleReset}>Reset</button>
    //             </div>
    //             </div>

    <div>
      <div className="input-group flex-column pb-2">
        <input
          className="form-control mb-2 w-100" // Use Bootstrap form-control and margin bottom
          type="text"
          name="freeSearchQuery"
          placeholder="Free search ..."
          value={freeSearchQuery}
          onChange={handleSearchInputChange}
        />

        <input
          className="form-control mb-2 w-100" // Consistent styling
          type="text"
          name="searchQuery"
          placeholder="Search user by first name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          style={{ marginTop: "10px" }} // Maintain marginTop
        />

        <input
          className="form-control mb-2 w-100" // Consistent styling
          type="text"
          name="searchQueryy"
          placeholder="Search user by last name"
          value={searchQueryy}
          onChange={handleSearchInputChange}
          style={{ marginTop: "10px" }} // Maintain marginTop
        />
      </div>

      <div className="row g-2">
        <div className="col-12 text-center pt-2">
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className="col-12 text-center pt-2">
          <button className="btn btn-danger" onClick={handleReset}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default IndexSearch;
