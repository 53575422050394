import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import MeetingComment from "./meetingComment";
import DeleteMeeting from "./deleteMeeting";
import EditMeeting from "./editMeeting";
import useOrgNaming from "../../../hooks/getNamingHook";
const MemberMeetingAdmin = ({ uId, mId, state }) => {
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const roleId = sessionStorage.getItem("role_id");

  const { getOrgNames } = useOrgNaming(org_id, token);
  const navigate = useNavigate();
  const location = useLocation();
  const [userMeetings, setUserMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [showPreviousMeetings, setShowPreviousMeetings] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(false);

  // function for fetching one-on-one meeting in a chapter
  const fetchUserMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/meetings/oneonone/chapter/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      // Check if imageUrls exists in the response
      if (data && data.imageUrls) {
        // Filter meetings where meeting_type is "One-on-One"
        const filteredMeetings = data.imageUrls.filter((meeting) => {
          if (roleId == 2 ) {
            // Apply the chapterId condition for CHAPTER_ADMIN role
            return (
              meeting.meeting_type === "One-on-One" &&
              (meeting.m_chapter_id == chapterId || meeting.A_chapter_id == chapterId)
            );
          }
          // Show all chapter meetings for ORG_ADMIN role
          return meeting.meeting_type === "One-on-One";
        });
       
    setUserMeetings(filteredMeetings);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // setError(
      //   "An error occurred while fetching user meetings. Please try again later."
      // );
    }
  };

  const handleClick = () => {
    setLinkClicked(true);
  };

  useEffect(() => {
    const fetchMeetingsData = async () => {
      fetchUserMeetings();
    };

    // Fetch initial data
    fetchMeetingsData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchMeetingsData();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [refreshNeeded]);

  // alert from state as search triggered
  const alertMessage = location.state && location.state.alertMessage;

  // search results from state
  const searchResults = location.state && location.state.searchResults;

  // function to display search results if avialble othewise display userMeetings
  const combinedMeetings =
    searchResults && searchResults.length > 0
      ? [...searchResults]
      : [...userMeetings];

  // function to sort combined meetings in ascending order of meeting date
  const meetingsToDisplay = combinedMeetings.slice().sort((a, b) => {
    const dateA = new Date(a.meeting_date);
    const dateB = new Date(b.meeting_date);

    return dateA - dateB;
  });

  const currentDate = new Date();

  // to View Previous Meetings
  const previousMeetings = meetingsToDisplay.filter(
    (meetings) => new Date(meetings.meeting_date) < currentDate
  );

  // to View Upcoming Meetings
  const upcomingMeetings = meetingsToDisplay.filter(
    (meetings) => new Date(meetings.meeting_date) >= currentDate
  );

  // to reverse the order of previous meetings
  const reversedPreviousMeetings = [...previousMeetings].sort((a, b) => {
    const dateA = new Date(b.meeting_date);
    const dateB = new Date(a.meeting_date);
    return dateA - dateB;
  });

  // in the table, display search results if available otherwise based on condition display previous or upcomming meetings
  const filteredMeetings =
    searchResults && searchResults.length > 0
      ? searchResults
      : showPreviousMeetings
        ? reversedPreviousMeetings
        : upcomingMeetings;

  // to toggle expanded row for eye icon
  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  // to toggle expanded row for comment icon
  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  // to toggle expanded row for edit icon
  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  // to toggle expanded row for delete icon
  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  // to toggle dropdown menu for the icons
  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  // to collapse expanded row on clicking outside the expanded row
  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  // Function to convert time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);
  
    if (formattedHour === 0) {
      formattedHour = 12;  // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }
  
    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <b>One-on-One {getOrgNames?.alt_meeting || "Meetings"}</b>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div>
                    {(!searchResults || searchResults.length == 0) && (
                      <div
                        // if search results is not available then View Upcoming Meetings by default
                        onClick={() =>
                          setShowPreviousMeetings(!showPreviousMeetings)
                        }
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        className="text-center text-primary"
                      >
                        {showPreviousMeetings // condition check to View Previous Meetings or upcoming metings
                          ? `View Upcoming ${getOrgNames?.alt_meeting || "Meetings"
                          }`
                          : `View Previous ${getOrgNames?.alt_meeting || "Meetings"
                          }`}
                      </div>
                    )}
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Date</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          <th>
                            <b>Subject</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {meetingsToDisplay.length > 0 ? (
                        <tbody>
                          {filteredMeetings.slice().map((meeting, index) => (
                            <React.Fragment key={index}>
                              <tr
                                 style={{
                                  backgroundColor: new Date(meeting.meeting_date) < new Date().setHours(0, 0, 0, 0) ? 'rgba(211, 211, 211, 0.5)' : 'transparent',
                                  color: new Date(meeting.meeting_date) < new Date().setHours(0, 0, 0, 0) ? 'darkgray' : 'black',
                                }}
                              >
                                <td>
                                  <b>
                                    {new Date(meeting.meeting_date)
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {(
                                      new Date(
                                        meeting.meeting_date
                                      ).getMonth() + 1
                                    )
                                      .toString()
                                      .padStart(2, "0")}
                                    /
                                    {new Date(
                                      meeting.meeting_date
                                    ).getFullYear()}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {meeting.meeting_status === "Schedule"
                                      ? "Scheduled"
                                      : meeting.meeting_status}
                                  </b>
                                </td>
                                <td>
                                  <b>{meeting.meeting_subject}</b>
                                </td>
                                <td>
                                  <div className="d-lg-none">
                                    <Dropdown
                                      show={showActionsDropdown === index}
                                      onSelect={() =>
                                        setShowActionsDropdown(null)
                                      }
                                    >
                                      <Dropdown.Toggle
                                        variant="link"
                                        id={`dropdown-basic-${index}`}
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "skyblue",
                                        }}
                                      >
                                        <FaEllipsisV
                                          className="ellipsis-icon"
                                          onClick={() =>
                                            toggleActionsDropdown(index)
                                          }
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        >
                                          <FaEye className="viewicon" /> View
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item
                                          onClick={() =>
                                            toggleCommentSection(index)
                                          }
                                        >
                                          <FaComment className="commenticon" />{" "}
                                          Comment
                                        </Dropdown.Item> */}
                                        {/* {userId == meeting.created_by && (
                                          <>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleEditSection(index)
                                              }
                                            >
                                              <FaEdit className="editicon" />{" "}
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleDeleteSection(index)
                                              }
                                            >
                                              <FaTrash className="deleteicon" />{" "}
                                              Delete
                                            </Dropdown.Item>
                                          </>
                                        )} */}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <FaEye
                                      className="viewicon"
                                      size={15}
                                      color="#007BFF"
                                      onClick={() => toggleEyeDetails(index)}
                                    />
                                    &nbsp;&nbsp;
                                    {/* <FaComment
                                      className="commenticon"
                                      size={15}
                                      color="#007BFF"
                                      onClick={() =>
                                        toggleCommentSection(index)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    {userId == meeting.created_by && (
                                      <>
                                        <FaEdit
                                          className="editicon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleEditSection(index)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        <FaTrash
                                          className="deleteicon"
                                          size={15}
                                          color="red"
                                          onClick={() =>
                                            toggleDeleteSection(index)
                                          }
                                        />
                                      </>
                                    )} */}
                                  </div>
                                </td>
                              </tr>
                              {eyeClickedRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEyeClickedRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Member: {meeting.participant_name}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Created By: {meeting.organizer_name}
                                          </b>
                                        </p>
                                        <p>
                                          <b>Mode: {meeting.meeting_mode}</b>
                                        </p>
                                        <p>
                                          <b>
                                            {meeting.meeting_mode ===
                                              "Virtual" ? (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meetings"}{" "}
                                                  Link:{" "}
                                                  <a
                                                    href={meeting.meeting_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={handleClick}
                                                  >
                                                    Click here
                                                  </a>
                                                  {/* {linkClicked && (
                                                    <span>
                                                      {" "}
                                                      ({meeting.meeting_link})
                                                    </span>
                                                  )} */}
                                                </p>
                                              </div>
                                            ) : (
                                              <div>
                                                <p>
                                                  {getOrgNames?.alt_meeting ||
                                                    "Meetings"}{" "}
                                                  Place: {meeting.meeting_place}
                                                </p>
                                              </div>
                                            )}
                                          </b>
                                        </p>
                                      </div>
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Start Time:{" "}
                                            {formatTime(
                                              meeting.meeting_start_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            End Time:{" "}
                                            {formatTime(
                                              meeting.meeting_end_time
                                            )}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Description: {meeting.meeting_desc}
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {commentRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setCommentRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <MeetingComment
                                      meetingId={meeting.meeting_id}
                                    />
                                  </td>
                                </tr>
                              )}
                              {editRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEditRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <EditMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setEditRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                              {deleteRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setDeleteRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <DeleteMeeting
                                      meetingId={meeting.meeting_id}
                                      onClose={() => setDeleteRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ) : (
                        <p>
                          No {getOrgNames?.alt_meeting || "Meetings"} scheduled.
                        </p>
                      )}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Error Alert */}

      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}

      {alertMessage && (
        <Alert
          variant="danger"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() =>
                navigate("/dashboard/app/memberMeetingAdmin", {
                  state: { alertMessage: null },
                })
              }
            />
          </div>
          <Alert.Heading>Alert!</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default MemberMeetingAdmin;
