import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";

// Import OrgNamingProvider
import { OrgNamingProvider } from "./hooks/OrgNamingContext";



import { IndexRouters } from "./router";
import { SimpleRouter } from "./router/simple-router";
import { ChatRouter } from "./router/chat-router";

const router = createBrowserRouter(
  [...IndexRouters, ...SimpleRouter, ...ChatRouter],
  { basename: process.env.PUBLIC_URL }
);

// Define org_id and token
const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
       <OrgNamingProvider org_id={org_id} token={token}>
        <App>
          <RouterProvider router={router} />
        </App>
      </OrgNamingProvider>
    </Provider>
  </StrictMode>
);
