import React from "react";

//pages
// import GroupDetail from "../views/dashboard/app/group-detail";
import Members from "../views/dashboard/app/members";
import MemberCategory from "../views/dashboard/app/mcategory";
import Chapters from "../views/dashboard/app/chapters";
import MembershipPlans from "../views/dashboard/app/mship-plans";
import ForMe from "../views/dashboard/app/for-me";
import ByMe from "../views/dashboard/app/by-me";
import ProfileVideos from "../views/dashboard/app/profile-videos";
import Meeting from "../views/dashboard/app/meeting";
import MeetingList from "../views/dashboard/app/meetingList";
import ChapterMeeting from "../views/dashboard/app/chapterMeeting";
import ChapterMeetingList from "../views/dashboard/app/chapterMeetingList";
import ChapterReceivedReferrals from "../views/dashboard/app/chapterReceivedReferrals";
import ChapterSentReferrals from "../views/dashboard/app/chapterSentReferrals";
import RenuewMemberList from "../views/dashboard/app/renuewMemberList";
import MemberMeetingAdmin from "../views/dashboard/app/memberMeetingAdmin";
export const Layout1Router = [
 
  {
    path: "dashboards/app/RenuewMemberList",
    element: <RenuewMemberList />,
  },
  {
    path: "dashboards/app/chapter-meeting-list",
    element: <ChapterMeetingList />,
  },
  {
    path: "dashboards/app/membershipRequest",
    element: <membershipRequest />,
  },
  {
    path: "dashboards/app/members",
    element: <Members />,
  },
  {
    path: "dashboards/app/mcategory",
    element: <MemberCategory />,
  },
 
  {
    path: "dashboards/app/chapters",
    element: <Chapters />,
  },

  {
    path: "dashboards/app/mship-plans",
    element: <MembershipPlans />,
  },
  {
    path: "dashboards/app/for-me",
    element: <ForMe />,
  },
  {
    path: "dashboards/app/by-me",
    element: <ByMe />,
  },
  {
    path: "dashboards/app/chapter-received-referrals",
    element: <ChapterReceivedReferrals />,
  },
  {
    path: "dashboards/app/chapter-sent-referrals",
    element: <ChapterSentReferrals />,
  },
  
  {
    path: "dashboards/app/profile-videos",
    element: <ProfileVideos />,
  },
   
  {
    path: "dashboards/app/meeting",
    element: <Meeting />,
  },
  {
    path: "dashboards/app/memberMeetingAdmin",
    element: <MemberMeetingAdmin />,
  },
  {
    path: "dashboards/app/chapter-meeting",
    element: <ChapterMeeting />,
  },
  {
    path: "dashboards/app/meetinglist",
    element: <MeetingList />,
  },
  
];
