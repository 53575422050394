import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "./sign-in-type.css";

import { PiSignInBold } from "react-icons/pi";

import adminIcon from "../../../assets/images/icon/admin-icon.png";
import memberIcon from "../../../assets/images/icon/member-icon.jpg";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignInType = () => {
  const location = useLocation();
  const {email_id,password } = location.state || {};
  const navigate = useNavigate();

  const handleAdminLogin = () => {
    sessionStorage.setItem("role_id", 2);
    let rd=sessionStorage.getItem("role_id");
    sessionStorage.setItem("role", "CHAPTER_ADMIN");
    console.log(rd);
    navigate("/adminDashboard");
  };

  const handleMemberLogin = () => {
    sessionStorage.setItem("role_id", 3);
    sessionStorage.setItem("role", "MEMBER");
    let rd=sessionStorage.getItem("role_id");
console.log(rd);
    navigate("/userposts");
  };

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>

        <Container className="container-div">
          <div className="card-container">
            <div>
              <Card onClick={handleAdminLogin}>
                <Card.Body className="card-body-signin">
                  <img src={adminIcon} alt="Admin" className="image-style" />
                </Card.Body>
                <Card.Footer className="card-footer-signin">
                  Admin Login <PiSignInBold />
                </Card.Footer>
              </Card>
            </div>

            <div>
              <Card onClick={handleMemberLogin}>
                <Card.Body className="card-body-signin">
                  <img src={memberIcon} alt="Admin" className="image-style" />
                </Card.Body>
                <Card.Footer className="card-footer-signin">
                  Member Login <PiSignInBold />
                </Card.Footer>
              </Card>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SignInType;
